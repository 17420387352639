import create from 'zustand'

import { ClientT } from '@/config/types'

export interface StoreEventI extends Conviva2EventI {
  id?: string
  streamType?: StreamTypes
  config?: ConfigI
}
export interface EventStoreState {
  open: boolean
  event: StoreEventI
  hoveredEventFlag: string | null
  isOcellus: boolean
  eventsPageFilters: Partial<Record<ClientT, ConfigI>>
  eventsPageStreamType: Partial<Record<ClientT, StreamTypes>>
  eventsPageDate: Partial<Record<ClientT, any>>
  setIsOcellus: (value: boolean) => void
  onOpenEvent: (event: StoreEventI) => void
  onHoverEventFlag: (id: string | null) => void
  onClose: () => void
  setEventsPageFilters: (
    client,
    funcOrValue: ConfigI | ((filters: ConfigI[]) => ConfigI[]),
  ) => void
  setEventsPageStreamType: (client, streamType: StreamTypes) => void
  setEventsPageDate: (client, date: any) => void
}

const emptyEvent: StoreEventI = {} as StoreEventI

export const useEventStore = create<EventStoreState>((set) => ({
  open: false,
  event: emptyEvent,
  hoveredEventFlag: null,
  isOcellus: false,
  eventsPageFilters: {},
  eventsPageStreamType: {},
  setIsOcellus: (value) => set(() => ({ isOcellus: value })),
  onOpenEvent: (event) => set(() => ({ open: true, event })),
  onHoverEventFlag: (id) => set(() => ({ hoveredEventFlag: id })),
  onClose: () => set(() => ({ open: false })),
  setEventsPageFilters: (client, funcOrValue) => {
    set((state) => ({
      ...state,
      eventsPageFilters: {
        ...state.eventsPageFilters,
        [client]:
          typeof funcOrValue === 'function'
            ? funcOrValue(state.eventsPageFilters[client])
            : funcOrValue,
      },
    }))
  },
  setEventsPageStreamType: (client, streamType) =>
    set((state) => ({
      ...state,
      eventsPageStreamType: {
        ...state.eventsPageStreamType,
        [client]: streamType,
      },
    })),
  eventsPageDate: {},
  setEventsPageDate: (client, date) =>
    set((state) => ({
      ...state,
      eventsPageDate: {
        ...state.eventsPageDate,
        [client]: date,
      },
    })),
}))
