import { startCase } from 'lodash'
import { useContext } from 'react'
import { HiX } from 'react-icons/hi'

import { Button } from '@/components/ui/button'
import { formOptions } from '@/config/formOptions'

import { FiltersContext } from './Filters'

export default function FilterItem({
  value,
  formGroup,
  isMulti,
}: {
  value: string
  formGroup?: keyof FiltersI
  isMulti: boolean
}) {
  const { updateFilters } = useContext(FiltersContext)
  if (!formGroup) return null

  const options = formOptions[formGroup]
  const config = options?.[value] || {}

  const image = config?.[1]?.image
  const label = config?.[1]?.label

  return (
    <Button
      variant='ghost'
      onClick={() => {
        updateFilters({
          formGroup,
          value,
          checked: false,
          isMulti,
        })
      }}
      className='flex items-center flex-shrink-0 h-8 px-3 py-2 text-xs font-medium border rounded-full shadow-sm group border-border-main hover:bg-neutral-dimmed bg-neutral-dimmed-heavy text-text-secondary hover:text-text-primary'
    >
      {image && (
        <img className='w-4 h-4 mr-1 rounded-full' src={image} alt={label} />
      )}

      <span>{label ?? startCase(value)}</span>

      <div className='items-center hidden w-6 h-6 p-0 ml-1 -mr-2 group-hover:flex text-helpers-error-button'>
        <HiX className='w-4 h-4' />
      </div>
    </Button>
  )
}
