import { motion } from 'framer-motion'
import * as React from 'react'

import FilterItem from './FilterItem'
import { FiltersContext } from './Filters'

const MAX_CHIPS = 3

function SpacingDot() {
  return (
    <div className='flex-shrink-0 w-0.5 h-0.5 mx-2 rounded-full bg-neutral-shadow-heavy'></div>
  )
}

export default function FiltersGroups() {
  const { activeFilters } = React.useContext(FiltersContext)

  if (!activeFilters || Object.keys(activeFilters).length === 0) return null

  return (
    <div className='flex items-center justify-start space-x-2'>
      {Object.keys(activeFilters).map((formGroup, index) => (
        <React.Fragment key={formGroup}>
          {index > 0 && <SpacingDot />}
          <FilterGroup
            selectedFilters={activeFilters[formGroup as keyof FiltersI]}
            formGroup={formGroup as keyof FiltersI}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

function FilterGroup({
  selectedFilters,
  formGroup,
}: {
  selectedFilters?: string[] | string | null | number | boolean
  formGroup?: keyof FiltersI
}) {
  const [isHovered, setIsHovered] = React.useState(false)

  if (typeof selectedFilters === 'string') {
    return (
      <FilterItem
        formGroup={formGroup}
        value={selectedFilters}
        isMulti={false}
      />
    )
  } else if (Array.isArray(selectedFilters)) {
    const maxRenderedChips =
      MAX_CHIPS + 1 === selectedFilters.length ? MAX_CHIPS + 1 : MAX_CHIPS

    return (
      <motion.div
        className='flex items-center justify-start flex-shrink-0 space-x-1 transition-all duration-75 ease-in-out'
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onTap={(e) => {
          setIsHovered(true)
          e.stopImmediatePropagation()
        }}
      >
        {[...selectedFilters]
          .slice(0, isHovered ? selectedFilters.length : maxRenderedChips)
          .map((filter, index) => (
            <motion.div
              initial={{ x: -1, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: index * 0.05 }}
              key={filter}
              className='flex-shrink-0 w-auto'
            >
              <FilterItem formGroup={formGroup} value={filter} isMulti />
            </motion.div>
          ))}
        {maxRenderedChips < selectedFilters?.length && !isHovered && (
          <span className='bg-neutral-shadow text-text-secondary inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium mr-1 flex-shrink-0 mb-0.5'>
            +
            {selectedFilters?.length - MAX_CHIPS + maxRenderedChips - MAX_CHIPS}
          </span>
        )}
      </motion.div>
    )
  }
  return null
}
