import { ClientT, SeriesReportsSettingsI } from '@/config/types'

import nowSeriesReportsSettings from './now'
import nowOTTSeriesReportsSettings from './nowott'
import skyEuropeSeriesReportsSettings from './sky-europe'

export const seriesEventReportSettings: Record<
  ClientT,
  SeriesReportsSettingsI
> = {
  peacock: skyEuropeSeriesReportsSettings, // Never actually used
  skyshowtime: skyEuropeSeriesReportsSettings, // Never actually used
  now: nowOTTSeriesReportsSettings,
  nowott: nowSeriesReportsSettings,
  'nbcu-dtc': skyEuropeSeriesReportsSettings, // Never actually used
  'sky-europe-and-peacock': skyEuropeSeriesReportsSettings, // Never actually used
  'sky-europe': skyEuropeSeriesReportsSettings,
  showmax: skyEuropeSeriesReportsSettings, // Never actually used
}

export default seriesEventReportSettings
