import { env } from '../../env'
import { AppSettingsI } from '../types'

const appSettings: AppSettingsI = {
  name: 'Insights',
  key: 'insights',
  clients: [
    'nbcu-dtc',
    'sky-europe',
    'peacock',
    'skyshowtime',
    'nowott',
    'now',
    'showmax',
  ],
  defaultClient: 'sky-europe',
  description:
    'Insights provides immediate access into the customer experience of all Sky propositions, including real-time viewership, top events and more.',
  logo: {
    light: {
      app: '/images/app/app-logo.png',
    },
    dark: {
      app: '/images/app/app-logo.png',
    },
  },
  login: {
    backgroundClassName: 'bg-gradient-to-br from-[#2967EC] to-[#124FD0]', //primary-500 -> primary/600
    particlesColor: '#B6CBF8', // primary-200
    loginButtonClassName:
      'text-white bg-[#2967EC] hover:bg-[#124FD0] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-[#2967EC] focus-visible:ring-offset-neutral-dimmed-heavy',
  },
  navigation: {
    type: 'Nav1', // More types coming soon,
  },
  easterEggs: {
    draggableLogo: true,
  },
  analytics: {
    enabled: env.NODE_ENV === 'production',
    firebaseConfig: {
      apiKey: env.REACT_APP_FIREBASE_API_KEY,
      measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
      appId: env.REACT_APP_FIREBASE_APP_ID,
    },
  },
}

export default appSettings
