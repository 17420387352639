import React from 'react'
import { RouteObject } from 'react-router'
import { Navigate } from 'react-router-dom'

import Layout from '@/components/Layout'
import Loadable from '@/components/Loadable'

import { eventsRedirects, reportsRedirects } from './utils/redirects'
import ReplaceRoute from './utils/replaceRoute'

// Pages

const Home = Loadable(React.lazy(() => import('@/pages/Home')))
const Metrics = Loadable(React.lazy(() => import('@/pages/Metrics')))
const Events = Loadable(React.lazy(() => import('@/pages/Content/AllEvents')))

const EventDetailsPage = Loadable(
  React.lazy(() => import('@/pages/Content/EventDetailsPage')),
)

const Contact = Loadable(React.lazy(() => import('@/pages/Contact')))
const MetricDefinitions = Loadable(
  React.lazy(() => import('@/pages/MetricDefinitions')),
)
const FAQs = Loadable(React.lazy(() => import('@/pages/FAQs')))

const RequestPrivileges = Loadable(
  React.lazy(() => import('@/pages/RequestPrivileges')),
)

const AdminPage = Loadable(
  React.lazy(() => import('@/pages/Admin/ManualNotification/AdminPage')),
)

const PageNotFound = Loadable(React.lazy(() => import('@/pages/PageNotFound')))
const PageNotSupportedWithinClient = Loadable(
  React.lazy(
    () => import('@/components/ClientPages/PageNotSupportedWithinClient'),
  ),
)

const routes: RouteObject[] = [
  {
    path: ':client',
    element: <Layout />,
    children: [
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <PageNotFound /> },
      { index: true, element: <Home /> },
      { path: 'metrics', element: <Metrics /> },
      { path: 'contact', element: <Contact /> },
      { path: 'faqs', element: <FAQs /> },
      { path: 'request-privileges', element: <RequestPrivileges /> },
      { path: 'metric-definitions', element: <MetricDefinitions /> },

      ...eventsRedirects,
      {
        path: 'content',
        children: [
          {
            index: true,
            element: (
              <ReplaceRoute replace={['/content', '/content/all-events']} />
            ),
          },
          { path: 'all-events', element: <Events /> },

          { path: 'event/:streamType/:eventId', element: <EventDetailsPage /> },
        ],
      },
      {
        path: 'reports',
        children: [
          { path: '*', element: <Navigate to='/404' /> },
          { index: true, element: <Navigate to='/404' /> },
          // { path: 'weekly-viewing', element: <WeeklyViewingReport /> },
          {
            path: 'weekly-viewing',
            element: <PageNotSupportedWithinClient title='Weekly Viewing' />,
          },
          {
            path: 'records',
            element: <PageNotSupportedWithinClient title='Records' />,
          },
          {
            path: 'global-certificates',
            element: (
              <PageNotSupportedWithinClient title='Global Certificates Dashboard' />
            ),
          },
          ...reportsRedirects,
        ],
      },
      {
        path: 'manual-notification',
        element: <AdminPage />,
      },
    ],
  },
]

export default routes
