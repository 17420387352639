import { env } from '../env'
import relativeDates from './relativeDates'
import { ClientT } from './types'

export const CONTROL_CENTRE_API_DOMAIN: string =
  env.REACT_APP_CONTROL_CENTRE_API_DOMAIN!

export const CONTROL_CENTRE_V2_API_DOMAIN: string =
  env.REACT_APP_CONTROL_CENTRE_V2_API_DOMAIN!

export const PEACOCK_API_DOMAIN = env.REACT_APP_PEACOCK_API_DOMAIN!

export const PEACOCK_INTERNAL_API_DOMAIN =
  env.REACT_APP_PEACOCK_INTERNAL_API_DOMAIN!

export const CONVIVA_API_DOMAIN = env.REACT_APP_CONVIVA_API_DOMAIN!

export const CONVIVA_INTERNAL_API_DOMAIN =
  env.REACT_APP_CONVIVA_INTERNAL_API_DOMAIN!

export const CONVIVA_PCK_API_DOMAIN = env.REACT_APP_CONVIVA_PCK_API_DOMAIN!

export const CONVIVA_PCK_INTERNAL_API_DOMAIN =
  env.REACT_APP_CONVIVA_PCK_INTERNAL_API_DOMAIN!

export const CONVIVA_2_V4_API_DOMAIN = env.REACT_APP_CONVIVA_2_V4_API_DOMAIN!

export const CONVIVA_2_V4_INTERNAL_API_DOMAIN =
  env.REACT_APP_CONVIVA_2_V4_INTERNAL_API_DOMAIN!

export const AUTH_GATEWAY_API_DOMAIN = env.REACT_APP_AUTH_GATEWAY_API_DOMAIN!

export const VQ_INTERNAL_API_DOMAIN = env.REACT_APP_VQ_INTERNAL_API_DOMAIN!

export const BIGVIVA_API_DOMAIN = env.REACT_APP_BIGVIVA_API_DOMAIN!

export const BIGVIVA_INTERNAL_API_DOMAIN =
  env.REACT_APP_BIGVIVA_INTERNAL_API_DOMAIN!

export const BIGVIVA_REALTIME_API_DOMAIN =
  env.REACT_APP_BIGVIVA_REALTIME_API_DOMAIN!

export const BIGVIVA_REALTIME_INTERNAL_API_DOMAIN =
  env.REACT_APP_BIGVIVA_REALTIME_INTERNAL_API_DOMAIN!

export const BIGVIVA_SDP_API_DOMAIN = env.REACT_APP_BIGVIVA_SDP_API_DOMAIN!

export const BIGVIVA_SDP_INTERNAL_API_DOMAIN =
  env.REACT_APP_BIGVIVA_SDP_INTERNAL_API_DOMAIN!

export const SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN =
  env.REACT_APP_SKYSHOWTIME_BIGVIVA_SDP_API_DOMAIN!

export const SKYSHOWTIME_BIGVIVA_SDP_INTERNAL_API_DOMAIN =
  env.REACT_APP_SKYSHOWTIME_BIGVIVA_SDP_INTERNAL_API_DOMAIN!

export const SDP_API_DOMAIN = env.REACT_APP_SDP_API_DOMAIN!

export const SDP_INTERNAL_API_DOMAIN = env.REACT_APP_SDP_INTERNAL_API_DOMAIN!

export const PEACOCK_SDP_API_DOMAIN = env.REACT_APP_PEACOCK_SDP_API_DOMAIN!

export const PEACOCK_SDP_INTERNAL_API_DOMAIN =
  env.REACT_APP_PEACOCK_SDP_INTERNAL_API_DOMAIN!

export const IDENTITY_API_DOMAIN = env.REACT_APP_IDENTITY_API_DOMAIN!

export const IDENTITY_INTERNAL_API_DOMAIN =
  env.REACT_APP_IDENTITY_INTERNAL_API_DOMAIN!

export const PEACOCK_IDENTITY_API_DOMAIN =
  env.REACT_APP_PEACOCK_IDENTITY_API_DOMAIN!

export const PEACOCK_IDENTITY_INTERNAL_API_DOMAIN =
  env.REACT_APP_PEACOCK_IDENTITY_INTERNAL_API_DOMAIN!

export const SKYSHOWTIME_IDENTITY_API_DOMAIN =
  env.REACT_APP_SKYSHOWTIME_IDENTITY_API_DOMAIN!

export const SKYSHOWTIME_IDENTITY_INTERNAL_API_DOMAIN =
  env.REACT_APP_SKYSHOWTIME_IDENTITY_INTERNAL_API_DOMAIN!

export const EU_CHANNELS_API_DOMAIN = env.REACT_APP_EU_CHANNELS_API_DOMAIN!

export const US_CHANNELS_API_DOMAIN = env.REACT_APP_US_CHANNELS_API_DOMAIN!

export const EVENT_CONFIG_API_DOMAIN = env.REACT_APP_EVENT_CONFIG_API_DOMAIN!

export const EVENT_CONFIG_INTERNAL_API_DOMAIN =
  env.REACT_APP_EVENT_CONFIG_INTERNAL_API_DOMAIN!

export const SKY_SPORTS_IMAGES_API_DOMAIN =
  env.REACT_APP_SKY_SPORTS_IMAGES_API_DOMAIN!
export const SKY_SPORTS_SCHEDULE_API_DOMAIN =
  env.REACT_APP_SKY_SPORTS_SCHEDULE_API_DOMAIN!
export const SKY_SPORTS_F1_API_DOMAIN = env.REACT_APP_SKY_SPORTS_F1_API_DOMAIN!

export const CSI_API_DOMAIN = env.REACT_APP_CSI_API_DOMAIN!
export const CSI_INTERNAL_API_DOMAIN = env.REACT_APP_CSI_INTERNAL_API_DOMAIN!

export const NOTIFICATIONS_CONFIG_API_DOMAIN =
  env.REACT_APP_NOTIFICATIONS_CONFIG_API_DOMAIN!

export const NOTIFICATIONS_CONFIG_INTERNAL_API_DOMAIN =
  env.REACT_APP_NOTIFICATIONS_CONFIG_INTERNAL_API_DOMAIN!

export const SEVERITY_MATRIX_API_DOMAIN =
  env.REACT_APP_SEVERITY_MATRIX_API_DOMAIN!

export const SEVERITY_MATRIX_INTERNAL_API_DOMAIN =
  env.REACT_APP_SEVERITY_MATRIX_INTERNAL_API_DOMAIN!

export const INCIDENT_FORM_API_DOMAIN = env.REACT_APP_INCIDENT_FORM_API_DOMAIN!

export const INCIDENT_FORM_INTERNAL_API_DOMAIN =
  env.REACT_APP_INCIDENT_FORM_INTERNAL_API_DOMAIN!

export const PROMETHEUS_INTERNAL_API_DOMAIN =
  env.REACT_APP_PROMETHEUS_INTERNAL_API_DOMAIN!

export const PROMETHEUS_API_DOMAIN = env.REACT_APP_PROMETHEUS_API_DOMAIN!

export const CMDM_API_DOMAIN = env.REACT_APP_CMDM_API_DOMAIN!
export const CMDM_INTERNAL_API_DOMAIN = env.REACT_APP_CMDM_INTERNAL_API_DOMAIN!

export const MEILESEARCH_API_DOMAIN = env.REACT_APP_MEILESEARCH_API_DOMAIN
export const MEILESEARCH_INTERNAL_API_DOMAIN =
  env.REACT_APP_MEILESEARCH_INTERNAL_API_DOMAIN

export const AZURE_AD_CLIENT_ID = env.REACT_APP_AZURE_AD_CLIENT_ID
export const API_ACCESS_TOKEN_SCOPE = env.REACT_APP_AZURE_API_SCOPE

export const CONFIG_API_DOMAIN = env.REACT_APP_CONFIG_API_DOMAIN

export const CERTIFICATES_API_DOMAIN = env.REACT_APP_CERTIFICATES_API_DOMAIN!

export const ACROPOLIS_DASHBOARD_DOMAIN =
  env.REACT_APP_ACROPOLIS_DASHBOARD_DOMAIN!
export const INSIGHTS_DASHBOARD_DOMAIN =
  env.REACT_APP_INSIGHTS_DASHBOARD_DOMAIN!

export const DECISION_SCIENCES_API_DOMAIN = 'TBD'
export const DECISION_SCIENCES_INTERNAL_API_DOMAIN = 'TBD'

export const DEFAULT_DATE = (client: ClientT) => {
  const defaultRelativeDate = relativeDates[client].find(
    ({ isDefault }) => isDefault,
  )

  const { start, end } = defaultRelativeDate?.getDateRange() as {
    start: any
    end: any
  }

  return {
    dateType: 'relative' as const,
    relativeDate: defaultRelativeDate,
    start: start.clone().utc().format(),
    end: end.clone().utc().format(),
  }
}

export const DEFAULT_EVENT_METRIC: { [key in StreamTypes]: string } = {
  sle: 'concurrent-plays',
  vod: 'plays',
  linear: 'concurrent-plays',
  trailers: 'concurrent-plays', //?
  clips: 'concurrent-plays', //?
  cdvr: 'concurrent-plays', //?
}

export const MIN_DATE = new Date(2020, 3) // April 2020

export const vqKPIEmojiThresholds = {
  '😭': 0,
  '😞': 0.2,
  '😕': 0.4,
  '😃': 0.6,
  '😁': 0.7,
}

export const EVENT_TIME_BUFFER = 1000 * 60 * 60 * 2 // 2 hours

// Used for buttons
export const streamTypeLabel: { [key: string]: string } = {
  linear: 'linear',
  vod: 'VOD',
}

// Manual Notifications API
export const MANUAL_NOTIFICATIONS_API_DOMAIN =
  env.REACT_APP_MANUAL_NOTIFICATIONS_API_DOMAIN!

export const UPCOMING_EVENTS_API_DOMAIN =
  env.REACT_APP_UPCOMING_EVENTS_API_DOMAIN!

export const UPCOMING_EVENTS_INTERNAL_API_DOMAIN =
  env.REACT_APP_UPCOMING_EVENTS_INTERNAL_API_DOMAIN!

// Twitter API
export const TWITTER_API_DOMAIN = env.REACT_APP_TWITTER_API_DOMAIN!
export const TWITTER_INTERNAL_API_DOMAIN =
  env.REACT_APP_TWITTER_INTERNAL_API_DOMAIN!

// Search API
export const SEARCH_API_DOMAIN = env.REACT_APP_SEARCH_API_DOMAIN!
export const SEARCH_INTERNAL_API_DOMAIN =
  env.REACT_APP_SEARCH_INTERNAL_API_DOMAIN!

// Grafana Faro
export const GRAFANA_FARO_URL = env.REACT_APP_GRAFANA_FARO_URL!
export const GRAFANA_FARO_API_KEY = env.REACT_APP_GRAFANA_FARO_API_KEY!

export const clientsWithDynamicConfig: ClientT[] = ['skyshowtime']

export const FEATURE_FLAGS = {
  INSTANT_SEARCH_ENABLED_FOR_HIGH_PROFILE_EVENTS:
    'FF_INSTANT_SEARCH_ENABLED_FOR_HIGH_PROFILE_EVENTS',
  RECORDS_PAGE_ENABLED: 'FF_RECORDS_PAGE_ENABLED',
  CONVIVA_FALLBACK_ENABLED: 'FF_CONVIVA_FALLBACK_ENABLED',
  OCELLUS_METRICS_ENABLED: 'FF_OCELLUS_METRICS_ENABLED',
  OCELLUS_EVENTS_ENABLED: 'FF_OCELLUS_EVENTS_ENABLED',
  METRIC_DEFINITIONS_PAGE_ENABLED: 'FF_METRIC_DEFINITIONS_PAGE_ENABLED',
  FF_OLYMPICS_EVENT_PAGE: 'FF_OLYMPICS_EVENT_PAGE',
  FF_CERTIFICATES_PAGE: 'FF_CERTIFICATES_PAGE',
  FF_CERTIFICATES_ADMIN: 'FF_CERTIFICATES_ADMIN',
  FF_PAID_SUBS_REPORT_ENABLED: 'FF_PAID_SUBS_REPORT_ENABLED',
}

export const DATE_TIME_FORMAT = `ddd DD MMM 'YY, HH:mm`
export const SHORT_DATE_TIME_FORMAT = ` DD/MM/YY, HH:mm`
export const DATE_FORMAT = `ddd DD MMM 'YY`

export const INSTANT_SEARCH_INDEX_NAMES = {
  HIGH_PROFILE_EVENTS: 'hpe',
}

export const COMCAST_DELAY_IN_MINUTES = 8

// Client-based timezones
export const CLIENT_DEFAULT_TIMEZONES = {
  'nbcu-dtc': 'America/New_York',
  peacock: 'America/New_York',
  skyshowtime: 'UTC',
  showmax: 'UTC',
  'sky-europe': 'Europe/London',
  'sky-europe-and-peacock': 'Europe/London',
  now: 'Europe/London',
  nowott: 'Europe/London',
}
