import { ClientT, ReportSettingsI } from '@/config/types'

import peacockSubscriberReportPageSettings from './peacock'

export const subscriberReportPageSettings: Record<ClientT, ReportSettingsI> = {
  peacock: peacockSubscriberReportPageSettings,
  skyshowtime: peacockSubscriberReportPageSettings, // Not used
  now: peacockSubscriberReportPageSettings, // Not used
  nowott: peacockSubscriberReportPageSettings, // Not used
  'nbcu-dtc': peacockSubscriberReportPageSettings, // Not used
  'sky-europe-and-peacock': peacockSubscriberReportPageSettings, // Not used
  'sky-europe': peacockSubscriberReportPageSettings, // Not used
  showmax: peacockSubscriberReportPageSettings, // Not used
}

export default subscriberReportPageSettings
