import { startCase } from 'lodash'

import { MetricSettingsI } from '@/config/types'

const fallback = []

interface LineDataI {
  name: string
  label: any
  image?: any
  icon?: any
  meta: any
  color: any
  id: string
  lineWidth: number
  data: any[][]
  zIndex: number
  type?: string
  [key: string]: any
}
;[]

const convivaTimeseriesMultipleMetricsToLine = ({
  metrics,
  data,
}: {
  metrics: MetricSettingsI[]
  data: ConvivaTimeseriesResponseI
}): LineDataI[] => {
  if (data?.data) {
    //Hack to fix fact API always returns total object
    if (
      Object.keys(data?.data).length === 1 &&
      data?.data?.total?.length === 0
    ) {
      return fallback
    }

    const lineData = Object.keys(data?.data).reduce((acc, splitName) => {
      metrics.forEach((metric) => {
        const { label, value, dataKey } = metric
        const isMainLine =
          splitName === 'total' || Object.keys(data?.data)?.length === 2

        acc.push({
          name: label || startCase(value),
          label: label || startCase(value),
          meta: data?.data?.meta,
          id: `${splitName}-${dataKey}`,
          color: null,
          lineWidth: isMainLine ? 2 : 1.25,
          data:
            data?.data?.[splitName].map((datum) => [
              datum.timestamp,
              datum[dataKey] || 0,
            ]) || [],
          zIndex: isMainLine ? 1 : 0,
        })
      })
      return acc
    }, [] as LineDataI[])

    return lineData
  } else {
    return fallback
  }
}

export default convivaTimeseriesMultipleMetricsToLine
