import ImageFallback from '@/components/ImageFallback'
import { BusiestDayTopEventI } from '@/config/types'
import useMetricSettings from '@/hooks/useMetricSettings'

import { valueFormat } from './DatePickerBusiestDayItem'

interface DatePickerBusiesDayTopEventsItemProps extends BusiestDayTopEventI {
  metric: 'concurrent-plays' | 'plays'
}

const DatePickerBusiesDayTopEventsItem = ({
  id,
  imageUrl,
  title,
  concurrentPlays,
  metric,
}: DatePickerBusiesDayTopEventsItemProps) => {
  const metricSettings = useMetricSettings({
    metric,
    variants: { withClient: true },
  })

  return (
    <li
      key={id}
      className='flex items-start py-1 w-full space-x-3 overflow-hidden'
    >
      <ImageFallback
        src={imageUrl}
        alt={title}
        className='object-cover object-center w-10 h-10 rounded-md bg-neutral'
      />

      <div className='flex flex-col justify-between flex-1 space-y-1 truncate'>
        <p className='text-xs truncate text-text-primary text-left'>{title}</p>

        <div className='inline-flex items-center space-x-1 text-xs truncate text-text-dimmed'>
          <metricSettings.solidIcon className='h-3.5 w-3.5 mr-1' />
          <span className='sr-only'>{metricSettings.label}: </span>
          {valueFormat(concurrentPlays)}
        </div>
      </div>
    </li>
  )
}

export default DatePickerBusiesDayTopEventsItem
