import { RouteObject } from 'react-router'

import { ClientT } from '../types'
import nbcuDtcRoutes from './nbcu-dtc'
import nowRoutes from './now'
import nowOTTRoutes from './nowott'
import peacockRoutes from './peacock'
import showmaxRoutes from './showmax'
import skyEuropeRoutes from './sky-europe'
import skyEuropeAndPeacockRoutes from './sky-europe-and-peacock'
import skyshowtimeRoutes from './skyshowtime'

export const routes: Record<ClientT, RouteObject[]> = {
  peacock: peacockRoutes,
  skyshowtime: skyshowtimeRoutes,
  now: nowRoutes,
  nowott: nowOTTRoutes,
  'nbcu-dtc': nbcuDtcRoutes,
  'sky-europe-and-peacock': skyEuropeAndPeacockRoutes,
  'sky-europe': skyEuropeRoutes,
  showmax: showmaxRoutes,
}

export default routes
