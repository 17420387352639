import { useContext } from 'react'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { FiltersContext } from '../Filters'
import AddFilterButton from './AddFilterButton'
import FiltersNotAvailable from './FiltersNotAvailable'
import FiltersSelectionDropdownGroup from './FiltersSelectionDropdownGroup/FiltersSelectionDropdownGroup'
import FiltersSelectionRemoveFiltersButton from './FiltersSelectionRemoveFiltersButton'

// function FiltersLoading() {
//   return (
//     <div className='flex flex-col items-center justify-center space-y-2 h-36'>
//       <LuLoader2 className='w-4 h-4 animate-spin' />
//       <span className='text-sm text-text-tertiary'>Loading filters...</span>
//     </div>
//   )
// }

function FiltersDropdownMenuGroupContent() {
  const { availableFilters } = useContext(FiltersContext)

  return (
    <>
      {availableFilters?.map((form) => (
        <FiltersSelectionDropdownGroup
          key={form.value}
          {...form}
          value={form.value as keyof FiltersI}
        />
      ))}
    </>
  )
}

export default function FiltersDropdown() {
  const { availableFilters } = useContext(FiltersContext)

  const emptyAvailableFilters =
    !availableFilters || availableFilters.length === 0

  // We still want to show the dropdown if there's an issue with the tags or if they are still loading
  if (emptyAvailableFilters) {
    return <FiltersNotAvailable />
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <AddFilterButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuLabel>Events Filters</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuGroup className='min-w-[200px]'>
          <FiltersDropdownMenuGroupContent />
        </DropdownMenuGroup>

        <DropdownMenuSeparator />
        <FiltersSelectionRemoveFiltersButton />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
