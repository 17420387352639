import { motion } from 'framer-motion'
import { useState } from 'react'

import Chip from '@/components/Chip'
import { getPropositionImage, getTerritoryImage } from '@/config/formOptions'
import { SettingsI } from '@/config/types'
import { cn } from '@/utils/cn'
import getLabel from '@/utils/getLabel'

interface PropsI {
  variant?: 'chip' | 'tag' | 'badge' //standard chip, label only or icon only
  maxChips: number
  formGroup: 'territory' | 'proposition'
  settings: Pick<SettingsI, 'showIcons' | 'form'>
  list: string[]
  theme?: ThemeT
  chipClassName?: string
}

const ChipGroup = ({
  maxChips,
  formGroup,
  settings,
  list,
  theme,
  variant = 'chip',
  chipClassName,
}: PropsI) => {
  const [isHovered, setIsHovered] = useState(false)
  const maxRenderedChips =
    maxChips + 1 === list.length ? maxChips + 1 : maxChips

  return (
    <motion.div
      className='inline-flex flex-wrap items-center transition-all duration-75 ease-in-out'
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onTap={(e) => {
        setIsHovered(true)
        e.stopImmediatePropagation()
      }}
    >
      {settings?.showIcons?.[formGroup]?.enabled &&
        list
          ?.slice(0, isHovered ? list.length : maxRenderedChips)
          .map((chip: string, index) => {
            const imageSrc =
              formGroup === 'proposition'
                ? getPropositionImage(chip)
                : formGroup === 'territory'
                ? getTerritoryImage(chip)
                : null

            return (
              <motion.div
                initial={{ x: -1, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.05 }}
                key={index}
              >
                <Chip
                  variant={variant}
                  theme={theme}
                  key={chip}
                  label={getLabel({
                    value: chip,
                    formGroup,
                    settings: settings,
                  })}
                  collapseLabel
                  image={imageSrc}
                  className={chipClassName}
                />
              </motion.div>
            )
          })}
      {maxRenderedChips < list?.length && !isHovered && (
        <span
          className={cn(
            ' inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium mr-1 flex-shrink-0 mb-0.5',
            {
              'bg-neutral-shadow text-text-secondary':
                !theme || theme === 'light',
              'bg-elements-primary-400 text-elements-primary-contrastText':
                theme === 'dark',
            },
          )}
        >
          +{list?.length - maxChips + maxRenderedChips - maxChips}
          <span className='hidden ml-1 3xl:inline'>
            {formGroup === 'proposition'
              ? 'propositions'
              : formGroup === 'territory'
              ? 'territories'
              : formGroup + 's'}
          </span>
        </span>
      )}
    </motion.div>
  )
}

export default ChipGroup
