/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const _colors = require('tailwindcss/colors')

const getFullPalette = require('../utils/getFullPalette')
const peacockPallette = require('../utils/peacockPallette')

const colors = { ..._colors, ...peacockPallette }

// Palette
const secondary = 'pumpkin'
const success = 'green'
const error = 'red'
const info = 'blue'
const warning = 'orange'

const neutral = 'zinc'
const standard = 'white'

const light = {
  type: 'light',
  value: 'peacock-light',
  label: 'Peacock Light',
  selectors: ['.peacock-light'], //.light on html or no selector at all
  theme: {
    chartColors: [
      // Initial set of chart colors, always use tailwind colors to avoid duplicates
      colors.zinc['500'],
      colors.supernova['500'],
      colors.pumpkin['500'],
      colors.redribbon['500'],
      colors.curiousblue['500'],
      colors.uranium['500'],
      colors.fuchsiablue['500'],
    ],
    colors: {
      // Primary and Secondary colours
      'elements-primary-dimmed': '#4D4D4D',
      'elements-primary-main': '#212121',
      'elements-primary-shadow': '#1A1A1A',
      'elements-primary-highlight': '#7A7A7A',
      'elements-primary-contrastText': '#ffffff',
      // Full Pallette
      'elements-primary-50': '#E8E8E8',
      'elements-primary-100': '#D4D4D4',
      'elements-primary-200': '#A6A6A6',
      'elements-primary-300': '#7A7A7A',
      'elements-primary-400': '#4D4D4D',
      'elements-primary-500': '#212121',
      'elements-primary-600': '#1A1A1A',
      'elements-primary-700': '#141414',
      'elements-primary-800': '#0D0D0D',
      'elements-primary-900': '#080808',
      'elements-primary-950': '#030303',

      // Secondary
      'elements-secondary-dimmed': colors[secondary]['400'],
      'elements-secondary-main': colors[secondary]['500'],
      'elements-secondary-shadow': colors[secondary]['600'],
      'elements-secondary-highlight': colors[secondary]['300'],
      'elements-secondary-contrastText': '#ffffff',
      ...getFullPalette('elements-secondary', secondary),

      // Homepage realtime gradient
      'headline-gradient-from': 'rgb(0, 0, 0)',
      'headline-gradient-via': 'rgb(0, 0, 0)',
      'headline-gradient-to': 'rgb(0, 0, 0)',

      'event-backdrop': 'rgb(0, 0, 0)',

      // Alert
      'helpers-error-dimmed': colors[error]['100'],
      'helpers-error-main': colors[error]['500'],
      'helpers-error-button': colors[error]['600'],
      'helpers-error-button-hover': colors[error]['700'],
      'helpers-error-contrastText': '#ffffff',

      'helpers-warning-dimmed': colors[warning]['100'],
      'helpers-warning-main': colors[warning]['500'],
      'helpers-warning-button': colors[warning]['600'],
      'helpers-warning-button-hover': colors[warning]['700'],

      'helpers-success-dimmed': colors[success]['100'],
      'helpers-success-main': colors[success]['500'],
      'helpers-success-button': colors[success]['600'],
      'helpers-success-button-hover': colors[success]['700'],

      'helpers-info-dimmed': colors[info]['100'],
      'helpers-info-main': colors[info]['500'],
      'helpers-info-button': colors[info]['600'],
      'helpers-info-button-hover': colors[info]['700'],

      // Custom brand colours (e.g. Sky palette, Peacock palette)
      'brand-1': '#ffffff',

      // Text
      'text-primary': colors[neutral]['900'],
      'text-secondary': colors[neutral]['700'],
      'text-tertiary': colors[neutral]['500'],
      'text-dimmed': colors[neutral]['400'],
      'text-disabled': colors[neutral]['300'],

      // Accent / Background
      'divider-dimmed': colors[neutral]['100'],
      'divider-main': colors[neutral]['200'],
      'divider-shadow': colors[neutral]['300'],

      'border-dimmed': colors[neutral]['100'],
      'border-main': colors[neutral]['200'],
      'border-shadow': colors[neutral]['300'],

      'neutral-dimmed-heavy': colors[standard],
      'neutral-dimmed': colors[neutral]['50'],
      neutral: colors[neutral]['100'],
      'neutral-shadow': colors[neutral]['200'],
      'neutral-shadow-heavy': colors[neutral]['300'],
    },
  },
  muiThemeBase: {
    palette: {
      type: 'light',
      primary: {
        main: '#212121',
        light: '#4D4D4D',
        dark: '#1A1A1A',
      },
      secondary: {
        main: colors[secondary]['500'],
        light: colors[secondary]['400'],
        dark: colors[secondary]['600'],
      },
      error: {
        main: colors[error]['500'],
        light: colors[error]['400'],
        dark: colors[error]['600'],
      },
      text: {
        disabled: colors[neutral]['400'],
        icon: 'rgba(0, 0, 0, 0.5)',
        primary: colors[neutral]['900'],
        secondary: colors[neutral]['700'],
      },
      divider: colors[neutral]['200'],
      gray: colors[neutral],
      background: {
        default: colors[neutral]['50'],
        paper: colors[standard],
      },
    },
  },
}

module.exports = light
