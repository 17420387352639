import { HiOutlineTicket, HiTicket } from 'react-icons/hi'

import { SPLIT_BY } from '@/config/formOptions'
import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonPeacockSdpConfig from './common/peacockSdp'

const activeSubscriptions: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const peacockSdpConfig = commonPeacockSdpConfig({ variants })
  return {
    ...peacockSdpConfig,
    value: 'active-subscriptions',
    label: 'Active Subscriptions',
    shortLabel: 'Subscriptions',
    dataKey: 'activeSubscriptions',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 5,
      },
    },
    icon: HiOutlineTicket,
    solidIcon: HiTicket,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Active Subscriptions shows the maximum number of active subscriptions in a time period. Please note that this data currently includes trialists.',
    aggregationType: 'peak',
    form: [
      {
        value: 'split-by',
        type: 'button-group',
        multi: false,
        options: [SPLIT_BY['package'], SPLIT_BY['subscription-type']],
      },
    ],
    defaultConfig: {
      ...peacockSdpConfig.defaultConfig,
      metric: 'active-subscriptions',
      'split-by': 'package',
    },
  }
}

export default activeSubscriptions
