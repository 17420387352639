import {
  CLIENT_DEFAULT_TIMEZONES,
  DECISION_SCIENCES_API_DOMAIN,
  DEFAULT_DATE,
} from '@/config/constants'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonFiveMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { VariantsT } from '@/config/types'

const commonDecisionSciencesConfig = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variants,
}: {
  variants?: VariantsT
}) => {
  return {
    dataSource: 'Decision Sciences',
    legacyGroup: 'Decision Sciences',
    group: GROUPS.DECISION_SCIENCES,
    apiDomain: DECISION_SCIENCES_API_DOMAIN,
    supportsClient: true,
    hideInMetricsExplorer: true,
    timezone: CLIENT_DEFAULT_TIMEZONES['peacock'],
    useTimezone: true,
    formatter: convivaStyleDataFormatters,
    granularity: commonFiveMinuteGranularity,
    defaultConfig: {
      ...DEFAULT_DATE('peacock'),
      client: 'peacock',
    },
  }
}

export default commonDecisionSciencesConfig
