// Allows us to handle some of the pesky renames such as 'now' rather than 'now-tv'
// And apply the client param

import {
  ClientT,
  EventsSettingsI,
  MetricSettingsI,
  ReportSettingsI,
  SettingsI,
  VariantsT,
} from '@/config/types'

const getClientSupportedPropositions = (propositions?: string[]) => {
  if (!propositions || !Array.isArray(propositions)) return ['all']
  else {
    return propositions?.map((proposition) =>
      proposition.replace('now-tv', 'now'),
    )
  }
}

const createVariantsSupportedConfig = ({
  config,
  variants,
  client,
  settings,
}: {
  config: ConfigI
  variants?: VariantsT
  client: ClientT
  settings: MetricSettingsI | EventsSettingsI | SettingsI | ReportSettingsI
}) => {
  const withClient = (variants?.withClient || false) && settings?.supportsClient

  const proposition = withClient
    ? getClientSupportedPropositions(config?.proposition)
    : config?.proposition

  const variantsSupportedConfig = {
    ...config,
    ...(withClient ? { client } : {}),
  }

  if (proposition) {
    return {
      ...variantsSupportedConfig,
      proposition,
    }
  } else {
    return variantsSupportedConfig
  }
}

export default createVariantsSupportedConfig
