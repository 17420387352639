import { DashStyleValue } from 'highcharts'
import { startCase } from 'lodash'
import { HiOutlineExternalLink } from 'react-icons/hi'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

import ImageFallback from '@/components/ImageFallback'
import { DEFAULT_EVENT_METRIC } from '@/config/constants'
import { getPropositionImage, getTerritoryImage } from '@/config/formOptions'
import useEventsData from '@/hooks/useEventsData'
import useEventsSettings from '@/hooks/useEventsSettings'
import useMetricSettings from '@/hooks/useMetricSettings'
import useTheme from '@/hooks/useTheme'
import { QUALITY_METRICS } from '@/pages/Content/AllEvents'
import { sortLegendItems } from '@/pages/MetricsExplorer/components/MetricTile/MetricTileSummaryPanel/utils'
import { useClientStore } from '@/stores/client.store'
import { StoreEventI, useEventStore } from '@/stores/event.store'
import { useTimeStore } from '@/stores/time.store'
import { useUiStore } from '@/stores/ui.store'
import { cn } from '@/utils/cn'
import getChannelLogo from '@/utils/getChannelLogo'
import getDateRangeString from '@/utils/getDateRangeString'
import getEventConfigParams from '@/utils/getEventConfigParams'
import getLabel from '@/utils/getLabel'
import getLabels from '@/utils/getLabels'
import getMinutesFromMs from '@/utils/getMinutesFromMs'
import getTimezoneLabel from '@/utils/getTimezoneLabel'
import getVideoFormatConfig from '@/utils/getVideoFormatConfig'
import { getEventDetailsPageRoute } from '@/utils/routes'

import Line from '../Chart/Line'
import Chip from '../Chip'
import DevUrl from '../DevUrl'
import Live from '../Live'
import Status from '../Status'
import EventItemQualityMetrics from './EventItemQualityMetrics'
import OcellusEventSwitch from './OcellusEventsSwitch'

export function EventItemMeta({ event }: { event: Conviva2EventI }) {
  const genre = event?.meta?.genre || null
  const subGenre = event?.meta?.subGenre || null
  const durationMilliseconds = event?.meta?.durationMilliseconds || null

  const genreExists = !!genre && genre !== 'Undefined'
  const subGenreExists = !!subGenre && subGenre !== 'Undefined'

  return (
    <div className='inline-flex items-center space-x-1 text-sm truncate text-text-dimmed'>
      {genreExists && <p>{genre}</p>}
      {subGenreExists && (
        <>
          {genreExists && <span>∙</span>}
          <p>{subGenre}</p>
        </>
      )}
      {durationMilliseconds && (
        <>
          {(genreExists || subGenreExists) && <span>∙</span>}
          <p>{getMinutesFromMs(durationMilliseconds)}</p>
        </>
      )}
    </div>
  )
}

type ContentType = 'accordionContent' | 'slideOverContent'

const checkIsEmpty = (eventQuery: any) => {
  if (
    !eventQuery ||
    !eventQuery.data ||
    !eventQuery.data.data ||
    !eventQuery.data?.data?.[0] ||
    !eventQuery.data?.data?.[0]?.timeseries
  ) {
    return true
  }

  if (eventQuery.data.data.length === 0) {
    return true
  }
  if (Object.keys(eventQuery.data?.data?.[0]?.timeseries)?.length === 0) {
    return true
  }
  return false
}

const EventVideoFormatSplit = ({
  eventQuery,
  config,
  metric,
}: {
  eventQuery: any
  config: ConfigI
  metric?: string
}) => {
  const { type } = useTheme() || {}
  const streamType = config['stream-type'] as StreamTypes

  const event = eventQuery?.data?.data?.[0]

  const getVideoFormatSplits = () => {
    if (streamType === 'vod' && event.metricsByVideoFormat) {
      return event.metricsByVideoFormat
    }
    if (streamType === 'sle' && event.metricsByVideoFormat) {
      return event.metricsByVideoFormat
    }

    return null
  }

  const videoFormatSplits = getVideoFormatSplits()

  const calculatePercentage = (value: number) => {
    const totalPlays = event.plays || event.concurrentPlays || 0

    if (totalPlays === 0) return null

    return Math.round((value / totalPlays) * 100)
  }

  const filterInvalidKeys = (
    entries: [string, number][],
  ): [string, number][] => {
    // temporarily filtering out 'all' for Ocellus events, will be changed to 'unknown' on the BE
    return entries.filter(
      ([key]) => !!key && key !== 'unknown' && key !== 'all',
    )
  }

  if (!metric || !streamType) {
    return null
  }

  if (!videoFormatSplits) {
    return null
  }

  return (
    <div className='flex flex-wrap items-center mt-1 gap-y-1'>
      {filterInvalidKeys(Object.entries(videoFormatSplits)).map(
        ([key, value]: [string, any]) => {
          const percentageValue = calculatePercentage(
            value.concurrentPlays || value.plays || 0,
          )

          // If totalPlays are 0, the percentageValue will return null, and then we don't show the video quality split icon and value
          if (!percentageValue) return null

          const imageColorClass = type === 'light' ? 'invert' : 'invert-0'

          return (
            <div key={`${key}-${value}`} className='flex items-center gap-y-1'>
              <img
                src={`/images/videoFormat/${key}.png`}
                className={`${imageColorClass} h-3 mr-1`}
                alt={key}
              />
              <span className='mr-1 text-xs font-medium'>{`${percentageValue}%`}</span>
            </div>
          )
        },
      )}
    </div>
  )
}

const EventItemPanelContentAggregation = ({
  config,
  contentType,
  eventQuery,
  metric,
}: {
  config: ConfigI
  contentType: ContentType
  eventQuery: any
  metric?: string
}) => {
  const metricSettings = useMetricSettings({
    metric: metric || 'plays',
    variants: { withClient: true },
  })

  if (contentType === 'slideOverContent') {
    return (
      <div>
        <p className='mb-1.5 text-sm font-medium uppercase tracking-wider text-text-tertiary'>
          {`${startCase(metricSettings?.aggregationType)} ${
            metricSettings?.label
          }`}
        </p>
        <p className='text-lg font-semibold tracking-tight text-text-primary tabular-nums'>
          {eventQuery.status === 'loading' || eventQuery.isFetching ? (
            <Skeleton width={135} />
          ) : (
            <>
              <span>
                {metricSettings?.valueFormat(
                  typeof eventQuery?.data?.data?.[0]?.concurrentPlays ===
                    'number'
                    ? eventQuery?.data?.data?.[0]?.concurrentPlays
                    : eventQuery?.data?.data?.[0]?.plays,
                )}
              </span>
            </>
          )}
        </p>

        <EventVideoFormatSplit
          eventQuery={eventQuery}
          config={config}
          metric={metric}
        />
      </div>
    )
  }
  return null
}

const EventItemPanelContentHeader = ({
  contentType,
  event,
  config,
  eventQuery,
  metric,
}: {
  contentType: ContentType
  event: Conviva2EventI
  config: ConfigI
  eventQuery: any
  metric?: string
}) => {
  const timezone = useTimeStore((state) => state.timezone)
  const eventsSettings = useEventsSettings({ variants: { withClient: true } })
  const metricSettings = useMetricSettings({
    metric: metric || 'plays',
    variants: { withClient: true },
  })

  const channelLogo = getChannelLogo(event?.meta, event?.territories)

  const { start, end, relative } = getDateRangeString({
    start: config?.start,
    end: config?.end,
    timezone,
  })

  if (contentType === 'slideOverContent') {
    return (
      <div className='border-b border-border-main'>
        <div className='relative w-full px-6 pt-4 h-60'>
          <ImageFallback
            src={event.meta?.imageUrl?.replace('/16-9/500', '/16-9/1000')}
            alt={event?.meta?.title}
            className='object-cover w-full h-full rounded-lg'
          />

          {channelLogo && (
            <ImageFallback
              src={channelLogo}
              alt={'Channel Logo'}
              className='absolute object-auto max-h-6 xl:max-h-8 max-w-24 bottom-4 left-10'
              transparentFallback
            />
          )}
        </div>
        <div className='px-4 pt-6 pb-8 space-y-4 lg:px-6'>
          <div className='text-lg font-medium text-text-primary'>
            {event?.meta?.title}
          </div>
          {start && end && config['stream-type'] !== 'vod' && (
            <div className='pb-4 mb-4 border-b border-border-main'>
              <div className='flex items-center pb-1.5'>
                <Live
                  start={config?.start}
                  end={config?.end}
                  className='mr-1.5 text-base'
                />
                <p className='text-base truncate text-text-primary'>
                  {start} - {end}
                </p>
              </div>
              <p className='text-sm text-text-dimmed'>({relative})</p>
            </div>
          )}

          <p className='text-sm italic text-text-tertiary'>
            {event?.meta?.description || event?.meta?.summary}
          </p>

          <EventItemMeta event={event} />

          <div className='flex flex-wrap items-center gap-y-1'>
            {eventsSettings.showIcons.territory.enabled &&
              getLabels({
                event: eventQuery.data?.data?.[0] || event,
                settings: eventsSettings.showIcons.territory,
              })?.map((territoryLabel) => (
                <Chip
                  key={territoryLabel}
                  label={getLabel({
                    value: territoryLabel,
                    formGroup: 'territory',
                    settings: metricSettings,
                  })}
                  image={getTerritoryImage(territoryLabel)}
                  icon={undefined}
                />
              ))}

            {eventsSettings.showIcons.proposition.enabled &&
              getLabels({
                event: eventQuery.data?.data?.[0] || event,
                settings: eventsSettings.showIcons.proposition,
              })?.map((propositionLabel) => (
                <Chip
                  key={propositionLabel}
                  label={getLabel({
                    value: propositionLabel,
                    formGroup: 'proposition',
                    settings: metricSettings,
                  })}
                  image={getPropositionImage(propositionLabel)}
                  icon={undefined}
                />
              ))}

            {event?.meta?.channelName && (
              <Chip
                label={event.meta.channelName}
                image={undefined}
                icon={undefined}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
  if (contentType === 'accordionContent') {
    return (
      <p className='pt-2 pb-4 text-sm italic text-text-tertiary'>
        {event?.meta?.description || event?.meta?.summary}
      </p>
    )
  }

  return null
}

const EventItemPanelContent = ({
  event,
  config: initialConfig,
  contentType = 'slideOverContent',
  open,
  onClose,
  containerClasses,
  eventLinkClasses,
}: {
  event: StoreEventI
  config: ConfigI
  contentType?: ContentType
  open?: boolean
  onClose?: () => void
  containerClasses?: string
  eventLinkClasses?: string
}) => {
  const client = useClientStore((state) => state.client)
  const timezone = useTimeStore((state) => state.timezone)
  const developerMode = useUiStore((state) => state.developerMode)
  const { isOcellus, setIsOcellus } = useEventStore((state) => ({
    isOcellus: state.isOcellus,
    setIsOcellus: state.setIsOcellus,
  }))
  const { theme } = useTheme()!

  let streamType =
    ((initialConfig?.['stream-type'] || event.streamType) as
      | StreamTypes
      | StreamTypes[]
      | undefined) || 'linear'

  if (Array.isArray(streamType)) {
    streamType = streamType[0]
  }

  const mainMetric = DEFAULT_EVENT_METRIC[streamType]

  const eventConfigParams = getEventConfigParams(event, initialConfig)

  // Extract params from event to be passed to Link, so they can be used for event query on the Event Details Page

  // When Event Panel is opened through Top Events sections on the homepage, it doesn't get additional quality metrics from url params, as for the Top Events we only request the main metric (concurrent-plays) to avoid requesting extra data when it's not needed. This bit of code will check if additional quality metrics are present and push them to config if needed. Currently additional metrics are not supported for VOD, but once implemented, we'll need to remove the stream type check.

  const config: ConfigI = {
    ...initialConfig,
    ...eventConfigParams,
    metric:
      eventConfigParams['stream-type'] !== 'vod' &&
      eventConfigParams.metric?.length === 1 &&
      Array.isArray(eventConfigParams.metric)
        ? [...eventConfigParams.metric, ...QUALITY_METRICS]
        : eventConfigParams.metric,
  }

  const metricSettings = useMetricSettings({
    metric: mainMetric,
    variants: {
      withClient: true,
      isOcellus: isOcellus && client === 'peacock',
    },
  })

  const uid = event?.identifier || event?.eventUid

  const { event: eventQuery, url: eventUrl } = useEventsData({
    key: `event-${uid}`,
    config: {
      ...config,
    },
    reactQueryProps: {
      enabled: !!event && open,
    },
    singleQuery: true,
    variants: {
      withClient: true,
      isOcellus: isOcellus && client === 'peacock',
    },
  })

  const videoFormatConfig = getVideoFormatConfig(theme)

  const formattedEventData = eventQuery?.data?.data?.[0]?.timeseries
    ? Object.entries(eventQuery.data.data[0].timeseries)
        .filter(([key]: [string, any], _, allSeries) => {
          if (allSeries.length === 2) {
            // Return only the Total series if there are 2 series, otherwise the lines just overlap as they have the same values
            return key === 'total'
          } else {
            // Return all series if there are more than 1
            return true
          }
        })
        .map(([key, value]: [string, any]) => {
          const isTotal = key === 'total'

          if (!videoFormatConfig[key]) {
            return {
              name: videoFormatConfig['unknown'].name,
              data: [],
              color: videoFormatConfig['unknown'].color,
              isTotal,
              dashStyle: 'Solid',
            }
          }

          return {
            name: videoFormatConfig[key].label,
            data:
              value?.map((datum: any) => [
                datum.timestamp,
                (datum['concurrentPlays'] ?? datum['plays']) || 0,
              ]) || [],
            color: videoFormatConfig[key].color,
            isTotal,
            dashStyle: 'Solid',
          }
        })
    : []

  const showStartAndEndPlotLines =
    event && streamType === 'sle' && !!event?.meta?.rightsStartTimeEpochUTC

  return (
    <div
      className={cn(
        'relative pb-16 h-screen overflow-x-hidden overflow-y-auto',
        containerClasses,
      )}
    >
      <EventItemPanelContentHeader
        contentType={contentType}
        event={event}
        config={config}
        eventQuery={eventQuery}
        metric={mainMetric}
      />
      <div>
        {client === 'peacock' && (
          <div className='flex items-center pt-6 pb-3 pl-6'>
            <OcellusEventSwitch
              checked={isOcellus}
              onChange={() => setIsOcellus(!isOcellus)}
            />
          </div>
        )}

        {developerMode && <DevUrl url={eventUrl} />}
        <div
          className={cn({
            'px-4 py-8 lg:px-6': contentType === 'slideOverContent',
          })}
        >
          <Status
            {...eventQuery}
            isEmpty={checkIsEmpty(eventQuery)}
            statusWrapperProps={{ style: { minHeight: 300 } }}
          >
            <EventItemQualityMetrics
              qualityMetrics={QUALITY_METRICS}
              eventData={eventQuery?.data?.data[0]}
              wrapperClassName='flex justify-between mb-6 pb-6 border-b border-border-main'
              itemClassName='flex flex-col items-center mt-1 text-xs font-medium text-right text-text-secondary tabular-nums'
            />

            <EventItemPanelContentAggregation
              config={config}
              contentType={contentType}
              eventQuery={eventQuery}
              metric={mainMetric}
            />
            <div className='flex-1 mt-4' style={{ height: 300 }}>
              <Line
                xTitle={`Timezone ${getTimezoneLabel(config.start, timezone)}`}
                xTitleProps={{
                  margin: 10,
                  align: 'high',
                  style: {
                    fontSize: '10px',
                  },
                }}
                data={sortLegendItems(formattedEventData)}
                yAxisFormat={metricSettings?.yAxisFormat}
                valueFormat={metricSettings?.valueFormat}
                aggregationType={metricSettings?.aggregationType}
                chartType='areaspline'
                exportingEnabled={false}
                plotLines={getStartAndEndPlotLinesSLE({
                  event,
                  showStartAndEndPlotLines,
                  theme,
                })}
              />
            </div>
          </Status>
          <p className='py-4 text-xs text-right text-text-dimmed'>
            <span>ID:</span> {event?.eventUid || event?.identifier}
          </p>
        </div>
      </div>
      <div
        className={cn(
          'fixed bottom-0 right-0 w-[calc(100vw-50px)] max-w-[448px]',
          eventLinkClasses,
        )}
      >
        <div
          className={cn(
            'w-screen max-w-full sm:max-w-[448px] px-4 py-8 lg:px-6 bg-neutral-dimmed-heavy/10 backdrop-blur-sm',
            eventLinkClasses,
          )}
        >
          <Link
            className='flex justify-center px-4 py-2 ml-3 text-sm font-medium border border-transparent rounded-md shadow-sm cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 text-elements-primary-contrastText bg-elements-primary-main hover:bg-elements-primary-shadow focus-visible:ring-elements-primary-main'
            to={getEventDetailsPageRoute({
              client,
              // For come clients stream type comes as array and for other comes as string
              streamType,
              eventId: event.identifier || event.eventUid || '',
              params: { ...config, metric: ['all'] },
            })}
            state={{ event }}
            onClick={onClose}
          >
            <span>Open Event Page</span>

            <HiOutlineExternalLink className='w-4 h-4 ml-2' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EventItemPanelContent

function getStartAndEndPlotLinesSLE({
  event,
  theme,
  showStartAndEndPlotLines,
}: {
  event: Conviva2EventI
  theme: any
  showStartAndEndPlotLines: boolean
}) {
  if (showStartAndEndPlotLines)
    return [
      {
        label: {
          text: 'Start',
          style: { color: theme.colors['text-tertiary'] },
        },
        value: event?.meta?.rightsStartTimeEpochUTC,
        width: 1,
        color: theme.colors['text-tertiary'],
        zIndex: 10,
        dashStyle: 'LongDash' as DashStyleValue,
        useHTML: true,
      },
      {
        label: {
          text: 'End',
          style: { color: theme.colors['text-tertiary'] },
        },
        value: event?.meta?.rightsEndTimeEpochUTC,
        width: 1,
        color: theme.colors['text-tertiary'],
        zIndex: 10,
        dashStyle: 'LongDash' as DashStyleValue,
        useHTML: true,
      },
    ]

  return []
}
