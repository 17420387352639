import useSettings from '@/hooks/useSettings'
import { useUserStore } from '@/stores/user.store'
import { cn } from '@/utils/cn'

export default function RequestContextContent({
  className,
}: {
  className?: string
}) {
  const { contactPageSettings } = useSettings()
  const user = useUserStore((state) => state.user)

  const isNBCU = JSON.stringify(user).includes('@nbcuni.com')

  const teamId = contactPageSettings.slack.teamId[isNBCU ? 'nbcu' : 'sky']

  return (
    <div className={cn(className)}>
      <p className='mb-4'>
        To request context access, please visit{' '}
        <a
          href={`slack://channel?team=${teamId}&id=${contactPageSettings.slack.id}`}
          target='_blank'
          rel='noreferrer'
          className='text-elements-primary-main hover:underline'
        >
          {contactPageSettings.slack.channel}
        </a>{' '}
        on Slack, or email{' '}
        <a
          href={`mailto:${contactPageSettings.email.address}`}
          target='_blank'
          rel='noreferrer'
          className='text-elements-primary-main hover:underline'
        >
          {contactPageSettings.email.address}
        </a>
        . When requesting a context, please remember to state a business reason
        for your request.
      </p>
    </div>
  )
}
