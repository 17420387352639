import { isEqual } from 'lodash'
import * as React from 'react'

import { FormI } from '@/config/types'
import { useMetricsExplorerTabs } from '@/pages/MetricsExplorer/hooks/useMetricsExplorerTabs'
import { useMetricsExplorerTagsData } from '@/pages/MetricsExplorer/hooks/useMetricsExplorerTagsData'
import { useSharedChartStore } from '@/stores/shared-chart.store'
import { logEvent } from '@/utils/firebaseAnalytics'

import {
  getApplicableMetricFilters,
  mergeFormWithTags,
  pushValue,
  removeUnavailableActiveFilters,
  useAvailableTabFilters,
} from './utils/filters'

export type TagsErrorT = MetricsExplorerTagI & { metric: string }

interface Context {
  activeTab?: MetricsExplorerTabI
  availableFilters?: FormI[]
  activeFilters?: FiltersI
  updateFilters: ({
    formGroup,
    value,
    checked,
    isMulti,
  }: {
    formGroup: keyof FiltersI
    value: string
    checked: boolean
    isMulti: boolean
  }) => void
  tags: MetricsExplorerTagsI
  tagsIsLoading: boolean
  tagsWithErrors: TagsErrorT[]
}

export const FiltersContext = React.createContext<Context>({
  activeFilters: {},
  updateFilters: () => null,
  tags: {},
  tagsIsLoading: false,
  tagsWithErrors: [],
})

export const FiltersProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const previousFiltersRef = React.useRef<FiltersI>()
  const { activeTab, updateTab } = useMetricsExplorerTabs()
  const setSharedPlotBand = useSharedChartStore(
    (state) => state.setSharedPlotBand,
  )
  const tags = useMetricsExplorerTagsData()

  const tabMetrics = activeTab.tiles
    .filter((tile) => typeof tile.metric === 'string')
    .map((tile) => tile.metric) as string[]

  const availableFilters = useAvailableTabFilters({
    metrics: tabMetrics,
    tags,
  })
  const activeFilters = activeTab?.filters

  const updateFilters = React.useCallback(
    ({
      formGroup,
      value,
      checked,
      isMulti,
    }: {
      formGroup: keyof FiltersI
      value: string
      checked: boolean
      isMulti: boolean
    }) => {
      updateTab((prevState) => {
        const nextTab: MetricsExplorerTabI = {
          ...prevState,
          selectedEventTimelineItemId: null,
        }

        let eventName

        if (checked) {
          eventName = 'select_filter'

          nextTab.filters = {
            ...prevState.filters,
            [formGroup]: isMulti
              ? pushValue(value, prevState.filters?.[formGroup])
              : value,
          }
        } else {
          const activeSelection = prevState.filters?.[formGroup]
          if (activeSelection && Array.isArray(activeSelection)) {
            const selectedValues = activeSelection.filter(
              (selectedValue) => value !== selectedValue,
            )
            if (selectedValues.length === 0) {
              eventName = 'remove_filter'
              delete nextTab.filters?.[formGroup]
            } else {
              eventName = 'select_filter'

              nextTab.filters = {
                ...prevState.filters,
                [formGroup]: selectedValues,
              }
            }
          } else {
            eventName = 'remove_filter'

            delete nextTab.filters?.[formGroup]
          }
        }

        logEvent(eventName, {
          filterName: formGroup,
          [formGroup]: value,
        })

        return nextTab
      })
      setSharedPlotBand(null)
    },
    [updateTab, setSharedPlotBand],
  )

  React.useEffect(() => {
    // Remove active filters that aren't within the available filters
    // Mainly useful for resetting filters after metric/tile removal

    const tagsIsLoading = Object.values(tags).some((tag) => tag.isLoading)
    const tagsIsError = Object.values(tags).some((tag) => tag.isError)

    // Don't do anything here if the tags are still loading or erroring
    if (tagsIsLoading || tagsIsError) return

    const filters = removeUnavailableActiveFilters({
      availableFilters,
      activeFilters,
    })

    // Only update the filters if they have actually changed
    if (!isEqual(filters, previousFiltersRef.current)) {
      updateTab((prevState) => {
        return {
          ...prevState,
          filters,
        }
      })

      previousFiltersRef.current = filters
    }
  }, [availableFilters, tags])

  const tagsIsLoading = Object.values(tags).some((tag) => tag.isLoading)
  const tagsWithErrors = Object.entries(tags)
    .map(([metric, tag]) => ({ ...tag, metric }))
    .filter((tag) => tag.isError)

  const context = React.useMemo(
    () => ({
      activeTab,
      activeFilters,
      availableFilters,
      tags,
      tagsIsLoading,
      tagsWithErrors,
      updateFilters,
    }),
    [
      activeTab.filters,
      activeTab.sequenceIndex,
      activeTab.tiles,
      tags,
      availableFilters,
      updateFilters,
    ],
  )

  return (
    <FiltersContext.Provider value={context}>
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = () => {
  const context = React.useContext(FiltersContext)
  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider')
  }
  return context
}

export const useApplicableMetricFilters = ({
  metric,
  form,
}: {
  metric: string
  form: FormI[]
}) => {
  const { activeFilters, tags } = useFilters()

  const mergedFormWithTags = React.useMemo(
    () => mergeFormWithTags({ metric, tags, form }),
    [metric, tags, form],
  )

  const applicableFilters = React.useMemo(() => {
    return getApplicableMetricFilters({
      activeFilters,
      form: mergedFormWithTags,
    })
  }, [activeFilters, mergedFormWithTags])

  return applicableFilters
}
