const peacockPallette = {
  supernova: {
    50: '#FFFCF0',
    100: '#FEF7D7',
    200: '#FEEFB3',
    300: '#FDE686',
    400: '#FDDB54',
    500: '#FCCF1E',
    600: '#E8BA03',
    700: '#C9A203',
    800: '#AB8902',
    900: '#796102',
    950: '#5B4901',
  },
  curiousblue: {
    50: '#ECF8FD',
    100: '#DEF3FC',
    200: '#B8E6F9',
    300: '#89D5F6',
    400: '#4CBFF1',
    500: '#12A2E2',
    600: '#1093CB',
    700: '#0E82B3',
    800: '#0C6D97',
    900: '#084B68',
    950: '#063347',
  },
  fuchsiablue: {
    50: '#F8F7FD',
    100: '#EDEAFB',
    200: '#D7D0F5',
    300: '#BEB3EF',
    400: '#A191E8',
    500: '#765EDE',
    600: '#6B51DB',
    700: '#593CD7',
    800: '#4629C7',
    900: '#2E1B83',
    950: '#25166A',
  },
  pumpkin: {
    50: '#FFF4F0',
    100: '#FFEAE0',
    200: '#FFD4C2',
    300: '#FFB899',
    400: '#FF976B',
    500: '#FF6521',
    600: '#FA4B00',
    700: '#D64000',
    800: '#B33600',
    900: '#802600',
    950: '#5C1C00',
  },

  redribbon: {
    50: '#FEF1F4',
    100: '#FDE8EC',
    200: '#FACCD6',
    300: '#F6A7B8',
    400: '#F27893',
    500: '#E91E4B',
    600: '#D6153F',
    700: '#BA1236',
    800: '#9E0F2E',
    900: '#740B22',
    950: '#4F0817',
  },
  uranium: {
    50: '#E8FDEF',
    100: '#CCFADC',
    200: '#8BF4AF',
    300: '#16E960',
    400: '#14CD54',
    500: '#11B049',
    600: '#0F9E41',
    700: '#0D8C3A',
    800: '#0B7430',
    900: '#084F21',
    950: '#053817',
  },
}

module.exports = peacockPallette
