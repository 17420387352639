import { Moment } from 'moment'

/*
getDateRangeFromConfig checks the config for a relativeDate (see src/config/relativeDates.ts) and returns a start/end value for the API queries.
If `extendToMidnight` is true, for any dates that end in the current minute, it will extend the selection to the end of the day. 
`extendToMidnight` is used on the event reporter seletion when we want to see all the events for the current day, not only the ones up to the current minute.
*/

const getDateRangeFromConfig = (
  config: ConfigI,
  extendToMidnight?: boolean,
) => {
  let _start, _end, _timePeriod

  if (config?.dateType === 'relative' && config?.relativeDate?.getDateRange) {
    const dateParams = config?.relativeDate?.getDateRange()
    const startEndDateParams = dateParams as { start: Moment; end: Moment }
    const timePeriodDateParams = dateParams as { 'time-period': string }
    const { start, end } = startEndDateParams

    if (start) {
      _start = start.clone().utc().format()
    }

    if (end) {
      if (extendToMidnight) {
        _end = end.clone().endOf('day').utc().format()
      } else {
        _end = end.clone().utc().format()
      }
    }

    if (timePeriodDateParams['time-period']) {
      _timePeriod = timePeriodDateParams['time-period']
    }
  } else if (config?.start && config?.end) {
    _start = config?.start
    _end = config?.end
  }

  return {
    start: _start,
    end: _end,
    'time-period': _timePeriod,
  }
}

export default getDateRangeFromConfig
