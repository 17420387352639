import groupBy from 'lodash/groupBy'

import useSettings from '@/hooks/useSettings'
import { logEvent } from '@/utils/firebaseAnalytics'

import DatePickerQuickRangeItem from './DatePickerQuickRangeItem'

interface DatePickerQuickRangesProps {
  config: ConfigI
  relativeDates?: RelativeDateI[]
  onUpdateConfig: (config: RelativeDateI) => void
}

export default function DatePickerQuickRanges({
  relativeDates: propsRelativeDates,
  config,
  onUpdateConfig,
}: DatePickerQuickRangesProps) {
  const { relativeDates: settingsRelativeDates } = useSettings()
  const relativeDates = propsRelativeDates ?? settingsRelativeDates

  const relativeDatesGrouped = groupBy(relativeDates, 'group')

  const handleQuickRangeItemSelect = (relativeDateItem: RelativeDateI) => {
    logEvent('select_date', {
      dateType: 'relative' as const,
      ...relativeDateItem,
    })
    return onUpdateConfig(relativeDateItem)
  }

  return (
    <div className='flex flex-col gap-y-4'>
      {Object.keys(relativeDatesGrouped).map((group) => {
        const dateItems = relativeDatesGrouped[group].filter(
          ({ id }) => id !== 'custom',
        )

        const hasItems = dateItems.length > 0

        if (!hasItems) return null

        return (
          <div key={group} role='group' aria-labelledby={group}>
            <h3 id={group} className='mb-2 text-xs uppercase text-text-dimmed'>
              {group}
            </h3>
            <div className='flex flex-col'>
              {dateItems.map((relativeDateItem) => {
                const isSelected =
                  config?.relativeDate?.id === relativeDateItem.id

                return (
                  <DatePickerQuickRangeItem
                    key={relativeDateItem.id}
                    relativeDateItem={relativeDateItem}
                    isSelected={isSelected}
                    onSelect={handleQuickRangeItemSelect}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
