import { ClientT, MinutesUniquesSettingsI } from '../types'
import nbcuDtcMinutesUniquesSettings from './nbcu-dtc'
import nowMinutesUniquesSettings from './now'
import nowOTTMinutesUniquesSettings from './nowott'
import peacockMinutesUniquesSettings from './peacock'
import showmaxMinutesUniquesSettings from './showmax'
import skyEuropeMinutesUniquesSettings from './sky-europe'
import skyEuropeAndPeacockMinutesUniquesSettings from './sky-europe-and-peacock'
import skyshowtimeMinutesUniquesSettings from './skyshowtime'

export const minutesUniquesSettings: Record<ClientT, MinutesUniquesSettingsI> =
  {
    peacock: peacockMinutesUniquesSettings,
    skyshowtime: skyshowtimeMinutesUniquesSettings,
    now: nowMinutesUniquesSettings,
    nowott: nowOTTMinutesUniquesSettings,
    'nbcu-dtc': nbcuDtcMinutesUniquesSettings,
    'sky-europe-and-peacock': skyEuropeAndPeacockMinutesUniquesSettings,
    'sky-europe': skyEuropeMinutesUniquesSettings,
    showmax: showmaxMinutesUniquesSettings,
  }

export default minutesUniquesSettings
