import moment from 'moment'

import { DEFAULT_EVENT_METRIC } from '@/config/constants'
import { StoreEventI } from '@/stores/event.store'

const formatDate = (date: any) => {
  return moment(date).clone().utc().format()
}

const getEventConfigParams = (
  event: Conviva2EventI | StoreEventI,
  config: ConfigI,
) => {
  const params: { [key: string]: string | string[] | undefined } = {
    proposition: event?.propositions || [],
    territory: event?.territories || [],
  }

  let streamType = (config['stream-type'] ||
    (event as StoreEventI).streamType) as StreamTypes | StreamTypes[]
  if (Array.isArray(streamType)) {
    streamType = streamType[0]
  }

  const eventMetrics = config?.metric || []
  if (typeof eventMetrics === 'string') {
    params['metric'] = [eventMetrics]
  } else {
    params['metric'] = eventMetrics
  }

  params['stream-type'] = streamType

  // Filter out the default metrics and add below per stream type.
  // Linear and sle events can query plays
  if (streamType === 'vod' && config['time-period'] !== 'now') {
    params['metric'] = params['metric'].filter(
      (metric) => metric !== 'concurrent-plays',
    )
    params['metric'] = [DEFAULT_EVENT_METRIC[streamType], ...params['metric']]
  } else if (streamType === 'vod' && config['time-period'] === 'now') {
    params['metric'] = ['concurrent-plays', ...params['metric']]
  } else if (!params['metric']?.includes(DEFAULT_EVENT_METRIC[streamType])) {
    params['metric'] = [DEFAULT_EVENT_METRIC[streamType], ...params['metric']]
  }

  switch (streamType) {
    case 'linear':
      params['event-uid'] = event?.eventUid
      params['start'] = formatDate(event?.meta?.startTimeEpochUTC)
      params['end'] = formatDate(event?.meta?.endTimeEpochUTC)
      break
    case 'vod':
      params['identifier'] = event?.identifier
      if (config?.start && config?.end) {
        const _start = moment(config.start)
        const _end = moment(config.end)

        params['start'] = _start.clone().utc().format()
        params['end'] = _end.clone().utc().format()

        const hoursDifference = _end.diff(_start, 'hours')

        if (hoursDifference >= 4 && hoursDifference <= 48) {
          params['granularity'] = 'hourly'
        }
      }
      break
    case 'sle':
      params['identifier'] = event?.identifier
      params['start'] = formatDate(event?.meta?.rightsStartTimeEpochUTC)
      params['end'] = formatDate(event?.meta?.rightsEndTimeEpochUTC)
      //
      break
  }

  return params
}

export default getEventConfigParams
