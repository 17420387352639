import { ClientT, WeeklyViewingReportSettingsI } from '@/config/types'

import nbcuDtcWeeklyViewingSettings from './nbcu-dtc'
import nowWeeklyViewingSettings from './now'
import nowOTTWeeklyViewingSettings from './nowott'
import peacockWeeklyViewingSettings from './peacock'
import showmaxWeeklyViewingSettings from './showmax'
import skyEuropeWeeklyViewingSettings from './sky-europe'
import skyEuropeAndPeacockWeeklyViewingSettings from './sky-europe-and-peacock'
import skyshowtimeWeeklyViewingSettings from './skyshowtime'

export const eventReport: Record<ClientT, WeeklyViewingReportSettingsI> = {
  peacock: peacockWeeklyViewingSettings,
  skyshowtime: skyshowtimeWeeklyViewingSettings,
  now: nowWeeklyViewingSettings,
  nowott: nowOTTWeeklyViewingSettings,
  'nbcu-dtc': nbcuDtcWeeklyViewingSettings,
  'sky-europe-and-peacock': skyEuropeAndPeacockWeeklyViewingSettings,
  'sky-europe': skyEuropeWeeklyViewingSettings,
  showmax: showmaxWeeklyViewingSettings,
}

export default eventReport
