import React from 'react'

import { FormI } from '@/config/types'

import FiltersDropdown from './FiltersDropdown/FiltersDropdown'
import FiltersGroups from './FiltersGroups'

interface Context {
  availableFilters?: FormI[]
  activeFilters?: FiltersI
  deleteFilters: () => void
  updateFilters: (filterUpdate: {
    formGroup: string
    value: string
    checked: boolean
    isMulti?: boolean
  }) => void
}

export const FiltersContext = React.createContext<Context>({
  activeFilters: {},
  updateFilters: () => null,
  deleteFilters: () => null,
})

export default function Filters({
  filtersContext,
}: {
  filtersContext: Context
}) {
  return (
    <FiltersContext.Provider value={filtersContext}>
      <div className='relative flex items-center w-full h-full'>
        <div className='relative flex items-center w-full h-full p-1 pr-4 space-x-1 overflow-x-auto'>
          <FiltersDropdown />
          <FiltersGroups />
        </div>
        {
          // Edge gradient
        }
        <div className='absolute right-0 h-full w-11 bg-gradient-to-l from-neutral-dimmed via-neutral-dimmed to-transparent' />
      </div>
    </FiltersContext.Provider>
  )
}
