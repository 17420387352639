import moment from 'moment'
import { LuCircle } from 'react-icons/lu'

import { Button } from '@/components/ui/button'
import { DATE_FORMAT } from '@/config/constants'
import { BusiestDayI } from '@/config/types'
import useMetricSettings from '@/hooks/useMetricSettings'

import DatePickerBusiestDayTopEvents from './DatePickerBusiestDayTopEvents'

export const valueFormat = (value: number) => {
  return new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value)
}

interface DatePickerBusiestDayItemProps extends BusiestDayI {
  isSelected: boolean
  onSelect: (date: number) => void
  metric: 'concurrent-plays' | 'plays'
  ranking: number
  includeTopEvents: boolean
}

export default function DatePickerBusiestDayItem({
  timestamp,
  concurrentPlays,
  ranking,
  metric,
  topEvents,
  includeTopEvents,
  isSelected,
  onSelect,
}: DatePickerBusiestDayItemProps) {
  const metricSettings = useMetricSettings({
    metric,
    variants: { withClient: true },
  })
  const dateString = moment(timestamp).clone().format(DATE_FORMAT)

  return (
    <div>
      <Button
        role='button'
        variant='ghost'
        aria-label={`Select ${dateString}`}
        className='justify-start w-full h-auto py-3'
        onClick={() => {
          onSelect(timestamp)
        }}
      >
        <div className='w-6'>
          {isSelected && <LuCircle className='w-2 h-2 fill-current' />}
        </div>

        <div className='flex flex-col items-start'>
          <span className='text-sm text-text-primary'>{`${ranking}. ${dateString}`}</span>
          <div className='inline-flex items-center mt-1 text-xs text-text-dimmed'>
            <metricSettings.solidIcon className='h-3.5 w-3.5 mr-1.5' />
            <span className='sr-only'>{metricSettings.label}: </span>
            {valueFormat(concurrentPlays)}
          </div>
          <DatePickerBusiestDayTopEvents
            includeTopEvents={includeTopEvents}
            topEvents={topEvents}
            metric={metric}
          />
        </div>
      </Button>
    </div>
  )
}
