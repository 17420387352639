import { HiLogin, HiOutlineLogin } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonDecisionSciencesConfig from './common/decisionSciences'

const inflow: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const decisionSciencesConfig = commonDecisionSciencesConfig({ variants })

  return {
    ...decisionSciencesConfig,
    value: 'inflow',
    label: 'Inflow',
    form: [],
    shortLabel: 'Inflow',
    dataKey: 'inflow',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: false,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: false,
        order: 1,
        count: 10,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: false,
        },
        sle: {
          enabled: false,
        },
        count: 5,
      },
    },
    icon: HiOutlineLogin,
    solidIcon: HiLogin,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Inflow shows the number of user signing up during a given period.',
    aggregationType: 'total',
    defaultConfig: {
      ...decisionSciencesConfig.defaultConfig,
      metric: 'inflow',
    },
  }
}

export default inflow
