import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu'
import { FormI } from '@/config/types'
import { parseFilterSelectOptions } from '@/pages/MetricsExplorer/utils/parseFilterSelectOptions'

import FilterGroupTitleItem from '../FilterGroupTitleItem'
import FiltersSelectionCommand from './FiltersSelectionCommand'
import FiltersSelectionCommandVirtualised from './FiltersSelectionCommandVirtualised'

interface PropsI extends Pick<FormI, 'label' | 'options' | 'multi'> {
  value: keyof FiltersI
}

const MAX_OPTIONS = 100

export default function FiltersSelectionDropdownGroup({
  value,
  label,
  options,
  multi,
}: PropsI) {
  const Command =
    options.length > MAX_OPTIONS
      ? FiltersSelectionCommandVirtualised
      : FiltersSelectionCommand

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <FilterGroupTitleItem label={label} value={value} />
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <Command
            options={parseFilterSelectOptions(options)}
            multi={multi}
            value={value}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}
