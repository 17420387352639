import { v4 as uuidv4 } from 'uuid'

export function createEmptyTab(sequenceIndex: number) {
  return {
    name: null,
    sequenceIndex,
    isFavourite: false,
    tiles: [],
    selectedEventTimelineItemId: null,
    repollingEnabled: true,
    id: uuidv4(),
  }
}
