import { HiDotsVertical } from 'react-icons/hi'
import { IconType } from 'react-icons/lib'

import { cn } from '@/utils/cn'

import IconButton from '../IconButton'
import { Button } from '../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'

const PageHeader = ({
  title,
  buttons = [],
  children,
  childrenAsTitle,
  datePicker,
  className,
}: {
  title?: string
  className?: string
  buttons?: {
    label?: string
    onClick?: () => void
    Icon?: IconType
    className?: string
    labelClassName?: string
    badge?: { enabled: boolean }
  }[]
  children?: React.ReactNode
  childrenAsTitle?: boolean
  datePicker?: React.ReactNode
}): JSX.Element => {
  return (
    <div
      className={cn('relative flex items-center md:justify-between', className)}
    >
      <div className='flex-1 min-w-0'>
        {childrenAsTitle && children}
        {title && (
          <h2 className='text-xl font-bold leading-7 text-text-primary sm:text-2xl sm:truncate'>
            {title}
          </h2>
        )}
      </div>
      <div
        className={cn(
          'space-x-3 flex',
          buttons && buttons.length > 2 ? 'hidden md:flex' : 'flex',
        )}
      >
        {buttons.map(({ label, onClick, Icon, className, badge }, index) => {
          return (
            <IconButton
              badge={badge}
              label={label}
              delayShowTooltip={300}
              key={(label || '') + index}
              onClick={onClick}
              Icon={Icon}
              className={className}
            />
          )
        })}
        {datePicker}
      </div>
      {buttons && buttons.length > 2 && (
        <div className='flex space-x-3 md:hidden'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='ghost' className='w-10 h-10 p-0'>
                <HiDotsVertical className='w-4 h-4' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='mx-4 max-w-fit'>
              {buttons.map(({ label, onClick, Icon }) => {
                return (
                  <DropdownMenuItem
                    key={label}
                    onClick={onClick}
                    className='flex items-center space-x-2'
                  >
                    <Icon className='w-4 h-4 text-text-tertiary' />
                    <span>{label}</span>
                  </DropdownMenuItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu>
          {datePicker}
        </div>
      )}
      {!childrenAsTitle && children}
    </div>
  )
}

export default PageHeader
