import clsx from 'clsx'
import { AnimateSharedLayout, motion } from 'framer-motion'
import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

import useTheme from '@/hooks/useTheme'

import { ButtonGroupI } from '.'

type AnimatedButtonGroupI = Pick<
  ButtonGroupI,
  'buttons' | 'name' | 'onClick' | 'selected' | 'iconsOnly'
>

const AnimatedButtonGroup = ({
  buttons,
  name,
  onClick,
  selected,
  iconsOnly,
}: AnimatedButtonGroupI): JSX.Element => {
  const [isSelected, setSelected] = React.useState(
    selected ?? buttons[0].selected,
  )

  const { theme } = useTheme()!

  function onChangeRadio(e: any) {
    setSelected(e.target.value)
    if (onClick) {
      onClick(e.target.value)
    } else {
      buttons.find((b) => b.value === e.target.value)?.onClick(e.target.value)
    }
  }
  const { getArrowProps, getTooltipProps, setTooltipRef, visible } =
    usePopperTooltip({ placement: 'auto' })

  return (
    <div
      className='relative flex items-center justify-around w-full h-10 col-span-4 px-1 border rounded-md md:w-auto border-border-dimmed/80 bg-neutral'
      onChange={(e) => onChangeRadio(e)}
    >
      <AnimateSharedLayout>
        {buttons.map(({ Icon, ...button }) => (
          <React.Fragment key={button.value}>
            <input
              className='absolute w-px h-px overflow-hidden border-none'
              style={{ clip: 'rect(0, 0, 0, 0)' }}
              type='radio'
              id={button.value}
              value={button.value}
              name={name}
              defaultChecked={button.value === isSelected}
            />

            <motion.label
              className={clsx(
                iconsOnly ? 'px-1' : 'px-4',
                isSelected === button.value
                  ? 'text-text-primary'
                  : 'text-text-tertiary/90',
                'relative z-10 inline-flex items-center justify-center w-full h-full my-0 text-sm font-medium text-center transition-all duration-75 ease-in-out rounded-sm cursor-pointer',
              )}
              style={{ userSelect: 'none' }}
              htmlFor={button.value}
              key={button.value}
              initial={false}
              animate={{
                visibility: 'visible',
              }}
            >
              {isSelected === button.value && (
                <motion.div
                  className={clsx(
                    iconsOnly
                      ? 'absolute rounded-md bg-neutral-dimmed-heavy top-1 left-px right-px bottom-1 -z-10'
                      : 'absolute rounded-md bg-neutral-dimmed-heavy top-1 left-px right-px bottom-1 -z-10',
                  )}
                  layoutId='radioBackground'
                  animate={{
                    backgroundColor: theme.colors['neutral-dimmed-heavy'],
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                  }}
                />
              )}
              {Icon && (
                <Icon
                  className={clsx(iconsOnly ? 'w-5 h-5 mx-1' : 'w-5 h-5 mr-1')}
                  aria-hidden='true'
                />
              )}
              {iconsOnly ? (
                <span className='sr-only'>{button.label}</span>
              ) : (
                <p className='text-sm font-medium'>{button.label}</p>
              )}
            </motion.label>
            {visible && iconsOnly && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: 'tooltip-container-themed',
                })}
              >
                <p className='text-sm font-medium text-text-secondary'>
                  {button.label}
                </p>
                <div {...getArrowProps({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </React.Fragment>
        ))}
      </AnimateSharedLayout>
    </div>
  )
}

export default AnimatedButtonGroup
