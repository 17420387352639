/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const skyEuropeAndPeacockLight = require('./sky-europe-and-peacock/light')
const skyEuropeAndPeacockDark = require('./sky-europe-and-peacock/dark')
const skyEuropeLight = require('./sky-europe/light')
const skyEuropeDark = require('./sky-europe/dark')
const peacockLight = require('./peacock/light')
const peacockDark = require('./peacock/dark')
const skyshowtimeLight = require('./skyshowtime/light')
const skyshowtimeDark = require('./skyshowtime/dark')
const nowLight = require('./now/light')
const nowDark = require('./now/dark')
const nowOTTLight = require('./nowott/light')
const nowOTTDark = require('./nowott/dark')
const nbcuDtcLight = require('./nbcu-dtc/light')
const nbcuDtcDark = require('./nbcu-dtc/dark')
const comcastLight = require('./comcast/light')
const comcastDark = require('./comcast/dark')
const showmaxLight = require('./showmax/light')
const showmaxDark = require('./showmax/dark')

// IMPORTANT: At least one theme must have the name 'base' to be used as a default
// See https://github.com/crswll/tailwindcss-theme-swapper for more information
// Themes use the same configuration as tailwind.config.js theming

const themes = [
  peacockLight,
  peacockDark,
  skyEuropeAndPeacockLight,
  skyEuropeAndPeacockDark,
  skyEuropeLight,
  skyEuropeDark,
  skyshowtimeLight,
  skyshowtimeDark,
  nowLight,
  nowDark,
  nowOTTLight,
  nowOTTDark,
  nbcuDtcLight,
  nbcuDtcDark,
  comcastLight,
  comcastDark,
  showmaxLight,
  showmaxDark,
]

module.exports = themes
