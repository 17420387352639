import React from 'react'
import { HiOutlineTrash } from 'react-icons/hi'

import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { logEvent } from '@/utils/firebaseAnalytics'

import { FiltersContext } from '../Filters'

export default function FiltersSelectionRemoveFiltersButton() {
  const { activeFilters, deleteFilters } = React.useContext(FiltersContext)

  function handleRemoveFilters() {
    logEvent('remove_filters')

    deleteFilters()
  }

  return (
    <DropdownMenuItem
      onSelect={handleRemoveFilters}
      disabled={!activeFilters || Object.keys(activeFilters).length === 0}
      className='text-red-600'
    >
      <HiOutlineTrash className='w-4 h-4 mr-2' />
      <span className='mr-2'>Remove Filters</span>
    </DropdownMenuItem>
  )
}
