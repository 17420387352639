import { MetricSettingsI } from '@/config/types'

const fallback = null

const convivaMultipleMetricsAggregationTotal = ({
  metrics,
  data,
}: {
  metrics: MetricSettingsI[]
  data: ConvivaAggregationResponseI
}): {
  [metricName: string]: any
}[] => {
  if (data?.data?.total) {
    const values = metrics.map(({ dataKey }) => {
      const valueExists =
        data?.data?.total?.[0]?.[dataKey] ||
        data?.data?.total?.[0]?.[dataKey] === 0
      return {
        [dataKey]: valueExists ? data?.data?.total?.[0]?.[dataKey] : fallback,
      }
    })

    return values
  } else {
    return fallback
  }
}

export default convivaMultipleMetricsAggregationTotal
