import ReactImageFallback from 'react-image-fallback'

import { useClientStore } from '@/stores/client.store'

interface ImageFallbackProps {
  src: string
  alt: string
  className?: string
  transparentFallback?: boolean
  onError?: () => void
}

function ImageFallback({
  src,
  alt,
  className,
  transparentFallback = false,
  onError,
}: ImageFallbackProps) {
  const client = useClientStore((state) => state.client)
  const placeholderImage = `/images/${client}/image-placeholder.png`
  const transparentFallbackImage = '/images/transparentFallback.png'

  const fallbackImage = transparentFallback
    ? transparentFallbackImage
    : placeholderImage

  return (
    <ReactImageFallback
      src={src}
      fallbackImage={fallbackImage}
      initialImage={fallbackImage}
      alt={alt}
      className={className}
      onError={() => {
        onError && onError()
      }}
    />
  )
}

export default ImageFallback
