import { FeatureFlagsT } from '@/config/types'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import { useClientStore } from '@/stores/client.store'
import { useUiStore } from '@/stores/ui.store'

import { ClientT } from '../types'
import nbcuDtcNavigation from './nbcu-dtc'
import nowNavigation from './now'
import nowOTTNavigation from './nowott'
import peacockNavigation from './peacock'
import showmaxNavigation from './showmax'
import skyEuropeNavigation from './sky-europe'
import skyEuropeAndPeacockNavigation from './sky-europe-and-peacock'
import skyshowtimeNavigation from './skyshowtime'

export interface NavigationPropsI {
  client: ClientT
  adminMode: boolean
  featureFlags: FeatureFlagsT
}

export const navigation = () => {
  const client = useClientStore((state) => state.client)
  const adminMode = useUiStore((state) => state.adminMode)
  const { featureFlags } = useFeatureFlags()

  const props = { client, adminMode, featureFlags }

  switch (client) {
    case 'peacock':
      return peacockNavigation(props)
    case 'skyshowtime':
      return skyshowtimeNavigation(props)
    case 'now':
      return nowNavigation(props)
    case 'nowott':
      return nowOTTNavigation(props)
    case 'sky-europe-and-peacock':
      return skyEuropeAndPeacockNavigation(props)
    case 'nbcu-dtc':
      return nbcuDtcNavigation(props)
    case 'sky-europe':
      return skyEuropeNavigation(props)
    case 'showmax':
      return showmaxNavigation(props)
    default:
      return []
  }
}

export default navigation
