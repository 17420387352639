import moment from 'moment'
import { LuLoader2 } from 'react-icons/lu'

import DevUrl from '@/components/DevUrl'
import Status from '@/components/Status'
import useBusiestDaysData from '@/hooks/useBusiestDaysData'
import useSettings from '@/hooks/useSettings'
import { useUiStore } from '@/stores/ui.store'

import formatMomentDateToUTC from '../utils/formatMomentDateToUTC'
import DatePickerBusiestDayItem from './DatePickerBusiestDayItem'

import { logEvent } from '@/utils/firebaseAnalytics'

function DatePickerBusiestDaysLoading() {
  return (
    <div className='flex flex-col items-center justify-center py-12 text-sm text-text-tertiary'>
      <LuLoader2 className='w-5 h-5 animate-spin' />
      <span className='mt-2'>Loading...</span>
    </div>
  )
}

interface DatePickerBusiestDaysProps {
  config: DateConfigI
  onUpdateConfig: (config: DateConfigI) => void
}

export default function DatePickerBusiestDays({
  config,
  onUpdateConfig,
}: DatePickerBusiestDaysProps) {
  const { clientSettings } = useSettings()
  const busiestDaysSettings = clientSettings.metricsExplorer.busiestDays
  const { metric, comingSoon, includeTopEvents } = busiestDaysSettings

  const { busiestDaysQuery, busiestDaysUrl } = useBusiestDaysData({
    config: { metric },
  })

  const developerMode = useUiStore((state) => state.developerMode)

  const handleBusiestDayItemSelect = (timestamp: number) => {
    const momentDate = moment(timestamp)
    const momentStart = momentDate.clone().startOf('day')
    const momentEnd = momentDate.clone().add(1, 'day')

    const start = formatMomentDateToUTC(momentStart)
    const end = formatMomentDateToUTC(momentEnd)

    const nextConfig: ConfigI = {
      ...config,
      dateType: 'datetime',
      start,
      end,
    }

    logEvent('select_busiest_day', { start, end })

    return onUpdateConfig(nextConfig)
  }

  return (
    <div className='px-4 pb-4 space-y-2'>
      <div className='py-4 mt-1 border-b border-border-main'>
        <h3 className='text-sm font-medium'>Records</h3>
      </div>

      {developerMode && <DevUrl url={busiestDaysUrl} />}

      <div className='flex flex-col divide-y divide-divider-main'>
        <Status
          {...busiestDaysQuery}
          LoadingComponent={DatePickerBusiestDaysLoading}
          isEmpty={busiestDaysQuery?.data?.length === 0}
          comingSoon={comingSoon}
          comingSoonMessage='Coming soon'
          comingSoonSubMessage="We're still collecting the records data for this context"
          statusWrapperProps={{ className: 'mt-8' }}
          small
        >
          {busiestDaysQuery?.data?.map((busiestDay) => {
            const isSelected = moment(busiestDay?.timestamp).isSame(
              config?.start,
            )

            return (
              <DatePickerBusiestDayItem
                isSelected={isSelected}
                key={busiestDay?.timestamp?.toString()}
                onSelect={handleBusiestDayItemSelect}
                ranking={busiestDay?.rank}
                metric={metric}
                includeTopEvents={includeTopEvents}
                {...busiestDay}
              />
            )
          })}
        </Status>
      </div>
    </div>
  )
}
