import { SettingsI } from '@/config/types'

export function getFiltersFromConfig(config: ConfigI, settings: SettingsI) {
  const filters = {}
  settings.form.forEach((formGroup) => {
    const value = config[formGroup.value]
    if (formGroup.type === 'hidden') {
      return
    }
    if (Array.isArray(value)) {
      filters[formGroup.value] = value.filter((v) => v !== 'all')
    } else if (value) {
      filters[formGroup.value] = value
    }
  })
  return filters
}
