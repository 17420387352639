import 'react-loading-skeleton/dist/skeleton.css'

import moment from 'moment-timezone'
import { useLayoutEffect } from 'react'
import { useKonami } from 'react-konami-code'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate, useRoutes } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { PaletteMode } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'

import { CustomNavigationClient } from './NavigationClient'
import Event from './components/Event'
import Authentication from './hocs/Authentication'
import Clients from './hocs/Clients'
import FirebaseAnalytics from './hocs/FirebaseAnalytics'
import GetAccessToken from './hocs/GetAccessToken'
import GetDynamicConfig from './hocs/GetDynamicConfig'
import ModalProvider from './hocs/ModalProvider'
import useFeatureFlags from './hooks/useFeatureFlags'
import useSettings from './hooks/useSettings'
import useTheme from './hooks/useTheme'
import { useClientStore } from './stores/client.store'
import { useTimeStore } from './stores/time.store'
import { useUiStore } from './stores/ui.store'

function App() {
  const { routes } = useSettings()

  return useRoutes(routes)
}

interface AppWrapperI {
  pca: IPublicClientApplication
}

const AppWrapper = ({ pca }: AppWrapperI): JSX.Element => {
  const appTheme = useTheme()

  const adminMode = useUiStore((state) => state.adminMode)
  const xmasMode = useUiStore((state) => state.xmasMode)
  const setXmasMode = useUiStore((state) => state.setXmasMode)
  const setAdminMode = useUiStore((state) => state.setAdminMode)
  const setDeveloperMode = useUiStore((state) => state.setDeveloperMode)
  const developerMode = useUiStore((state) => state.developerMode)

  const timezone = useTimeStore((state) => state.timezone)
  const setTimezone = useTimeStore((state) => state.setTimezone)
  const clientDefaultTimezone = useClientStore(
    (state) => state.clientDefaultTimezone,
  )

  useLayoutEffect(() => {
    if (xmasMode) {
      setXmasMode(false)
    }
  }, [])

  const { appSettings } = useSettings()
  const client = useClientStore((state) => state.client)
  const setClient = useClientStore((state) => state.setClient)

  useLayoutEffect(() => {
    if (!appSettings.clients.includes(client)) {
      setClient(appSettings.defaultClient)
    }
  }, [client])

  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  useFeatureFlags()

  // Admin mode for the Event Reporter
  useKonami(
    () => {
      setAdminMode(adminMode ? false : true)
    },
    { code: [191, 191, 83, 69, 67, 85, 82, 69, 191, 191] },
  )

  // Developre Mode to view API calls where applicable
  useKonami(
    () => {
      setDeveloperMode(developerMode ? false : true)
    },
    { code: [68, 69, 86] },
  )

  // Switch Dashboard view between local timezone and client default tz
  useKonami(
    () => {
      setTimezone(
        timezone === clientDefaultTimezone
          ? moment.tz.guess()
          : clientDefaultTimezone,
      )
    },
    { code: [87, 65, 82, 80] },
  )
  // Switch Dashboard view to UTC
  useKonami(
    () => {
      setTimezone('UTC')
    },
    { code: [85, 84, 67] },
  )

  const muiTheme = createTheme({
    ...(appTheme?.muiThemeBase || {}),
    palette: {
      ...(appTheme?.muiThemeBase.palette || {}),
      mode: (appTheme?.muiThemeBase.palette.type as PaletteMode) || 'light',
    },
  })

  return (
    <MsalProvider instance={pca}>
      <Authentication>
        <GetAccessToken>
          <Clients>
            <GetDynamicConfig>
              <FirebaseAnalytics>
                <MuiThemeProvider theme={muiTheme}>
                  <ModalProvider />
                  <SkeletonTheme
                    baseColor={appTheme?.theme.colors['divider-main']}
                    highlightColor={appTheme?.theme.colors['neutral']}
                  >
                    <ToastProvider autoDismiss autoDismissTimeout={6000}>
                      <Event />
                      <App />
                    </ToastProvider>
                  </SkeletonTheme>
                </MuiThemeProvider>
              </FirebaseAnalytics>
            </GetDynamicConfig>
          </Clients>
        </GetAccessToken>
      </Authentication>
    </MsalProvider>
  )
}

export default AppWrapper
