import queryString from 'query-string'

import {
  CLIENT_DEFAULT_TIMEZONES,
  CONVIVA_2_V4_API_DOMAIN,
} from '@/config/constants'
import { ClientT } from '@/config/types'

const busiestDaysEndpoint = '/busiestday'
const NUMBER_OF_DAYS_DISPLAYED = 10

const getBusiestDaysUrl = (
  client: ClientT,
  includeTopEvents: boolean,
): string => {
  const queryStringParameters = queryString.stringify({
    client,
    timezone: CLIENT_DEFAULT_TIMEZONES[client],
    'rank-limit': NUMBER_OF_DAYS_DISPLAYED,
    'include-events': includeTopEvents,
  })

  return `${CONVIVA_2_V4_API_DOMAIN}${busiestDaysEndpoint}?${queryStringParameters}`
}

export default getBusiestDaysUrl
