import 'react-popper-tooltip/dist/styles.css'

import moment from 'moment'
import React from 'react'
import {
  HiOutlineInformationCircle,
  HiOutlineRefresh,
  HiOutlineShare,
} from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router-dom'

import XAxis from '@/components/Chart/XAxis'
import DevUrl from '@/components/DevUrl'
import DatePickerSwitch from '@/components/FilterPanel/components/DatePickerSwitch'
import InfoModalContent from '@/components/InfoModalContent'
import LastUpdatedTime from '@/components/LastUpdatedTime'
import PageHeader from '@/components/PageHeader'
import PageLoading from '@/components/PageLoading'
import ShareModalContent from '@/components/ShareModalContent'
import Status from '@/components/Status'
import { FEATURE_FLAGS } from '@/config/constants'
import relativeDates from '@/config/relativeDates'
import useCurrentMinute from '@/hooks/useCurrentMinute'
import useEventsSettings from '@/hooks/useEventsSettings'
import useFeatureFlags from '@/hooks/useFeatureFlags'
import useOlympicEvents from '@/hooks/useOlympicEvents'
import PageNotFound from '@/pages/PageNotFound'
import { useClientStore } from '@/stores/client.store'
import { useModalStore } from '@/stores/modal.store'
import { useUiStore } from '@/stores/ui.store'
import cleanseConfig from '@/utils/cleanseConfig'
import createUrlFromConfig from '@/utils/createUrlFromConfig'
import { logEvent } from '@/utils/firebaseAnalytics'
import getConfigFromUrl from '@/utils/getConfigFromUrl'
import getDateRangeFromConfig from '@/utils/getDateRangeFromConfig'
import getEventsConfigDateRange from '@/utils/getEventsConfigDateRange'
import getEventsConfigMetrics from '@/utils/getEventsConfigMetrics'
import getUniqueEventId from '@/utils/getUniqueEventId'

import { DiffBar } from './DiffBar'
import { EventItem } from './EventItem'

// ** Plays metrics test: Adding `plays` to the quality events query is as a means to check wether old linear or sle events should display quality metrics.
// Instead of null, the %metrics(VPF VSF) might come as 0 and display 0 values, but if Plays is null, we know not to display anything

export const QUALITY_METRICS = [
  'connection-induced-rebuffering-ratio',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
]

const OlympicRings = () => {
  return (
    <div className='flex items-center min-w-0 space-x-4'>
      <img
        alt='Olympic rings'
        className='z-0 w-auto h-12 pointer-events-none '
        src='/images/olympic_rings.svg'
      />
      <h2 className='text-xl font-bold leading-7 text-text-primary sm:text-2xl sm:truncate'>
        Paris Olympics 2024
      </h2>
    </div>
  )
}
let timeout: any
const Olympics = () => {
  const client = useClientStore((state) => state.client)
  const scrollParentRef = React.useRef(null)
  const navigate = useNavigate()

  const openModal = useModalStore((state) => state.openModal)
  const developerMode = useUiStore((state) => state.developerMode)
  const initialSettings = useEventsSettings({
    variants: {
      withClient: true,
    },
  })

  const searchParamsConfig = getConfigFromUrl({
    metrics: [initialSettings],
    isEvents: true,
  })

  const [config, setConfig] = React.useState<ConfigI>(() => {
    const conviva2SupportedConfig = getEventsConfigDateRange({
      config: { ...initialSettings.defaultConfig, ...searchParamsConfig },
      settings: initialSettings,
    })
    return getEventsConfigMetrics({
      settings: initialSettings,
      config: {
        ...conviva2SupportedConfig,
        'stream-type': 'sle',
        'page-size': 500,
        metric: [...QUALITY_METRICS, 'concurrent-plays'], // see  ** explanation at the top of the file
      },
    })
  })

  const settings = initialSettings

  const [diffBarHovered, setDiffBarHovered] = React.useState<{
    enabled: boolean
    start: any
    end: any
  }>()
  const [silentRefresh, setSilentRefresh] = React.useState(false)

  const currentMinute = useCurrentMinute()

  const onUpdateConfig = (updatedConfig: ConfigI, isSilent = false) => {
    const nextConfig = { ...config, ...updatedConfig }
    if (!isSilent) {
      events.remove()
    }
    const { start, end } = getDateRangeFromConfig(nextConfig)
    setSilentRefresh(isSilent)
    setConfig({ ...nextConfig, start, end })
  }

  const cleansedConfig = React.useMemo(() => cleanseConfig(config), [config])
  const location = useLocation()

  const { events, url } = useOlympicEvents({
    key: 'events',
    subGenreFilter: 'Olympics',
    config: cleansedConfig,
    reactQueryProps: {
      refetchInterval: false,
    },
    variants: {
      withClient: true,
    },
  })

  React.useEffect(() => {
    navigate(location.pathname)
  }, [navigate, location.pathname])

  React.useEffect(() => {
    if (events.data?.data.length && events.data?.data.length <= 1) {
      const { start, end } = getDateRangeFromConfig(config)
      if (start !== config?.start || end !== config?.end) {
        onUpdateConfig({ start, end }, true)
      }
    }
  }, [currentMinute])

  React.useEffect(() => {
    setSilentRefresh(false)
  }, [location])

  const onUpdateLocalConfig = (updatedConfig: ConfigI, delay = 0) => {
    const nextConfig = { ...config, ...updatedConfig }
    setConfig(nextConfig)

    clearTimeout(timeout)
    timeout = null
    timeout = setTimeout(() => {
      onUpdateConfig(nextConfig)
    }, delay)
  }

  return (
    <main className='flex flex-1 overflow-hidden'>
      <div className='flex flex-col flex-1'>
        <div className='relative px-6 py-6 lg:py-8 lg:px-8'>
          <PageHeader
            title={``}
            childrenAsTitle
            datePicker={
              <DatePickerSwitch
                relative={true}
                config={config}
                dates={settings?.dates || relativeDates[client]}
                onUpdateLocalConfig={onUpdateLocalConfig}
                showCustomDateRangeDisclaimer={false}
                listBoxClassName='min-w-[175px] right-0 z-50'
                buttonClassName='relative px-4 pr-8 relative inline-flex items-center py-2 text-sm font-medium text-text-secondary bg-neutral-dimmed-heavy border border-border-main rounded-md shadow-sm hover:bg-neutral-dimmed focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-500 ring-offset-neutral'
              />
            }
            buttons={[
              {
                label: 'Refresh',
                Icon: HiOutlineRefresh,
                onClick: () => {
                  setSilentRefresh(false)
                  events.remove()
                  events.refetch()
                  logEvent('select_refresh')
                },
                labelClassName: 'hidden md:inline-block',
              },
              {
                label: 'Info',
                Icon: HiOutlineInformationCircle,
                onClick: () => {
                  openModal({
                    title: `${settings?.label} Information`,
                    description: (
                      <InfoModalContent
                        settings={{
                          ...settings,
                          info: 'This page shows a table ranking of the most watched olympic events during a given interval.',
                        }}
                        config={config}
                        isEvent
                      />
                    ),
                    appearance: 'info',
                  })
                  logEvent('select_info')
                },
                labelClassName: 'hidden md:inline-block',
              },
              {
                label: 'Share',
                Icon: HiOutlineShare,
                onClick: () => {
                  const shareUrl = createUrlFromConfig(config, settings)

                  openModal({
                    title: 'Share',
                    description: <ShareModalContent url={shareUrl} />,
                    appearance: 'info',
                  })
                  logEvent('select_share')
                },
                labelClassName: 'hidden md:inline-block',
              },
            ]}
          >
            <OlympicRings />
          </PageHeader>
        </div>

        <section className='flex flex-col flex-1 overflow-hidden border-t border-divider-main'>
          {developerMode && <DevUrl url={url} />}

          <Status
            {...events}
            isEmpty={events.data?.data.length === 0}
            silentRefresh={silentRefresh}
            loadingMessage={`We're aggregating through millions of events, so this can take a while...`}
            comingSoon={initialSettings?.comingSoon}
            comingSoonMessage='Events data is coming soon'
            comingSoonSubMessage="We're in the process of integrating Merlin data to provide this feature"
          >
            <div className='flex justify-center flex-1 overflow-hidden'>
              <div
                className='flex-1 w-full overflow-y-auto xl:px-12 bg-neutral-dimmed'
                ref={scrollParentRef}
              >
                <div className='mx-auto max-w-7xl'>
                  <div className='sticky top-0 z-20 px-1 py-3 space-y-3 text-sm font-medium border-b text-text-tertiary border-divider-main bg-neutral-dimmed'>
                    <div className='hidden sm:block'>
                      <XAxis
                        range={[
                          Number(moment(config.start).unix()) * 1000,
                          Number(moment(config.end).unix()) * 1000,
                        ]}
                        interval={16}
                      />
                      <div className='absolute left-0 z-10 w-full px-1 top-1'>
                        <DiffBar
                          start={config.start}
                          end={config.end}
                          itemStart={diffBarHovered?.start}
                          itemEnd={diffBarHovered?.end}
                          enabled={diffBarHovered?.enabled}
                          animate
                          bgClassName='bg-neutral-dimmed'
                        />
                      </div>
                    </div>

                    <div className='relative grid grid-cols-12 gap-4 pb-3 sm:px-4'>
                      <div className='col-span-3 2xl:col-span-2'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Rank
                        </p>
                      </div>
                      <div className='col-span-3'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Event
                        </p>
                      </div>
                      <div className='col-span-6 2xl:col-span-7'>
                        <p className='text-xs font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                          Metrics
                        </p>
                      </div>
                      <LastUpdatedTime
                        isRefreshing={
                          events.isLoading ||
                          events.isFetching ||
                          events.isRefetching
                        }
                        timestamp={events.dataUpdatedAt}
                        containerClasses='absolute right-3 sm:right-4 -bottom-1.5  text-text-tertiary opacity-80'
                      />
                    </div>
                  </div>

                  <ol className='py-4 pt-24 -mt-24 overflow-hidden divide-y shadow divide-divider-main rounded-xl bg-neutral-dimmed-heavy'>
                    {events.data?.data?.map((event, index) => {
                      const id = getUniqueEventId(event)
                      return (
                        <EventItem
                          key={id}
                          id={id}
                          event={event}
                          index={index}
                          config={config}
                          settings={settings}
                          setDiffBarHovered={setDiffBarHovered}
                        />
                      )
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </Status>
        </section>
      </div>
    </main>
  )
}

const OlympicsPageWrap = () => {
  const { loading: ffLoading, getFeatureFlag } = useFeatureFlags()
  const olympicsPageEnabled = getFeatureFlag(
    FEATURE_FLAGS.FF_OLYMPICS_EVENT_PAGE,
  )

  // Block rendering until feature flags are loaded
  // Otherwise feature flag: false will be rendered for a short time
  if (ffLoading) return <PageLoading />

  if (olympicsPageEnabled) {
    return <Olympics />
  }
  return <PageNotFound />
}

export default OlympicsPageWrap
