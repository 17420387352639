import { HiOutlineExclamation } from 'react-icons/hi'

import DatePicker from '@/components/DatePicker'
import { useThemeStore } from '@/stores/theme.store'
import { cn } from '@/utils/cn'
import { logEvent } from '@/utils/firebaseAnalytics'

type DateType = 'start' | 'end'

interface CustomDatePickerProps {
  type: DateType
  label: string
  date: string | null
  onCustomRangeSelect: (type: DateType, config: ConfigI) => void
}

function CustomDatePicker({
  type,
  label,
  date,
  onCustomRangeSelect,
}: CustomDatePickerProps) {
  return (
    <div>
      <h3 className='mb-2 text-xs uppercase text-text-dimmed'>{label}</h3>
      <DatePicker
        selected={{
          start: date || new Date(),
          end: date || new Date(),
          dateType: 'datetime',
          isRange: false,
        }}
        onChange={(config: ConfigI) => {
          onCustomRangeSelect(type, config)
        }}
      />
    </div>
  )
}

interface DatePickerCustomRangeProps {
  config: ConfigI
  onUpdateConfig: (config: ConfigI) => void
}

export default function DatePickerCustomRange({
  config,
  onUpdateConfig,
}: DatePickerCustomRangeProps) {
  const { theme } = useThemeStore()
  const handleCustomRangeSelect = (type: DateType, customDate: ConfigI) => {
    if (!customDate || !('start' in customDate)) return

    // VOD won't have start and end so we should get some sensible start and end params as a starting point

    const nextConfig: ConfigI = {
      dateType: 'datetime',
    }

    if (config['stream-type'].includes('vod')) {
      if (config?.relativeDate?.getStartEndParams) {
        const dateParams = config?.relativeDate?.getStartEndParams()

        if (dateParams && 'start' in dateParams && !config?.start) {
          nextConfig.start = dateParams?.start.clone().utc().format()
        }
        if (dateParams && 'end' in dateParams && !config?.end) {
          nextConfig.end = dateParams?.end.clone().utc().format()
        }
      }
    }

    logEvent('select_date', { dateType: 'custom', ...customDate })

    // Update the start or end date based on the type
    nextConfig[type] = customDate.start

    return onUpdateConfig(nextConfig)
  }
  const showCustomDateRangeDisclaimer = config['stream-type'].includes('vod')

  return (
    <div className='flex flex-col space-y-4'>
      <CustomDatePicker
        label='Start'
        type='start'
        date={config?.start || null}
        onCustomRangeSelect={handleCustomRangeSelect}
      />

      <CustomDatePicker
        label='End'
        type='end'
        date={config?.end || null}
        onCustomRangeSelect={handleCustomRangeSelect}
      />
      {showCustomDateRangeDisclaimer && (
        <div
          className={cn(
            'flex items-center px-2.5 py-3 mt-2 text-xs font-regular border-l-2 border-amber-500 bg-amber-500/10',
            {
              'text-amber-700': theme === 'light',
              'text-amber-400': theme === 'dark',
            },
          )}
        >
          <HiOutlineExclamation className='flex-shrink-0 w-4 h-4 mr-2.5' />
          Custom date ranges can be slow, especially over long windows
        </div>
      )}
    </div>
  )
}
