import { startCase } from 'lodash'

import { FormOptionI } from '@/config/types'

export function parseFilterSelectOptions(options: FormOptionI[]) {
  return options
    .map((option) => ({
      value: option[0],
      label: option[1]?.label || startCase(option[0]),
      config: option[1],
    }))
    .filter((option) => option.value !== 'all')
}
