import { BusiestDayTopEventI } from '@/config/types'

import DatePickerBusiesDayTopEventsItem from './DatePickerBusiestDayTopEventsItem'

interface DatePickerBusiesDayTopEventsProps {
  includeTopEvents: boolean
  topEvents: BusiestDayTopEventI[]
  metric: 'concurrent-plays' | 'plays'
}

const DatePickerBusiestDayTopEvents = ({
  includeTopEvents,
  topEvents,
  metric,
}: DatePickerBusiesDayTopEventsProps) => {
  if (!includeTopEvents) return null
  if (topEvents.length === 0) return null

  return (
    <div className='mt-2 w-48 pl-2 border-l-4 border-divider-main'>
      {topEvents?.map((topEvent) => (
        <DatePickerBusiesDayTopEventsItem
          key={topEvent.id}
          metric={metric}
          {...topEvent}
        />
      ))}
    </div>
  )
}

export default DatePickerBusiestDayTopEvents
