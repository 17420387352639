import { MinutesUniquesSettingsI } from '@/config/types'

import {
  PROPOSITION,
  SPLIT_BY,
  TERRITORY,
  nowOTTTerritoryOptions,
} from '../formOptions'
import commonBigvivaConfig from './common/bigvivaConfig'

const minutesAndUniquesSettings: MinutesUniquesSettingsI = {
  ...commonBigvivaConfig,
  form: [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [SPLIT_BY['territory']],
    },
    {
      value: 'proposition',
      type: 'hidden',
      multi: true,
      options: [PROPOSITION['all'], PROPOSITION['nowott']],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [TERRITORY['all'], ...nowOTTTerritoryOptions],
    },
  ],
}

export default Object.freeze(minutesAndUniquesSettings)
