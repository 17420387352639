import { usePopperTooltip } from 'react-popper-tooltip'

import { cn } from '@/utils/cn'

const Chip = ({
  label,
  image,
  collapseLabel = false,
  className,
  labelClassName,
  theme,
  variant,
}: any) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'top', delayShow: 500 })

  return (
    <span
      ref={setTriggerRef}
      className={cn(
        `inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium mr-1 flex-shrink-0 overflow-hidden`,
        {
          'bg-neutral-shadow text-text-secondary': !theme || theme === 'light',
          'bg-elements-primary-400 text-elements-primary-contrastText':
            theme === 'dark',
        },
        {
          'bg-transparent rounded w-8 h-8 p-0': variant === 'badge',
        },
        className,
      )}
    >
      {variant !== 'tag' && image && (
        <img
          className={cn('w-4 h-4 rounded-sm', {
            'w-full h-full content-center object-fill': variant === 'badge',
          })}
          src={image}
          alt={label}
        />
      )}
      {variant !== 'badge' && (
        <span
          className={cn(
            {
              'hidden md:inline-block ml-2': collapseLabel,
              'ml-2': !collapseLabel && image,
              labelClassName,
            },
            ' whitespace-nowrap',
          )}
        >
          {label}
        </span>
      )}
      {variant === 'badge' && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container-themed bg-opacity-80 backdrop-blur',
          })}
        >
          <p className='text-sm font-medium text-text-secondary'>{label}</p>

          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </span>
  )
}

export default Chip
