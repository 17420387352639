import clsx from 'clsx'
import { startCase } from 'lodash'
import { Check } from 'lucide-react'
import { useContext } from 'react'

import { CommandItem } from '@/components/ui/command'
import { FormOptionConfigI } from '@/config/types'
import { cn } from '@/utils/cn'

import { FiltersContext } from '../Filters'

interface PropsI {
  value: string
  formGroup: keyof FiltersI
  config?: FormOptionConfigI
  isMulti?: boolean
  style?: any
}

export default function FiltersSelectionDropdownItem({
  formGroup,
  value,
  config,
  isMulti,
  style,
}: PropsI) {
  const Icon = config?.icon
  const { updateFilters, activeFilters } = useContext(FiltersContext)

  let checked = false

  if (
    (typeof value === 'number' || typeof value === 'string') &&
    activeFilters?.[formGroup] === value
  ) {
    checked = true
  } else if (
    Array.isArray(activeFilters?.[formGroup]) &&
    (activeFilters?.[formGroup] as string[])?.includes(value)
  ) {
    checked = true
  }

  return (
    <CommandItem
      style={style}
      onSelect={() => {
        updateFilters({
          formGroup,
          value,
          checked: !checked,
          isMulti: !!isMulti,
        })
      }}
      key={value}
    >
      <Check
        className={cn(
          'mr-2 h-4 w-4 flex-shrink-0',
          checked ? 'opacity-100' : 'opacity-0',
        )}
      />
      {Icon && (
        <Icon
          className={clsx(
            'w-5 h-5 mr-2 group-hover:text-text-tertiary flex-shrink-0',
            {
              'text-text-dimmed': !checked,
              'text-text-tertiary': checked,
            },
          )}
        />
      )}
      {config?.image && (
        <img
          src={config.image}
          alt={value}
          className='max-h-5 w-5 object-contain ml-0.5 mr-2 rounded-sm flex-shrink-0'
        />
      )}
      <span className='whitespace-nowrap overflow-ellipsis'>
        {config?.label || startCase(value)}
      </span>
    </CommandItem>
  )
}
