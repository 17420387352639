import clsx from 'clsx'
import { startCase, uniq } from 'lodash'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { HiCheck, HiSelector } from 'react-icons/hi'

import { cn } from '@/utils/cn'
import { Listbox, Transition } from '@headlessui/react'

const Option = ({ option }) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        clsx(
          active
            ? 'text-elements-primary-contrastText bg-elements-primary-shadow'
            : 'text-text-primary',
          'cursor-pointer select-none relative py-2 pl-8 pr-4',
        )
      }
      value={option}
    >
      {({ selected, active }) => (
        <>
          <span
            className={clsx(
              selected ? 'font-semibold' : 'font-normal',
              'block truncate',
            )}
          >
            {option.name}
          </span>

          {selected ? (
            <span
              className={clsx(
                active ? 'text-white' : 'text-primary-600',
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
              )}
            >
              <HiCheck className='w-5 h-5' aria-hidden='true' />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  )
}

const Options = ({ options = [], groups = [] }) => {
  if (groups.length > 0) {
    return groups.map((group, index) => (
      <div key={group}>
        <p
          label={startCase(group)}
          className={clsx(
            'pl-3 pb-1 text-xs uppercase tracking-wider text-text-tertiary font-semibold cursor-default',
            {
              'mt-4': index > 0,
              'mt-2': index === 0,
            },
          )}
        >
          {startCase(group)}
        </p>
        {options
          ?.filter((option) => option.group === group)
          ?.map((option) => (
            <Option key={option.id} option={option} />
          ))}
      </div>
    ))
  } else {
    return options.map((option) => <Option key={option.id} option={option} />)
  }
}

export default function ListBoxComponent({
  options,
  value,
  onChange,
  label,
  labelHidden,
  buttonClassName = '',
  listBoxClassName = '',
}) {
  const groups = uniq(
    options?.filter(({ group }) => !!group)?.map(({ group }) => group),
  )

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className='relative'>
            <Listbox.Button
              className={cn(
                'border shadow-sm border-divider-shadow bg-neutral-dimmed-heavy relative w-full py-2 pl-3 pr-10 text-left rounded-md cursor-pointer hover:bg-neutral-dimmed focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-elements-primary-main  focus-visible:ring-offset-neutral text-sm h-[38px]',
                {
                  'bg-neutral-dimmed': open,
                },
                buttonClassName,
              )}
            >
              <span className='block font-medium truncate text-text-secondary'>
                {value?.name}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <HiSelector
                  className='w-5 h-5 text-text-dimmed'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className={cn(
                  'absolute z-10 w-full py-1 mt-1 overflow-auto text-sm rounded-md shadow-lg bg-neutral-dimmed-heavy max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none',
                  listBoxClassName,
                )}
              >
                <Options options={options} groups={groups} />
              </Listbox.Options>
            </Transition>
          </div>
          <Listbox.Label
            className={clsx('block text-sm font-medium text-text-secondary', {
              hidden: labelHidden || !label,
              'mb-1': label && !labelHidden,
            })}
          >
            {label}
          </Listbox.Label>
        </>
      )}
    </Listbox>
  )
}
