import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/utils/cn'

const badgeVariants = cva(
  'inline-flex items-center border-border-main rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-elements-primary-main focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-elements-primary-main hover:bg-elements-primary-main/80 border-transparent text-elements-primary-contrastText',
        secondary:
          'bg-neutral hover:bg-neutral/80 border-transparent text-text-primary',
        destructive:
          'bg-helpers-error-main hover:bg-helpers-error-main/80 border-transparent text-helpers-error-contrastText',
        outline: 'border border-border-main text-text-primary',
        basic: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
