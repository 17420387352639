import {
  HiOutlineChartSquareBar,
  HiOutlineChatAlt,
  HiOutlineClipboardList,
  HiOutlineDocumentText,
  HiOutlineHome,
  HiOutlineMail,
  HiOutlinePlay,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi'
import { MdListAlt } from 'react-icons/md'

import { FEATURE_FLAGS } from '../constants'
import { NavigationSettingsI } from '../types'
import { NavigationPropsI } from './index'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const navigation = (props: NavigationPropsI): NavigationSettingsI => {
  const nav = [
    {
      id: 'main-nav',
      children: [
        {
          id: 'home',
          label: 'Home',
          icon: HiOutlineHome,
          url: '/',
        },
        {
          id: 'metrics',
          label: 'Metrics',
          icon: HiOutlineChartSquareBar,
          url: '/metrics',
        },
        {
          id: 'content',
          label: 'Content',
          icon: HiOutlinePlay,
          url: '/content/all-events',
        },
        {
          id: 'reports',
          label: 'Reports',
          icon: HiOutlineDocumentText,
          url: '/reports',
          // As there's only one report available atm, we don't want to show empty Reports section, so the whole Reports section is hidden when Certs page FF is disabled
          hidden:
            !props.featureFlags[FEATURE_FLAGS.FF_CERTIFICATES_PAGE] &&
            !props.featureFlags[FEATURE_FLAGS.FF_PAID_SUBS_REPORT_ENABLED],
          defaultExpanded: true,
          children: [
            // {
            //   id: 'weekly-viewing',
            //   label: 'Weekly Viewing',
            //   url: '/reports/weekly-viewing',
            //   icon: HiOutlineClipboardList,
            //   badge: 'New',
            //   badgeClassName:
            //     'bg-elements-primary-main/10 text-elements-primary-main',
            // },
            {
              id: 'certificates',
              label: 'Global Certificates',
              url: '/reports/global-certificates',
              icon: HiOutlineClipboardList,
              // badge: 'New',
              // badgeClassName:
              //   'bg-elements-primary-main/10 text-elements-primary-main',
              hidden: !props.featureFlags[FEATURE_FLAGS.FF_CERTIFICATES_PAGE],
            },
            {
              id: 'Paid Subs',
              label: 'Subscriber Report',
              url: '/reports/subscribers',
              icon: HiOutlineClipboardList,
              badge: 'New',
              badgeClassName: 'bg-elements-secondary-main text-white',
              hidden:
                !props.featureFlags[FEATURE_FLAGS.FF_PAID_SUBS_REPORT_ENABLED],
            },
          ],
        },
      ],
    },
    // {
    //   id: 'tools-nav',
    //   header: 'Tools',
    //   children: [
    //     {
    //       id: 'severity-matrix',
    //       label: 'Severity Matrix',
    //       icon: HiOutlineTag,
    //       url: '/severity-matrix',
    //     },
    //     {
    //       id: 'incident-form',
    //       label: 'Incident Form',
    //       icon: HiOutlineBell,
    //       url: '/incident-form',
    //     },
    //   ],
    // },
    {
      id: 'support-nav',
      header: 'Support',
      children: [
        {
          id: 'contact',
          label: 'Contact',
          icon: HiOutlineMail,
          url: '/contact',
        },
        {
          id: 'faqs',
          label: 'FAQs',
          icon: HiOutlineQuestionMarkCircle,
          url: '/faqs',
        },
        // {
        //   id: 'roadmap',
        //   label: 'Roadmap',
        //   icon: HiOutlineMap,
        //   url: '/roadmap',
        // },
        {
          id: 'metrics-definitions',
          label: 'Metric Definitions',
          icon: MdListAlt,
          url: '/metric-definitions',
          hidden: !props.featureFlags['FF_METRIC_DEFINITIONS_PAGE_ENABLED'],
        },
      ],
    },
  ]
  if (props.adminMode) {
    return [
      ...nav,
      {
        id: 'admin-nav',
        header: 'Admin',
        children: [
          {
            id: 'manual-notification',
            label: 'Notifications',
            url: '/manual-notification',
            icon: HiOutlineChatAlt,
          },
          {
            id: 'event-config-admin',
            label: 'Special Events Grouping',
            icon: HiOutlinePlay,
            url: '/special-events',
          },
        ],
      },
    ]
  }
  return nav
}

export default navigation
