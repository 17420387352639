import { HiExclamationCircle, HiOutlineExclamationCircle } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI, VariantsT } from '@/config/types'

import commonConvivaConfig from './common/conviva'

const videoStartFailures: MetricSettingsFn = ({
  variants,
}: {
  variants?: VariantsT
}): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'video-start-failures',
    label: 'Video Start Failures',
    dataKey: 'videoStartFailures',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiOutlineExclamationCircle,
    solidIcon: HiExclamationCircle,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Video Start Failures counts the number of Attempts that were terminated during video startup before the first video frame was played.',
    aggregationType: 'total',
    invertedChangeColor: true,
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'video-start-failures',
    },
  }
}

export default videoStartFailures
