import * as React from 'react'
import { MdFilterList } from 'react-icons/md'

import { Button } from '@/components/ui/button'
import { useFilters } from '@/pages/MetricsExplorer/contexts/filters-context'
import { cn } from '@/utils/cn'

const AddFilterButton = React.forwardRef(
  (props, ref?: React.Ref<HTMLButtonElement>) => {
    const { activeFilters = {} } = useFilters()

    const hasFilters = Object.keys(activeFilters)?.length > 0

    return (
      <Button
        {...props}
        ref={ref}
        variant='ghost'
        className='flex-shrink-0 px-2 space-x-2'
      >
        <MdFilterList className='w-5 h-5 aria-hidden' />
        <span className={cn({ 'sr-only': hasFilters })}>Add Filter</span>
      </Button>
    )
  },
)

export default AddFilterButton
