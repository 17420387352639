import axios, { Method } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'sonner'

import { CONFIG_API_DOMAIN } from '@/config/constants'
import { ClientT } from '@/config/types'
import { useClientStore } from '@/stores/client.store'

import getAccessToken from '../utils/getAccessToken'

const fetchConfig = async ({
  client,
  configUuid,
}: {
  client: string
  configUuid?: string
}) => {
  const url = configUuid
    ? `${CONFIG_API_DOMAIN}/metrics-explorer-tabs/${configUuid}`
    : `${CONFIG_API_DOMAIN}/metrics-explorer-tabs`

  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        client,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }
    return configUuid ? [response.data] : response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

const mutateConfig = async ({
  newConfig,
  configUuid,
  client,
  mutationType,
}: {
  newConfig?: MetricsExplorerTabI
  configUuid?: string
  client: ClientT
  mutationType: Method
}) => {
  const config = { ...newConfig }
  delete config.sequenceIndex

  const bodyParameters = configUuid
    ? config
    : {
        client,
        config,
      }

  const token = await getAccessToken()

  const request = axios({
    baseURL: `${CONFIG_API_DOMAIN}/metrics-explorer-tabs`,
    url: configUuid ? `/${configUuid}` : '',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    method: mutationType,
    data: bodyParameters,
  })

  return request
}

// Custom hook for getting tab configuration for the insights dashboard config API.
// If a configUuid is passed, single config returned, otherwise all.
// to access the single tab methods(put, delete), the configUuid param is necessary

const useMetricExplorerConfig = ({ configUuid }: { configUuid?: string }) => {
  const client = useClientStore((state) => state.client)
  const queryClient = useQueryClient()
  // GET all tab configs
  // GET single tab config if a uuid is passed.

  const {
    data: allTabConfigs,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery<MetricExplorerTabConfig[]>({
    queryKey: ['client-tabs', client, configUuid],
    staleTime: 0,
    refetchOnWindowFocus: true,
    queryFn: () =>
      fetchConfig({
        client,
        configUuid,
      }),
  })

  //
  const {
    isError: mutationIsError,
    isLoading: isMutating,
    ...configMutation
  } = useMutation({
    mutationFn: mutateConfig,
    onError: (error) => {
      console.log('We had an error when updating the config', error)
    },
    onSuccess: () => {
      refetch()
    },
  })

  const addTabConfig = (config: MetricsExplorerTabI) => {
    const addTabConfigMutation = configMutation.mutateAsync({
      newConfig: config,
      mutationType: 'post',
      client,
    })

    toast.promise(addTabConfigMutation, {
      position: 'bottom-right',
      loading: 'Saving bookmark...',
      success: () => {
        return `Tab saved`
      },
      error: 'We could not save the tab, please try again',
    })

    return addTabConfigMutation
  }

  const updateTabConfig = (
    config: MetricsExplorerTabI,
    options?: { silentUpdate: boolean },
  ) => {
    const updateTabConfigMutation = configMutation
      .mutateAsync({
        newConfig: { ...config, configUuid: undefined },
        mutationType: 'PUT',
        client,
        configUuid: config.configUuid,
      })
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ['client-tabs', client, config.configUuid],
        })
      })
    if (!options?.silentUpdate) {
      toast.promise(updateTabConfigMutation, {
        position: 'bottom-right',
        loading: 'Saving changes...',
        success: () => {
          return `Tab configuration updated`
        },
        error: 'We could not save your changes, please try again',
      })
    }

    return updateTabConfigMutation
  }

  const deleteTabConfig = (id: string) => {
    return configMutation
      .mutateAsync({
        mutationType: 'DELETE',
        client,
        configUuid: id,
      })
      .then(() => {
        refetch()
      })
  }

  return {
    // data
    config: allTabConfigs,
    // status
    isLoading,
    isError,
    error,
    mutationIsError,
    isMutating,

    // methods
    updateTabConfig,
    addTabConfig,
    deleteTabConfig,
  }
}

export default useMetricExplorerConfig
