import { HiChevronDown, HiOutlineCalendar } from 'react-icons/hi'

import { Button } from '@/components/ui/button'
import { PopoverTrigger } from '@/components/ui/popover'
import { useTimeStore } from '@/stores/time.store'
import { cn } from '@/utils/cn'
import getDateRangeString from '@/utils/getDateRangeString'

interface DatePickerPopoverTriggerProps {
  config: DateConfigI
  buttonClassName?: string
}

function getDateRangeLabel(config: ConfigI, timezone: string) {
  const fallback = 'Select a date'
  if (config.dateType === 'relative') {
    return config.relativeDate?.name
  }

  if (config.dateType === 'datetime') {
    const { start, end } = getDateRangeString({
      start: config.start,
      end: config.end,
      includeDay: true,
      timezone,
    })

    if (start && end) {
      return `${start} - ${end}`
    }
  }

  return fallback
}

export default function DatePickerPopoverTrigger({
  config,
  buttonClassName,
}: DatePickerPopoverTriggerProps) {
  const timezone = useTimeStore((state) => state.timezone)
  return (
    <PopoverTrigger asChild>
      <Button
        variant='outline'
        className={cn('shadow-sm bg-neutral-dimmed-heavy', buttonClassName)}
      >
        <HiOutlineCalendar className='w-4 h-4 mr-2' />
        {getDateRangeLabel(config, timezone)} {}
        <HiChevronDown className='w-4 h-4 ml-2' />
      </Button>
    </PopoverTrigger>
  )
}
