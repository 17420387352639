import { startCase } from 'lodash'

import { conviva2RelativeDates } from '@/config/relativeDates'
import { MetricSettingsI, SettingsI } from '@/config/types'
import { useClientStore } from '@/stores/client.store'
import { useThemeStore } from '@/stores/theme.store'
import { cn } from '@/utils/cn'

import getDateRangeString from '../../utils/getDateRangeString'

const eventsInfo: {
  [key: string]: {
    info: string
    aggregationType: string
    metric: string
    disclaimer?: string
  }
} = {
  vod: {
    info: 'Video content which can be streamed at any time on-demand.',
    aggregationType: 'total',
    metric: 'plays',
  },
  linear: {
    info: 'Linear events are viewed at the time of broadcast on a live channel.',
    disclaimer:
      'If the same event is broadcast on multiple channels, each channel is considered a separate linear event, and will appear as such in the dashboard.',
    aggregationType: 'peak',
    metric: 'concurrency',
  },
  sle: {
    info: 'SLE (Single Live Event) is a high-profile linear event that is watched on its own pop-up channel (currently Peacock specific).',
    aggregationType: 'Peak',
    metric: 'concurrency',
  },
}

const EventContent = ({
  streamType = 'vod',
  theme = 'light',
}: {
  streamType?: StreamTypes
  theme?: ThemeT
}) => {
  const { info, aggregationType, metric, disclaimer } = eventsInfo[streamType]

  return (
    <>
      <div>
        <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
          Stream Type Information
        </p>
        <p className='text-sm text-text-primary'>{info}</p>
        {disclaimer && (
          <div className='flex items-center p-4 mt-4 -ml-4 space-x-2 rounded bg-amber-500/10'>
            <p
              className={cn('text-xs', {
                'text-amber-700': theme === 'light',
                'text-amber-400': theme === 'dark',
              })}
            >
              {disclaimer}
            </p>
          </div>
        )}
      </div>
      <div>
        <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
          Sorted by
        </p>
        <p className='text-sm text-text-primary'>
          {startCase(aggregationType)} {startCase(metric)}
        </p>
      </div>
    </>
  )
}

const InfoModalContent = ({
  settings,
  config,
  includeGranularity = false,
  includeAggregationType = false,
  isEvent = false,
}: {
  settings: MetricSettingsI | SettingsI
  config: ConfigI
  includeGranularity?: boolean
  includeAggregationType?: boolean
  isEvent?: boolean
}) => {
  const theme = useThemeStore((state) => state.theme)
  const { clientDefaultTimezone, client } = useClientStore((state) => ({
    clientDefaultTimezone: state.clientDefaultTimezone,
    client: state.client,
  }))

  const dateRangeString = getDateRangeString({
    start: config?.start,
    end: config?.end,
    includeDay: true,
    timezone: clientDefaultTimezone,
  })

  const vodDates = conviva2RelativeDates[client]

  return (
    <div className='py-6 space-y-6'>
      <div>
        <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
          Description
        </p>
        <p className='text-sm text-text-primary'>{settings?.info}</p>
      </div>

      {!!settings?.dataSource && (
        <div>
          <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
            Data Source
          </p>
          <p className='text-sm text-text-primary'>
            {startCase(settings.dataSource)}
          </p>
        </div>
      )}

      {includeAggregationType && (
        <div>
          <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
            Aggregation Type
          </p>
          <p className='text-sm text-text-primary'>
            {startCase((settings as MetricSettingsI)?.aggregationType)}
          </p>
        </div>
      )}

      {isEvent && (
        <EventContent
          streamType={config['stream-type'] as StreamTypes}
          theme={theme}
        />
      )}

      {(dateRangeString.start || config['time-period']) && (
        <div>
          <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
            Date Range
          </p>
          {isEvent && config['stream-type']?.includes('vod') ? (
            <>
              <p className='text-sm text-text-primary'>
                {
                  vodDates.find((date) => date.id === config['time-period'])
                    ?.name
                }
              </p>
              <div className='flex items-center p-4 mt-4 -ml-4 space-x-2 rounded bg-neutral-dimmed'>
                {/* <HiOutlineInformationCircle className='flex-shrink-0 w-5 h-5' /> */}
                <p className='text-xs text-text-secondary'>
                  {' '}
                  Time periods for VOD events are in UTC. For example,
                  &quot;Today&quot; time period will display event data
                  beginning at midnight UTC. We are working on transitioning
                  this to context default timezones{' '}
                </p>{' '}
              </div>
            </>
          ) : (
            <>
              <p className='text-sm text-text-primary'>
                {dateRangeString.start} - {dateRangeString.end}
              </p>
            </>
          )}
        </div>
      )}

      {includeGranularity && (
        <div>
          <p className='mb-1 text-xs font-medium tracking-wider uppercase text-text-tertiary'>
            Data Granularity
          </p>
          <p className='text-sm text-text-primary'>
            {(settings as MetricSettingsI)?.granularity
              ? ((settings as MetricSettingsI).granularity(
                  config?.start as string,
                  config?.end as string,
                ) as any)
              : ''}
          </p>
          <p className='mt-2 text-xs text-text-dimmed'>
            Granularity is the space between each data point on the chart.
            Longer date range selections will have less granular data points to
            ensure that charts remain legible and performant.
          </p>
        </div>
      )}
    </div>
  )
}

export default InfoModalContent
