import {
  CLIENT_DEFAULT_TIMEZONES,
  CONVIVA_2_V4_API_DOMAIN,
  CONVIVA_API_DOMAIN,
  DEFAULT_DATE,
} from '@/config/constants'
import {
  DEVICE_TYPE,
  OPERATING_SYSTEM,
  PACKAGE,
  PROPOSITION,
  SPLIT_BY,
  STREAM_TYPE,
  TERRITORY,
} from '@/config/formOptions'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'
import { commonMinuteGranularity } from '@/config/metricsSettings/granularityHelpers'
import { GROUPS } from '@/config/metricsSettings/groups'
import { FormI, VariantsT } from '@/config/types'

const commonConvivaConfig = ({ variants }: { variants?: VariantsT }) => {
  const withClient = variants?.withClient || false

  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      options: [
        SPLIT_BY['proposition'],
        SPLIT_BY['territory'],
        SPLIT_BY['stream-type'],
        SPLIT_BY['device-type'],
        SPLIT_BY['device'],
        SPLIT_BY['operating-system'],
      ],
    },
    {
      value: 'proposition',
      type: 'button-group',
      multi: true,
      options: [
        PROPOSITION['all'],
        PROPOSITION['peacock'],
        ...(withClient ? [PROPOSITION['now']] : [PROPOSITION['now-tv']]),
        PROPOSITION['sky-go'],
        PROPOSITION['sky-q'],
        PROPOSITION['soip'],
        PROPOSITION['sky-stream'],
        PROPOSITION['sky-sports'],
        PROPOSITION['sky-x'],
        PROPOSITION['sky-store'],
        PROPOSITION['sky-sports-box-office'],
      ],
    },
    {
      value: 'territory',
      type: 'button-group',
      multi: true,
      options: [
        TERRITORY['all'],
        TERRITORY['uk'],
        TERRITORY['us'],
        TERRITORY['de'],
        TERRITORY['it'],
        TERRITORY['roi'],
        TERRITORY['at'],
      ],
    },
    {
      value: 'stream-type',
      type: 'button-group',
      multi: true,
      options: [
        STREAM_TYPE['all'],
        STREAM_TYPE['vod'],
        STREAM_TYPE['linear'],
        STREAM_TYPE['sle'],
        STREAM_TYPE['trailers'],
        STREAM_TYPE['clips'],
      ],
    },
    {
      value: 'device-type',
      type: 'button-group',
      multi: true,
      options: [
        DEVICE_TYPE['all'],
        DEVICE_TYPE['stb'],
        DEVICE_TYPE['mobile'],
        DEVICE_TYPE['smart-tv'],
        DEVICE_TYPE['tv-stick'],
        DEVICE_TYPE['console'],
        DEVICE_TYPE['web'],
        DEVICE_TYPE['desktop'],
        DEVICE_TYPE['streaming-stick'],
      ],
    },
    {
      value: 'operating-system',
      type: 'button-group',
      multi: true,
      options: [
        OPERATING_SYSTEM['all'],
        OPERATING_SYSTEM['amazon-fire'],
        OPERATING_SYSTEM['android'],
        OPERATING_SYSTEM['android-tv'],
        OPERATING_SYSTEM['apple-tv'],
        OPERATING_SYSTEM['chromecast'],
        OPERATING_SYSTEM['cox'],
        OPERATING_SYSTEM['desktop'],
        OPERATING_SYSTEM['fire-os'],
        OPERATING_SYSTEM['ios'],
        OPERATING_SYSTEM['lg-tv'],
        OPERATING_SYSTEM['linux'],
        OPERATING_SYSTEM['playstation'],
        OPERATING_SYSTEM['rdk'],
        OPERATING_SYSTEM['roku'],
        OPERATING_SYSTEM['samsung-tv'],
        OPERATING_SYSTEM['sky-q'],
        OPERATING_SYSTEM['tizen'],
        OPERATING_SYSTEM['tv-os'],
        OPERATING_SYSTEM['vidaa'],
        OPERATING_SYSTEM['vidaa'],
        OPERATING_SYSTEM['vizio'],
        OPERATING_SYSTEM['web'],
        OPERATING_SYSTEM['web-os'],
        OPERATING_SYSTEM['xbox'],
        OPERATING_SYSTEM['xclass-tv'],
        OPERATING_SYSTEM['xfinity'],
        OPERATING_SYSTEM['youview'],
      ],
    },
    {
      value: 'package',
      type: 'button-group',
      multi: true,
      options: [
        PACKAGE['all'],
        PACKAGE['free'],
        PACKAGE['premium'],
        PACKAGE['prem-plus'],
      ],
    },
  ]

  return {
    dataSource: 'conviva',
    legacyGroup: 'conviva',
    group: GROUPS.CONVIVA,
    apiDomain: withClient ? CONVIVA_2_V4_API_DOMAIN : CONVIVA_API_DOMAIN,
    supportsClient: true,
    timezone: CLIENT_DEFAULT_TIMEZONES['sky-europe-and-peacock'],
    useTimezone: !!withClient,
    tags: {
      enabled: !!withClient,
      endpoint: '/tags',
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: commonMinuteGranularity,
    defaultConfig: {
      'split-by': 'proposition',
      proposition: ['all'],
      territory: ['all'],
      'device-type': ['all'],
      'operating-system': ['all'],
      ...DEFAULT_DATE('sky-europe-and-peacock'),
      ...(withClient ? { client: 'sky-europe-and-peacock' } : {}),
    },
  }
}
export default commonConvivaConfig
