import { MdFilterList } from 'react-icons/md'

import { Button } from '@/components/ui/button'

export default function FiltersNotAvailable() {
  return (
    <Button disabled variant='ghost' className='px-2 space-x-2'>
      <MdFilterList className='w-5 h-5 aria-hidden' />
      <span>No filters available</span>
    </Button>
  )
}
