import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
} from '@/components/ui/command'
import { FormI, FormOptionConfigI } from '@/config/types'

import FiltersSelectionDropdownItem from '../FiltersSelectionDropdownItem'

interface PropsI extends Pick<FormI, 'label' | 'multi'> {
  value: keyof FiltersI
  options: Option[]
}
type Option = {
  value: string
  label: string
  config?: FormOptionConfigI
}

export default function FiltersSelectionCommand({
  value,
  options,
  multi,
}: PropsI) {
  return (
    <Command>
      <CommandInput
        autoFocus
        placeholder='Filter...'
        className='border-0 border-transparent rounded-none focus-visible:ring-0 focus:outline-none'
      />
      <CommandList>
        <CommandEmpty>No option found</CommandEmpty>
        <CommandGroup>
          {options.map((option) => (
            <FiltersSelectionDropdownItem
              key={option.value}
              formGroup={value}
              value={option.value}
              config={option.config}
              isMulti={multi}
            />
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}
