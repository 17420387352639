import * as React from 'react'

import useMetricExplorerConfig from '@/hooks/useMetricExplorerConfig'
import { createEmptyTab } from '@/pages/MetricsExplorer/utils/createEmptyTab'
import { useClientStore } from '@/stores/client.store'
import { useMetricsExplorerStore } from '@/stores/metrics-explorer.store'
import { useSharedChartStore } from '@/stores/shared-chart.store'
import { logEvent } from '@/utils/firebaseAnalytics'

const handleSilentRefreshing = (
  tab: MetricsExplorerTabI,
  silentlyRefresh: boolean,
) => {
  return {
    ...tab,
    tiles: tab.tiles.map((tile) => ({
      ...tile,
      silentlyRefreshing: silentlyRefresh,
    })),
  }
}

export const sortTabSequenceIndices = (tabs: MetricsExplorerTabI[]) => {
  return tabs.map((tab, index) => {
    return { ...tab, sequenceIndex: index }
  })
}

export const useMetricsExplorerTabs = () => {
  const client = useClientStore((state) => state.client)

  const {
    addTabConfig,
    updateTabConfig,
    config: savedTabConfigs,
  } = useMetricExplorerConfig({})

  const allClientTabs = useMetricsExplorerStore((state) => state.tabs)

  const setClientTabs = useMetricsExplorerStore((state) => state.setClientTabs)
  const allClientActiveTabIndex = useMetricsExplorerStore(
    (state) => state.activeTabIndex,
  )
  const setClientActiveTabIndex = useMetricsExplorerStore(
    (state) => state.setClientActiveTabIndex,
  )
  const setSharedZoomInfo = useSharedChartStore(
    (state) => state.setSharedZoomInfo,
  )

  const tabs = React.useMemo(() => {
    return allClientTabs[client] || [createEmptyTab(0)]
  }, [allClientTabs, client]) as MetricsExplorerTabI[]

  const activeTabIndex = React.useMemo(
    () => allClientActiveTabIndex[client] || 0,
    [allClientActiveTabIndex, client],
  )

  const addTab = React.useCallback(
    (tabConfig?: MetricsExplorerTabI) => {
      const sequenceIndex = tabs.length || 0

      const newTab = tabConfig
        ? { ...tabConfig, sequenceIndex }
        : createEmptyTab(sequenceIndex)

      const nextTabs = [...tabs, newTab]
      setClientTabs(client, nextTabs)
      setClientActiveTabIndex(client, sequenceIndex)
      // Reset sharedZoomChartInfo when adding a new tab
      setSharedZoomInfo(null, null)
      logEvent('create_metrics_explorer_tab')
    },
    [tabs, setClientTabs, setClientActiveTabIndex, client],
  )

  const removeTab = React.useCallback(
    (tabId: string) => {
      if (!tabId) return

      const sequenceIndex = tabs.findIndex((tab) => tab.id === tabId)
      const isRemovingActiveTab = activeTabIndex === sequenceIndex

      const nextTabs = tabs.filter((tab) => tab.id !== tabId)

      const sortedTabs = sortTabSequenceIndices(nextTabs)

      let nextActiveIndex = 0
      // Stay on the current tab if it's not being removed
      if (!isRemovingActiveTab) {
        const activeTabId = tabs.find(
          (tab) => tab.sequenceIndex === activeTabIndex,
        )?.id

        // Find the new sequence index of the active tab within sortedTabs
        nextActiveIndex = sortedTabs.findIndex((tab) => tab.id === activeTabId)
      } else {
        if (sortedTabs[activeTabIndex]) {
          // If there's a tab to the right of the removed tab, select it
          nextActiveIndex = activeTabIndex
        } else if (sortedTabs[activeTabIndex - 1]) {
          // If there's no tab to the right, but there is one to the left, select it
          nextActiveIndex = activeTabIndex - 1
        }
      }

      logEvent('remove_metrics_explorer_tab')

      setClientActiveTabIndex(client, nextActiveIndex)
      setClientTabs(client, sortedTabs)
      // Reset sharedZoomChartInfo when removing a tab
      setSharedZoomInfo(null, null)
    },

    [tabs, setClientTabs, activeTabIndex, setClientActiveTabIndex, client],
  )

  const updateTab = React.useCallback(
    (
      func: (prevTab: MetricsExplorerTabI) => MetricsExplorerTabI,
      options?: { silentlyRefresh: boolean; targetTabId?: string },
    ) => {
      setClientTabs(client, (currentTabs) => {
        if (!currentTabs) return []
        return currentTabs.map((tab) => {
          // Update a non active tab, eg when deleting it from the saved list, we need to remove the configUuid
          if (options?.targetTabId) {
            if (tab.id === options?.targetTabId) {
              return func(
                handleSilentRefreshing(tab, options?.silentlyRefresh || false),
              )
            }
            // Update active tab
          } else if (tab.sequenceIndex === activeTabIndex) {
            return func(
              handleSilentRefreshing(tab, options?.silentlyRefresh || false),
            )
          }

          return tab
        })
      })
    },
    [setClientTabs, client, activeTabIndex],
  )

  const updateTile = React.useCallback(
    (
      tileId: string,
      func: (tile: MetricExplorerTileI) => MetricExplorerTileI,
      options?: { silentlyRefresh: boolean },
    ) => {
      setClientTabs(client, (currentTabs) => {
        const updatedTabs = currentTabs.map((tab) => {
          const updatedTiles = tab.tiles.map((tile) => {
            if (tile.id !== tileId) {
              return tile
            }
            const silentlyRefreshing = options?.silentlyRefresh || false
            return func({ ...tile, silentlyRefreshing })
          })

          return { ...tab, tiles: updatedTiles }
        })
        return updatedTabs
      })
    },
    [setClientTabs, client],
  )

  const activeTab = React.useMemo(() => {
    return tabs.find(({ sequenceIndex }) => activeTabIndex === sequenceIndex)
  }, [tabs, activeTabIndex])

  const setTabs = React.useCallback(
    (nextTabs: MetricsExplorerTabI[]) => {
      setClientTabs(client, nextTabs)
    },
    [client, setClientTabs],
  )

  const setActiveTabIndex = React.useCallback(
    (nextActiveTabIndex: number) => {
      setClientActiveTabIndex(client, nextActiveTabIndex)
      // Reset sharedZoomChartInfo when changing tabs
      setSharedZoomInfo(null, null)
    },
    [client, setClientActiveTabIndex],
  )

  // methods to add and update server saved tab configs

  const saveLocalTabToDatabase = () => {
    // get the active tab
    const activeTab = tabs.find(
      ({ sequenceIndex }) => activeTabIndex === sequenceIndex,
    )

    addTabConfig(activeTab).then((res) => {
      setClientTabs(client, (currentTabs) => {
        return currentTabs.map((tab) => {
          if (tab.sequenceIndex === activeTabIndex) {
            return handleSilentRefreshing(
              // Adding a configUuid to a local tab means it's saved in the DB
              { ...tab, configUuid: res.data?.uuid },
              true,
            )
          }
          return tab
        })
      })
    })
  }

  const syncTabConfig = () => {
    const activeTab = tabs.find(
      ({ sequenceIndex }) => activeTabIndex === sequenceIndex,
    )

    // Check if config has been deleted from the server since opened. This is to avoid sending a PUT when it should be a post
    const isSavedToDatabase =
      activeTab.configUuid &&
      savedTabConfigs?.findIndex(
        (tabConfig) => tabConfig.uuid === activeTab.configUuid,
      ) !== -1

    if (isSavedToDatabase) {
      return updateTabConfig(activeTab)
    } else {
      return saveLocalTabToDatabase()
    }
  }

  return {
    tabs,
    setTabs,
    activeTabIndex,
    setActiveTabIndex,
    activeTab,
    addTab,

    removeTab,
    updateTab,
    updateTile,

    //API methods
    saveLocalTabToDatabase,
    syncTabConfig,
    savedTabConfigs: savedTabConfigs,
  }
}
