import {
  aggregationLegend,
  aggregationMultipleMetricsTotal,
  aggregationToPie,
  aggregationTotal,
  timeseriesAverage,
  timeseriesLegendMax,
  timeseriesLegendMin,
  timeseriesMin,
  timeseriesMultipleMetricsToLine,
  timeseriesPeak,
  timeseriesToLine,
  timeseriesToSparkline,
  timeseriesTotal,
} from '@/config/dataFormatters/conviva'

import {
  timeseriesLegendMedian,
  timeseriesLegendP75,
  timeseriesLegendP95,
  timeseriesLegendP97,
  timeseriesLegendP99,
} from '../dataFormatters/conviva/convivaTimeseriesStatisticsLegend'

export const convivaStyleDataFormatters = {
  timeseries: {
    line: timeseriesToLine,
    average: timeseriesAverage,
    min: timeseriesMin,
    peak: timeseriesPeak,
    total: timeseriesTotal,
    sparkline: timeseriesToSparkline,
    legendMin: timeseriesLegendMin,
    legendMax: timeseriesLegendMax,
    legendP75: timeseriesLegendP75,
    legendP95: timeseriesLegendP95,
    legendP97: timeseriesLegendP97,
    legendP99: timeseriesLegendP99,
    legendMedian: timeseriesLegendMedian,
  },
  aggregation: {
    total: aggregationTotal,
    pie: aggregationToPie,
    legend: aggregationLegend,
  },
}

export const convivaStyleDataFormattersMultipleMetrics = {
  timeseries: {
    line: timeseriesMultipleMetricsToLine,
    // average: timeseriesAverage,
    // min: timeseriesMin,
    // peak: timeseriesPeak,
    // total: timeseriesTotal,
    // sparkline: timeseriesToSparkline,
    // legendMin: timeseriesLegendMin,
    // legendMax: timeseriesLegendMax,
    // legendP75: timeseriesLegendP75,
    // legendP95: timeseriesLegendP95,
    // legendP97: timeseriesLegendP97,
    // legendP99: timeseriesLegendP99,
    // legendMedian: timeseriesLegendMedian,
  },
  aggregation: {
    total: aggregationMultipleMetricsTotal,
    // pie: aggregationToPie,
    // legend: aggregationLegend,
  },
}
