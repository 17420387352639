import { HiLogin, HiOutlineLogin } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI, VariantsT } from '@/config/types'

import commonIdentityConfig from './common/identity'

const signIns: MetricSettingsFn = ({
  variants,
}: {
  variants?: VariantsT
}): MetricSettingsI => {
  const identityConfig = commonIdentityConfig({ variants })

  return {
    ...identityConfig,
    value: 'sign-ins',
    label: 'Sign Ins',
    shortLabel: 'Sign Ins',
    dataKey: 'signIns',
    hideInMetricsExplorer: true, // REMOVE ME WHEN AVAILABLE FOR SKY EUROPE
    comingSoon: {
      enabled: true,
      message: 'Sign Ins Coming Soon',
      subMessage:
        'The Identity team is working on integrating Sky Europe into their Prometheus to enable this',
    },
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline', 'column'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 10,
      },
      sle: {
        enabled: false,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: false,
        },
        count: 10,
      },
    },
    icon: HiOutlineLogin,
    solidIcon: HiLogin,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(
        value,
      ),
    info: 'Sign Ins shows the number of user Sign Ins during a given period.',
    aggregationType: 'total',
    defaultConfig: {
      ...identityConfig.defaultConfig,
      metric: 'sign-ins',
    },
  }
}

export default signIns
