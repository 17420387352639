import clsx from 'clsx'
import React from 'react'

import Chip from '@/components/Chip'
import EventItemQualityMetrics from '@/components/Event/EventItemQualityMetrics'
import ImageFallback from '@/components/ImageFallback'
import Live from '@/components/Live'
import VideoFormatIcons from '@/components/VideoFormatIcons'
import { EventsSettingsI } from '@/config/types'
import useMetricSettings from '@/hooks/useMetricSettings'
import useTheme from '@/hooks/useTheme'
import { useEventStore } from '@/stores/event.store'
import { useTimeStore } from '@/stores/time.store'
import { logEvent } from '@/utils/firebaseAnalytics'
import getChannelLogo from '@/utils/getChannelLogo'
import getDateRangeString from '@/utils/getDateRangeString'
import getEventConfigParams from '@/utils/getEventConfigParams'

import ChipGroup from '../../../components/ChipGroup'
import { DiffBar } from './DiffBar'
import { EventItemMeta } from './EventItemMeta'
import { QUALITY_METRICS } from '.'

export const EventItem = ({
  event,
  id,
  index,
  config,
  settings: eventsSettings,
  setDiffBarHovered,
}: {
  event: Conviva2EventI
  index: number
  id: string
  config: ConfigI
  settings: EventsSettingsI
  setDiffBarHovered: (args: {
    enabled: boolean
    start: string
    end: string
  }) => void
}) => {
  const timezone = useTimeStore((state) => state.timezone)
  const eventIsOpen = useEventStore((state) => state.open)
  const selectedEvent = useEventStore((state) => state.event)
  const onOpenEvent = useEventStore((state) => state.onOpenEvent)
  const [channelLogoMissing, setChannelLogoMissing] = React.useState(false)
  const { type } = useTheme()!

  const streamType = Array.isArray(config['stream-type'])
    ? (config['stream-type'][0] as StreamTypes)
    : (config['stream-type'] as StreamTypes)

  const { metric, ...conviva2EventMetricParams } = getEventConfigParams(
    event,
    config,
  )

  const metricSettings = useMetricSettings({
    metric: metric.includes('concurrent-plays') ? 'concurrent-plays' : 'plays',
  })

  const eventStart = conviva2EventMetricParams.start
  const eventEnd = conviva2EventMetricParams.end

  const { start, end, relative } = getDateRangeString({
    start: eventStart,
    end: eventEnd,
    timezone,
  })

  const channelLogo = getChannelLogo(event?.meta, event?.territories)

  const handleSetDiffBar = (enabled: boolean) => {
    setDiffBarHovered({
      enabled,
      start: eventStart as string,
      end: eventEnd as string,
    })
  }

  const eventIsSelected = selectedEvent?.id === id
  const Icon = metricSettings?.solidIcon
  return (
    <li
      tabIndex={0}
      className={clsx(
        'cursor-pointer group rounded-sm border-b border-border-main',
        {
          'ring-2 ring-inset ring-elements-primary-main bg-neutral hover:bg-neutral-shadow':
            eventIsSelected && eventIsOpen,
          'hover:bg-neutral-dimmed bg-neutral-dimmed-heavy': !eventIsSelected,
        },
      )}
      onClick={() => {
        onOpenEvent({
          id,
          streamType,
          config,
          ...event,
        })
        const { meta, ...restOfEvent } = event
        logEvent('select_event', { ...meta, ...restOfEvent })
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
        if (e.key === 'Enter') {
          onOpenEvent({
            id,
            streamType,
            config,
            ...event,
          })
        }
      }}
      onMouseEnter={() => {
        handleSetDiffBar(true)
      }}
      onMouseLeave={() => {
        handleSetDiffBar(false)
      }}
    >
      <div className='grid items-center grid-cols-12 gap-6 px-4 py-4 md:px-6 2xl:gap-8'>
        <div className='col-span-3 2xl:col-span-2'>
          <div className='relative flex items-center justify-center h-20 overflow-hidden bg-center bg-no-repeat bg-cover max-w-[200px] rounded-lg sm:h-24 lg:h-28 bg-neutral-shadow'>
            <ImageFallback
              src={event.meta?.imageUrl?.replace('/16-9/500', '/16-9/1000')}
              alt={event?.meta?.title}
              className='object-cover w-full h-full rounded-lg'
            />
            <div className='absolute z-10 flex bottom-2 left-2'>
              <p className='text-xl font-medium leading-none text-white'>
                {index + 1}
              </p>
            </div>
            <div className='absolute z-10 items-end justify-end hidden w-20 h-4 md:w-24 sm:flex bottom-2 right-2'>
              {event?.meta?.channelName && channelLogo && (
                <ImageFallback
                  src={channelLogo}
                  alt='Channel Logo'
                  className='object-auto max-h-5 xl:max-h-6 max-w-20'
                  transparentFallback={true}
                  onError={() => setChannelLogoMissing(true)}
                />
              )}
            </div>
            <div className='absolute top-0 w-full h-full opacity-60 bg-gradient-to-t from-event-backdrop' />
          </div>
        </div>

        <div className='grid h-full grid-cols-12 col-span-9 2xl:col-span-10'>
          <div className='flex items-center justify-between col-span-12'>
            <div className='flex items-center'>
              <Live start={eventStart} end={eventEnd} className='mr-1.5' />
              <p className='overflow-hidden font-medium whitespace-nowrap text:sm md:text-base text-text-secondary line-clamp-1'>
                {event?.meta?.title}
              </p>
            </div>
            <div className='flex items-center justify-self-end space-x-1.5'>
              {Icon && (
                <Icon className='w-4 h-4 text-text-tertiary/50  mb-0.5 ' />
              )}
              <p className='flex items-center font-bold text-right text-md text-text-secondary tabular-nums'>
                {event?.concurrentPlays
                  ? new Intl.NumberFormat('en-GB', {}).format(
                      event?.concurrentPlays,
                    )
                  : event?.plays &&
                    new Intl.NumberFormat('en-GB', {}).format(event?.plays)}
              </p>
            </div>
          </div>

          <div className='grid items-start grid-cols-12 col-span-12'>
            <div className='flex flex-col justify-between h-full col-span-8'>
              <div className='flex flex-col'>
                {streamType !== 'vod' && start && end && (
                  <p className='text-xs truncate xl:text-sm mt-0.5 text-text-dimmed'>
                    {start} - {end}{' '}
                    <span className='hidden ml-1 italic opacity-0 2xl:inline-block text-text-tertiary group-hover:opacity-100'>
                      ({relative})
                    </span>
                  </p>
                )}
                <div className='flex items-center space-x-2'>
                  <EventItemMeta event={event} />
                  {['sle', 'vod'].includes(streamType) &&
                    getIcons(event).length > 0 && (
                      <>
                        <span className='text-text-tertiary'>∙</span>

                        <VideoFormatIcons
                          videoFormatIcons={getIcons(event)}
                          className='flex'
                          imageClassName='h-3.5 mt-1 mr-1 opacity-70'
                          type={type}
                        />
                      </>
                    )}
                </div>
              </div>
              <div className='flex flex-wrap items-start gap-1 pb-1'>
                {event?.meta?.channelName && channelLogoMissing && (
                  <Chip
                    label={event.meta.channelName}
                    image={undefined}
                    icon={undefined}
                  />
                )}
                {eventsSettings.showIcons.territory.enabled && (
                  <ChipGroup
                    maxChips={3}
                    formGroup={'territory'}
                    settings={eventsSettings}
                    list={event.territories || []}
                  />
                )}
                {eventsSettings.showIcons.territory.enabled && (
                  <ChipGroup
                    maxChips={3}
                    formGroup={'proposition'}
                    settings={eventsSettings}
                    list={event.propositions || []}
                  />
                )}
              </div>
            </div>

            {streamType !== 'vod' && (
              <div className='flex items-end justify-center h-full col-span-4 pb-1 '>
                <EventItemQualityMetrics
                  qualityMetrics={QUALITY_METRICS}
                  eventData={event}
                  wrapperClassName='self-end flex w-full items-end md:item-center justify-end gap-0 md:gap-3 flex-col md:flex-row md:-mr-5'
                  itemClassName='md:px-3 py-0 md:py-1.5 md:bg-neutral text-xs rounded-full flex items-center tabular-nums justify-center relative space-x-1'
                  itemValueClassName='text-[9px] md:text-[12px] font-bold text-text-secondary '
                  itemLabelClassName='text-[8px] md:text-[12px] font-semibold text-text-tertiary/80 whitespace-nowrap p-0 m-0 tracking-[-0.005rem]'
                />
              </div>
            )}

            {/* )} */}
          </div>
        </div>
      </div>
      {!!event?.meta?.startTimeEpochUTC && !!event?.meta?.startTimeEpochUTC && (
        <div className='hidden w-full px-0.5 pb-0.5 sm:block'>
          <DiffBar
            start={config.start}
            end={config.end}
            itemStart={eventStart}
            itemEnd={eventEnd}
            setDiffBarHovered={setDiffBarHovered}
            enabled
          />
        </div>
      )}
    </li>
  )
}

function getIcons(event?: Conviva2EventI) {
  return event?.metricsByVideoFormat
    ? Object.keys(event.metricsByVideoFormat).filter(
        // temporarily filtering out 'all' for Ocellus events, will be changed to 'unknown' on the BE
        (key) => key !== 'unknown' && key !== 'all' && !!key,
      )
    : []
}
