import { HiCheck } from 'react-icons/hi'

import { Button } from '@/components/ui/button'

interface DatePickerQuickRangeItemProps {
  relativeDateItem: RelativeDateI
  isSelected: boolean
  onSelect: (relativeDateItem: RelativeDateI) => void
}

export default function DatePickerQuickRangeItem({
  relativeDateItem,
  isSelected,
  onSelect,
}: DatePickerQuickRangeItemProps) {
  return (
    <div key={relativeDateItem.id}>
      <Button
        role='button'
        variant='ghost'
        aria-label={`Select ${relativeDateItem.name}`}
        className='justify-start w-full'
        onClick={() => {
          onSelect(relativeDateItem)
        }}
      >
        <div className='w-4 mr-2'>
          {isSelected && <HiCheck className='w-4 h-4 ' />}
        </div>
        {relativeDateItem.name}
      </Button>
    </div>
  )
}
