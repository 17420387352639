import { HiOutlineExclamation } from 'react-icons/hi'

import { ClientT } from '@/config/types'
import { cn } from '@/utils/cn'

const LinearDisclaimer = ({
  client,
  theme,
  onClick,
}: {
  client: ClientT
  theme: ThemeT
  onClick?: () => void
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'flex items-center px-2.5 py-2  text-xs font-regular border-l-2 border-amber-500 hover:bg-amber-500/40 bg-amber-500/10 transition-colors',
        {
          'text-amber-700': theme === 'light',
          'text-amber-400': theme === 'dark',
        },
      )}
    >
      <HiOutlineExclamation className='flex-shrink-0 w-4 h-4 mr-2.5' />
      Linear Events are split by channel
      {client === 'sky-europe' && ', proposition,'}
      {client !== 'peacock' && client !== 'nbcu-dtc' && ' and territory'}
    </button>
  )
}

export default LinearDisclaimer
