import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official'
import { Point, Series, YAxisOptions } from 'highcharts/highstock'
import moment from 'moment'
import React from 'react'

import {
  SharedPlotBand,
  useSharedChartStore,
} from '../../stores/shared-chart.store'

interface ChartOptionsI extends Highcharts.Options {
  yAxis?: YAxisOptions[]
}

interface SharedHighchartsReactProps extends HighchartsReactProps {
  options?: ChartOptionsI
  comparisonOffset?: [number, any] | null
}

const SharedHighchartsReact = React.forwardRef(
  (
    { options = {}, comparisonOffset, ...props }: SharedHighchartsReactProps,
    ref,
  ) => {
    const [chartOptions, setChartOptions] =
      React.useState<ChartOptionsI>(options)
    const [selectedPlotBand, setSelectedPlotBand] =
      React.useState<SharedPlotBand>(null)

    const setSharedTooltipTimestamp = useSharedChartStore(
      (state) => state.setSharedTooltipTimestamp,
    )
    const sharedTooltipTimestamp = useSharedChartStore(
      (state) => state.sharedTooltipTimestamp,
    )
    const sharedPlotBand = useSharedChartStore((state) => state.sharedPlotBand)

    const sharedZoomInfo = useSharedChartStore((state) => state.sharedZoomInfo)
    const setSharedZoomInfo = useSharedChartStore(
      (state) => state.setSharedZoomInfo,
    )

    const handleSetSharedTooltipTimestamp = React.useCallback(
      (timestamp: number | null) => {
        if (comparisonOffset) {
          setSharedTooltipTimestamp(
            moment(timestamp)
              .add(comparisonOffset[0], comparisonOffset[1])
              .unix() * 1000,
          )
        } else {
          setSharedTooltipTimestamp(timestamp)
        }
      },
      [setSharedTooltipTimestamp],
    )

    const handleResetAllPoints = () => {
      const myRef = ref as React.RefObject<any>

      if (!myRef) return

      if (myRef?.current?.chart.series?.length > 0) {
        myRef?.current?.chart.series?.forEach((series: Series) => {
          series?.data?.forEach((point) => {
            // ts ignore as point.state does exist on this property
            //@ts-ignore
            if (point.state === 'hover') {
              point.setState('')
            }
          })
        })
      }
    }

    const handleResetAllPlotBands = () => {
      const myRef = ref as React.RefObject<any>

      if (!myRef) return

      setChartOptions((prevChartOptions) => {
        return {
          ...prevChartOptions,
          // Reset xAxis to how it was initially passed in
          xAxis: options.xAxis,
        }
      })
    }

    const handleResetSharedZoomInfo = () => {
      const myRef = ref as React.RefObject<any>

      if (!myRef) return

      setChartOptions((prevChartOptions) => {
        return {
          ...prevChartOptions,
          // Reset xAxis to how it was initially passed in
          xAxis: options.xAxis,
        }
      })
    }

    const handleSetTooltip = (timestamp: number | null) => {
      const myRef = ref as React.RefObject<any>

      if (!myRef) return

      const points: Point[] = []

      if (myRef?.current?.chart.series?.length > 0) {
        myRef?.current?.chart.series?.forEach((series: Series) => {
          const targetTime = comparisonOffset
            ? moment(timestamp)
                .subtract(comparisonOffset[0], comparisonOffset[1])
                .unix() * 1000
            : timestamp

          const matchingPoint = series?.data?.find(
            (point) => point?.x === targetTime,
          )
          if (matchingPoint) {
            points.push(matchingPoint)
          }
        })
      }

      if (points.length > 0) {
        myRef?.current?.chart?.tooltip?.refresh(points)
        myRef.current?.chart.xAxis[0].drawCrosshair(null, points[0])
      } else {
        myRef?.current?.chart?.tooltip?.destroy()
        myRef.current?.chart.xAxis[0].drawCrosshair(null, null)
      }
    }

    const handleSetPlotBands = (plotBand: SharedPlotBand) => {
      const myRef = ref as React.RefObject<any>

      if (!myRef) return

      if (!plotBand) return

      setChartOptions((prevChartOptions) => {
        const currentXAxis = Array.isArray(prevChartOptions?.xAxis)
          ? prevChartOptions?.xAxis[0]
          : prevChartOptions?.xAxis

        return {
          ...prevChartOptions,
          xAxis: [
            {
              ...(currentXAxis || {}),
              plotBands: [...(currentXAxis?.plotBands || []), plotBand],
            },
          ],
        }
      })

      setSelectedPlotBand(plotBand)
    }

    const handleSetSharedZoomInfo = React.useCallback(
      (event: any) => {
        if (event.xAxis) {
          const min = event.xAxis[0].min
          const max = event.xAxis[0].max

          setSharedZoomInfo(Math.floor(min), Math.floor(max))

          setExtremes(min, max)
        }
      },
      [sharedZoomInfo, setSharedZoomInfo],
    )

    const setExtremes = (min: number | null, max: number | null) => {
      const myRef = ref as React.RefObject<any>
      myRef?.current?.chart?.xAxis[0].setExtremes(
        min || undefined,
        max || undefined,
      )
    }

    React.useEffect(() => {
      setChartOptions((prevChartOptions) => {
        return {
          ...prevChartOptions,
          chart: {
            ...prevChartOptions?.chart,
            events: {
              ...prevChartOptions?.chart?.events,
              load: function () {
                const handleMouseLeave = function () {
                  handleSetSharedTooltipTimestamp(null)
                }
                this.container.addEventListener('mouseleave', handleMouseLeave)

                this.xAxis[0].setExtremes(
                  sharedZoomInfo?.min || undefined,
                  sharedZoomInfo?.max || undefined,
                )
              },
              selection: (event) => {
                if (event) {
                  handleSetSharedZoomInfo(event)
                  return true
                } else {
                  return false
                }
              },
            },
          },
          plotOptions: {
            ...prevChartOptions?.plotOptions,
            series: {
              ...prevChartOptions?.plotOptions?.series,
              point: {
                ...prevChartOptions?.plotOptions?.series?.point,
                events: {
                  ...prevChartOptions?.plotOptions?.series?.point?.events,
                  mouseOver: function () {
                    handleSetSharedTooltipTimestamp(this.x)
                  },
                },
              },
            },
          },
          zooming: {
            resetButton: {
              theme: {
                // 'Hides' reset button seeing as we show our own reset button outside of this component
                style: { display: 'none' },
              },
            },
          },
        }
      })
    }, [options, setSharedTooltipTimestamp, setSharedZoomInfo, sharedZoomInfo])

    React.useEffect(() => {
      handleResetAllPoints()
      handleSetTooltip(sharedTooltipTimestamp)
    }, [sharedTooltipTimestamp])

    React.useEffect(() => {
      handleResetAllPlotBands()
      handleSetPlotBands(sharedPlotBand)
    }, [sharedPlotBand])

    React.useEffect(() => {
      handleResetSharedZoomInfo()
      setExtremes(sharedZoomInfo.min, sharedZoomInfo.max)
    }, [sharedZoomInfo])

    React.useEffect(() => {
      // Because handleSetPlotBands is asynchronous (setting React state)
      // I can't chain handleSetTooltip at the end of that function (it runs too early)
      // Instead, state is set with the most recent selected plotBand at the end of handleSetPlotBands
      // In order to set the tooltip from this useEffect
      if (selectedPlotBand && selectedPlotBand.from) {
        handleSetTooltip(selectedPlotBand.from)
      }
    }, [selectedPlotBand])

    return (
      <HighchartsReact options={chartOptions} ref={ref as any} {...props} />
    )
  },
)

export default SharedHighchartsReact
