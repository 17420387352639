import clsx from 'clsx'
import React from 'react'
import { CgSpinner } from 'react-icons/cg'
import {
  HiOutlineClipboardCopy,
  HiOutlineKey,
  HiOutlineLogout,
  HiOutlineMoon,
} from 'react-icons/hi'
import { LuRedo2 } from 'react-icons/lu'
import Skeleton from 'react-loading-skeleton'

import Switch from '@/components/Switch'
import useFetchAccessToken from '@/hooks/useFetchAccessToken'
import { queryClient } from '@/index'
import { useInformationalDialogStore } from '@/stores/informational-dialog.store'
import { useUiStore } from '@/stores/ui.store'
import { logEvent } from '@/utils/firebaseAnalytics'
import getAccessToken from '@/utils/getAccessToken'
import { Menu, Transition } from '@headlessui/react'

interface PropsI {
  user?: ADUserI
  userLoading: boolean
  open: boolean
  logout: () => void
  className: string
  theme: string
  setTheme: (theme: ThemeT) => void
}

function User({ user, userLoading }: { user?: ADUserI; userLoading: boolean }) {
  return (
    <div className='flex items-center py-4'>
      <div className='flex flex-col flex-1 min-w-0 pl-4'>
        <p className='text-sm font-medium truncate text-text-primary'>
          {userLoading ? (
            <Skeleton width={100} />
          ) : (
            <span>
              {user?.givenName} {user?.surname}
            </span>
          )}
        </p>

        <p className='text-sm line-clamp-2 text-text-tertiary'>
          {userLoading ? <Skeleton width={66} /> : user?.jobTitle}
        </p>
      </div>
    </div>
  )
}

function ThemeToggle({
  theme,
  setTheme,
}: {
  theme: string
  setTheme: (theme: ThemeT) => void
}) {
  const handleToggleTheme = () => {
    const nextTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(nextTheme)
    logEvent('select_theme', { theme: nextTheme })
  }
  return (
    <div className='flex items-center justify-between px-4 py-3 text-sm font-medium text-left border-t border-border-main text-text-secondary'>
      <div className='flex items-center'>
        <HiOutlineMoon
          className='w-5 h-5 mr-3 text-text-dimmed group-focus:text-text-tertiary group-hover:text-text-tertiary'
          aria-hidden='true'
        />
        Dark Mode
      </div>
      <Switch
        label={null}
        checked={theme === 'dark'}
        onChange={handleToggleTheme}
      />
    </div>
  )
}

function ResetAuthTokenButton() {
  const fetchAccessToken = useFetchAccessToken()
  const [refreshTokenLoading, setRefreshTokenLoading] =
    React.useState<boolean>(false)

  const handleResetAuthToken = async () => {
    setRefreshTokenLoading(true)
    await fetchAccessToken(true)
    queryClient.refetchQueries()
    setRefreshTokenLoading(false)
  }

  return (
    <button
      role='menuitem'
      onClick={handleResetAuthToken}
      className={clsx(
        'flex items-center justify-between w-full px-4 py-3 text-sm font-medium text-left border-t border-divider-main group text-text-secondary hover:bg-neutral-dimmed focus:bg-neutral-dimmed hover:text-text-primary focus:text-text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main',
      )}
    >
      <div className='flex items-center'>
        <HiOutlineKey
          className='w-5 h-5 mr-3 text-text-dimmed group-focus:text-text-tertiary group-hover:text-text-tertiary'
          aria-hidden='true'
        />
        Refresh Auth Token
      </div>
      {refreshTokenLoading && (
        <>
          <CgSpinner className='w-5 h-5 mr-1 animate-spin' />
          <span className='sr-only'>Refreshing Auth Token...</span>
        </>
      )}
    </button>
  )
}

function GetAccessTokenButton() {
  const [getAccessTokenLoading, setGetAccessTokenLoading] =
    React.useState<boolean>(false)
  const developerMode = useUiStore((state) => state.developerMode)

  const handleGetAccessToken = async () => {
    setGetAccessTokenLoading(true)
    try {
      const token = await getAccessToken()
      if (token) {
        navigator.clipboard.writeText(token)
      }
    } catch (err) {
      console.log(err)
    }
    setGetAccessTokenLoading(false)
  }

  if (!developerMode) return null

  return (
    <button
      role='menuitem'
      onClick={handleGetAccessToken}
      className={clsx(
        'flex items-center justify-between w-full px-4 py-3 text-sm font-medium text-left border-t border-divider-main group text-text-secondary hover:bg-neutral-dimmed focus:bg-neutral-dimmed hover:text-text-primary focus:text-text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main',
      )}
    >
      <div className='flex items-center'>
        <HiOutlineClipboardCopy
          className='w-5 h-5 mr-3 text-text-dimmed group-focus:text-text-tertiary group-hover:text-text-tertiary'
          aria-hidden='true'
        />
        Copy Access Token
      </div>
      {getAccessTokenLoading && (
        <>
          <CgSpinner className='w-5 h-5 mr-1 animate-spin' />
          <span className='sr-only'>Getting Access Token...</span>
        </>
      )}
    </button>
  )
}

function ResetInformationalDialogsButton() {
  const resetComplete = useInformationalDialogStore(
    (state) => state.resetComplete,
  )

  const handleResetInforamtionalDialogs = () => {
    resetComplete()
  }

  return (
    <button
      role='menuitem'
      onClick={handleResetInforamtionalDialogs}
      className={clsx(
        'flex items-center justify-between w-full px-4 py-3 text-sm font-medium text-left border-t border-divider-main group text-text-secondary hover:bg-neutral-dimmed focus:bg-neutral-dimmed hover:text-text-primary focus:text-text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main',
      )}
    >
      <div className='flex items-center'>
        <LuRedo2
          className='w-5 h-5 mr-3 text-text-dimmed group-focus:text-text-tertiary group-hover:text-text-tertiary'
          aria-hidden='true'
        />
        Reset Info Popups
      </div>
    </button>
  )
}

function LogOutButton({ logout }: { logout: () => void }) {
  return (
    <Menu.Item>
      <button
        role='menuitem'
        onClick={logout}
        className={clsx(
          'flex bg-neutral-dimmed-heavy items-center w-full px-4 py-3 text-sm font-medium text-left border-t border-divider-main rounded-b-md group text-helpers-error-main hover:bg-neutral-dimmed hover:text-helps-error-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-elements-primary-main',
        )}
      >
        <HiOutlineLogout
          className='w-5 h-5 mr-3 text-helpers-error-main group-hover:text-helpers-error-dark'
          aria-hidden='true'
        />
        Logout
      </button>
    </Menu.Item>
  )
}

const UserMenuDropDown = ({
  open = false,
  logout,
  className,
  user,
  userLoading,
  theme,
  setTheme,
}: PropsI) => {
  return (
    <Transition
      as={React.Fragment}
      show={open}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items static className={className}>
        <User user={user} userLoading={userLoading} />
        <ThemeToggle theme={theme} setTheme={setTheme} />
        <GetAccessTokenButton />
        <ResetAuthTokenButton />
        <ResetInformationalDialogsButton />
        <LogOutButton logout={logout} />
      </Menu.Items>
    </Transition>
  )
}

export default UserMenuDropDown
