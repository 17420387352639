import React from 'react'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
} from '@/components/ui/command'
import { FormOptionConfigI } from '@/config/types'
import { useVirtualizer } from '@tanstack/react-virtual'

import FiltersSelectionDropdownItem from '../FiltersSelectionDropdownItem'

type Option = {
  value: string
  label: string
  config?: FormOptionConfigI
}

interface FiltersSelectionCommandVirtualisedProps {
  options: Option[]
  multi?: boolean
  value: keyof FiltersI
}

export default function FiltersSelectionCommandVirtualised({
  options,
  multi,
  value,
}: FiltersSelectionCommandVirtualisedProps) {
  const [filteredOptions, setFilteredOptions] =
    React.useState<Option[]>(options)
  const parentRef = React.useRef(null)

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  })

  const virtualOptions = virtualizer.getVirtualItems()

  const handleSearch = (search: string) => {
    setFilteredOptions(
      options.filter((option) => {
        return (
          option.value.toLowerCase().includes(search.toLowerCase()) ||
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      }),
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault()
    }
  }

  return (
    <Command shouldFilter={false} onKeyDown={handleKeyDown}>
      <CommandInput
        autoFocus
        placeholder='Filter...'
        className='border-0 border-transparent rounded-none focus-visible:ring-0 focus:outline-none'
        onValueChange={handleSearch}
      />

      <CommandEmpty>No option found</CommandEmpty>
      <CommandGroup
        ref={parentRef}
        style={{
          height: '299px',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {virtualOptions.map((virtualOption) => (
            <FiltersSelectionDropdownItem
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualOption.size}px`,
                transform: `translateY(${virtualOption.start}px)`,
              }}
              formGroup={value}
              key={virtualOption?.key}
              value={filteredOptions[virtualOption.index]?.value}
              config={filteredOptions[virtualOption.index]?.config}
              isMulti={multi}
            />
          ))}
        </div>
      </CommandGroup>
    </Command>
  )
}
