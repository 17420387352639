import SlideOver from '@/components/SlideOver'
import { useEventStore } from '@/stores/event.store'

import EventItemPanelContent from './EventItemPanelContent'

const Event = () => {
  const open = useEventStore((state) => state.open)
  const storeEvent = useEventStore((state) => state.event)
  const onClose = useEventStore((state) => state.onClose)

  const { config, ...event } = storeEvent

  return (
    // Don't pass the event title to SlideOver, as we don't want the event title to be displayed at the top of the container, but under the event image instead
    <SlideOver title={''} open={open} onClose={onClose}>
      {/* The config is mandatory, don't render an event if the config is missing */}
      {config ? (
        <EventItemPanelContent
          event={event}
          config={config}
          open={open}
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </SlideOver>
  )
}

export default Event
