import create, { StateCreator } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

import { ClientT } from '@/config/types'
import { createEmptyTab } from '@/pages/MetricsExplorer/utils/createEmptyTab'

interface MetricsExplorerStateI {
  tabs: any
  activeTabIndex: any
  setClientTabs: (
    client: ClientT,
    funcOrValue:
      | MetricsExplorerTabI[]
      | ((tabs: MetricsExplorerTabI[]) => MetricsExplorerTabI[]),
  ) => void
  setClientActiveTabIndex: (
    client: ClientT,
    funcOrValue: number | ((currentIndex: number) => number),
  ) => void
}

type Persist = (
  config: StateCreator<MetricsExplorerStateI>,
  options: PersistOptions<MetricsExplorerStateI>,
) => StateCreator<MetricsExplorerStateI>

export const useMetricsExplorerStore = create<MetricsExplorerStateI>(
  (persist as Persist)(
    (set) => ({
      tabs: {
        'nbcu-dtc': [createEmptyTab(0)],
        'sky-europe': [createEmptyTab(0)],
        'sky-europe-and-peacock': [createEmptyTab(0)],
        now: [createEmptyTab(0)],
        nowott: [createEmptyTab(0)],
        peacock: [createEmptyTab(0)],
        skyshowtime: [createEmptyTab(0)],
        showmax: [createEmptyTab(0)],
      },
      activeTabIndex: {
        'nbcu-dtc': 0,
        'sky-europe': 0,
        'sky-europe-and-peacock': 0,
        now: 0,
        nowott: 0,
        peacock: 0,
        skyshowtime: 0,
        showmax: 0,
      },
      setClientTabs: (client, funcOrValue) => {
        set((state) => ({
          ...state,
          tabs: {
            ...state.tabs,
            [client]:
              typeof funcOrValue === 'function'
                ? funcOrValue(state.tabs[client])
                : funcOrValue,
          },
        }))
      },
      setClientActiveTabIndex: (client, funcOrValue) => {
        set((state) => ({
          ...state,
          activeTabIndex: {
            ...state.activeTabIndex,
            [client]:
              typeof funcOrValue === 'function'
                ? funcOrValue(state.activeTabIndex[client])
                : funcOrValue,
          },
        }))
      },
    }),
    {
      name: 'metrics-explorer',
      version: 2,
      migrate: (persistedState: MetricsExplorerStateI) => {
        return {
          ...persistedState,
          tabs: {
            ...persistedState.tabs,
            nowott:
              persistedState.tabs.nowott &&
              persistedState.tabs.nowott?.length > 0
                ? persistedState.tabs.nowott
                : [createEmptyTab(0)],
          },
          activeTabIndex: {
            ...persistedState.activeTabIndex,
            nowott: persistedState.activeTabIndex?.nowott || 0,
          },
        }
      },
    },
  ),
)
