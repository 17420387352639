import moment, { DurationInputArg1, DurationInputArg2 } from 'moment'
import queryString from 'query-string'

import {
  MetricSettingsI,
  NotificationsSettingsI,
  ReportSettingsI,
  SettingsI,
} from '@/config/types'

// const cleanseConfig = (_config, isTotal) => {
//   const config = { ..._config }

//   const day = moment(config?.start).format('YYYY-MM-DD')

//   config.day = day

//   delete config.start
//   delete config.end

//   if (isTotal) {
//     delete config['split-by']
//   }

//   return config
// }

const getComparisonDate = (date: any, compareVersus: [number, string]) => {
  if (compareVersus.length === 2) {
    return moment(date)
      .clone()
      .subtract(
        compareVersus[0] as DurationInputArg1,
        compareVersus[1] as DurationInputArg2,
      )
      .utc()
      .format()
  } else {
    return date
  }
}

const getUrl = ({
  settings,
  config,
  isTimeseries = false,
  extendApiDomain = (apiDomain) => apiDomain,
  compareVersus,
  cleanseConfig = (c) => c,
  metrics,
}: {
  settings:
    | MetricSettingsI
    | SettingsI
    | IncidentsSettingsI
    | ReportSettingsI
    | NotificationsSettingsI
  config: ConfigI
  isTimeseries?: boolean
  extendApiDomain?: (value: string) => string
  cleanseConfig?: (value: ConfigI) => ConfigI
  compareVersus?: [number, string] // [2, 'days']
  metrics?: MetricSettingsI[]
}) => {
  // Coerce types
  const typedSettings = settings as MetricSettingsI
  const typedConfig = config as ConfigI

  const { apiDomain, apiParam, group } = typedSettings
  const { metric } = typedConfig

  const resources = []
  const pathDirectories: {
    [key: string]: { values: string[]; domain?: string; domainIndex?: number }
  } = {}

  let cleanConfig: { [key: string]: any } = {}
  const metricParam =
    apiParam || metric || metrics?.map(({ value }) => value).join(',')

  let remove = ['dateType', 'relativeDate']

  if (isTimeseries) {
    if (typedSettings?.timeseriesAsParam) {
      cleanConfig['time-series'] = true
    } else {
      resources.push('timestamp')
    }
  }

  if (typedSettings?.useTimezone && typedSettings.timezone) {
    cleanConfig['timezone'] = typedSettings.timezone
  }

  if (typedSettings?.groupAsParam && group) {
    cleanConfig['metric-group'] = group.value
  }

  if (
    !typedSettings?.metricAsParam &&
    !typedSettings?.metricsAsDomainGroup &&
    metricParam
  ) {
    resources.push(metricParam)
    remove.push('metric')
  }

  if (typedSettings?.groupAsParam && group) {
    cleanConfig['metric-group'] = group.value
  }

  Object.keys(config).forEach((configKey) => {
    const configSettings = typedSettings?.form?.find(
      ({ value }) => value === configKey,
    )

    if (
      configKey === 'metric' &&
      typedSettings?.metricAsParam &&
      !typedSettings?.metricsAsDomainGroup
    ) {
      if (apiParam) {
        config.metric = apiParam
      }
    }

    const configValue = config[configKey as keyof ConfigI]

    if (configSettings && configSettings.options) {
      const selectedOptionSettingsList =
        configSettings?.options?.filter(
          ([value]) => configValue && configValue.indexOf(value) !== -1,
        ) || []

      selectedOptionSettingsList.forEach((selectedOptionSettings) => {
        // Check why only one option gets appended to path
        if (
          selectedOptionSettings.length > 1 &&
          selectedOptionSettings[1] &&
          selectedOptionSettings[1].url
        ) {
          const urlSettings = selectedOptionSettings[1].url

          if (urlSettings.remove) {
            remove = [...remove, ...urlSettings.remove]
          }
          if (urlSettings.attach === 'resource') {
            if (urlSettings.resourceGroup) {
              if (pathDirectories[urlSettings.resourceGroup]) {
                pathDirectories[urlSettings.resourceGroup] = {
                  ...pathDirectories[urlSettings.resourceGroup],
                  values: [
                    ...pathDirectories[urlSettings.resourceGroup].values,
                    urlSettings.formatter,
                  ],
                }
              } else {
                pathDirectories[urlSettings.resourceGroup] = {
                  values: [urlSettings.formatter],
                  domain: urlSettings.resourceGroupDomain,
                  domainIndex: urlSettings.resourceIndex,
                }
              }
            } else {
              resources.push(urlSettings.formatter || configValue)
            }
          }
          if (urlSettings.attach === 'param') {
            if (urlSettings.formatter) {
              cleanConfig = { ...cleanConfig, ...urlSettings.formatter }
            }
          }
        }
      })
    }

    if (!!configValue || configValue === 0) {
      if (Array.isArray(configValue) && configValue.indexOf('all') !== -1)
        return
      if (configValue.toString() === 'all') return
      if (Array.isArray(configValue)) {
        cleanConfig[configKey] = configValue.sort()
      } else {
        if (
          (configKey === 'start' || configKey === 'end') &&
          compareVersus?.length &&
          compareVersus.length > 0
        ) {
          cleanConfig[configKey] = getComparisonDate(configValue, compareVersus)
        } else {
          cleanConfig[configKey] = configValue
        }
      }
    }
  })

  const path = Object.keys(pathDirectories)
    .map((directory) => [
      pathDirectories[directory].values.join(','),
      pathDirectories[directory].domain,
      pathDirectories[directory].domainIndex,
    ])
    .sort((a, b) => {
      if (typeof a[2] === 'number' && typeof b[2] === 'number') {
        return a[2] - b[2]
      }
      return -1
    })
    .map((directoryGroup) =>
      directoryGroup[1]
        ? `${directoryGroup[1]}/${directoryGroup[0]}`
        : directoryGroup[0],
    )

  remove.forEach((key) => {
    if (cleanConfig[key]) {
      delete cleanConfig[key]
    }
  })

  const cleansedConfig = cleanseConfig(cleanConfig)

  const queryParams = queryString.stringify(cleansedConfig, {
    arrayFormat: 'comma',
  })

  const domain = extendApiDomain(apiDomain)

  let url = domain

  if (resources.length > 0) {
    url += `/${resources.join(',')}`
  }

  if (path.length > 0) {
    url += `/${path.join('/')}`
  }

  if (queryParams.length > 0) {
    url += `?${queryParams}`
  }

  return url
}

export default getUrl
