import { uniqBy } from 'lodash'

import { formOptions } from '@/config/formOptions'
import { FormI, FormOptionI } from '@/config/types'
import useMetricSettingsList from '@/hooks/useMetricSettingsList'
import { nonNullable } from '@/utils/nonNullable'

// Adds a new value to an existing collection, or initializes a new collection if one doesn't exist
export function pushValue(
  newValue: string,
  initialValue?: string | string[] | null | number | boolean,
) {
  return [initialValue, newValue].flat().filter(Boolean)
}

// On metric removal, remove filters that are no longer applicable to any tile on the page
export function removeUnavailableActiveFilters({
  availableFilters,
  activeFilters,
}: {
  availableFilters: FormI[]
  activeFilters?: FiltersI
}): FiltersI | undefined {
  if (!activeFilters) return {}

  return Object.fromEntries(
    Object.entries(activeFilters)
      .map(([group, filters]) => {
        const availableGroup = availableFilters.find(
          (filter) => filter.value === group,
        )
        if (!availableGroup) return null

        const filteredItems = (filters as string[]).filter((item) =>
          availableGroup.options.map((option) => option[0]).includes(item),
        )

        return filteredItems.length ? [group, filteredItems] : []
      })
      .filter(nonNullable),
  )
}

// Handles the filtering logic for a specific filter group (e.g. {proposition: ['now', 'sky-go']})
export function handleFilterGroup(
  filterGroup: string,
  filterValue: string[] | string,
  formGroup: FormI | undefined,
) {
  if (!formGroup) return { unapplied: { [filterGroup]: filterValue } }

  const validItems: string[] = []
  const invalidItems: string[] = []

  ;(Array.isArray(filterValue) ? filterValue : [filterValue]).forEach(
    (item) => {
      const optionExists = formGroup.options.some(
        (option) => option[0] === item,
      )
      optionExists ? validItems.push(item) : invalidItems.push(item)
    },
  )

  return {
    applied: validItems.length ? { [filterGroup]: validItems } : {},
    unapplied: invalidItems.length ? { [filterGroup]: invalidItems } : {},
  }
}

// For a given metric, get the applicable/unapplicable filters that are set at tab level
export function getApplicableMetricFilters({
  activeFilters = {},
  form,
}: {
  activeFilters?: FiltersI
  form: FormI[]
}) {
  return Object.entries(activeFilters).reduce(
    (acc, [filterGroup, filterValue]) => {
      const formGroup = form.find((group) => group.value === filterGroup)
      const { applied, unapplied } = handleFilterGroup(
        filterGroup,
        filterValue as string[],
        formGroup,
      )

      return {
        appliedFilters: { ...acc.appliedFilters, ...applied },
        unappliedFilters: { ...acc.unappliedFilters, ...unapplied },
      }
    },
    { appliedFilters: {} as FiltersI, unappliedFilters: {} as FiltersI },
  )
}

// Combines the form array within metricSettings with the fields in the /tags response
function useAllFormGroupsWithTags({
  metrics,
  tags,
}: {
  metrics?: string[]
  tags?: MetricsExplorerTagsI
}) {
  const metricsSettings = useMetricSettingsList({
    variants: { withClient: true },
  })

  return metricsSettings
    .filter(({ value }) => metrics?.includes(value))
    .flatMap(({ value, form = [] }) =>
      mergeFormWithTags({ metric: value, tags, form }),
    )
    .filter(({ value }) => value !== 'split-by')
}

function mergeDuplicateFormGroupsWithUniqueOptions(
  formGroups: FormI[],
): FormI[] {
  // Use a Map to merge form groups with the same value
  const mergedFormGroupMap = formGroups.reduce((acc, formGroup) => {
    const existingGroup = acc.get(formGroup.value)

    if (existingGroup) {
      // Merge options and remove any duplicates based on the first element of the option
      const mergedOptions = [...existingGroup.options, ...formGroup.options]
      const uniqueOptions = uniqBy(mergedOptions, (option) => option[0])

      existingGroup.options = uniqueOptions
    } else {
      acc.set(formGroup.value, formGroup)
    }

    return acc
  }, new Map())

  // Convert the Map values back to an array
  return Array.from(mergedFormGroupMap.values())
}

export function mergeFormWithTags({
  metric,
  tags,
  form,
}: {
  metric: string
  tags?: MetricsExplorerTagsI
  form: FormI[]
}) {
  if (!tags || !(metric in tags)) return form || []

  // Add Tag options into original form
  const metricTags = tags[metric]

  const mergedForm = Object.entries(metricTags.tags).map(
    ([tagGroupValue, tagGroupOptions]) => {
      const tagOptions = tagGroupOptions
        .filter((value) => !!value) // Ignore empty strings
        .map((option) => {
          const foundFormOptions = formOptions[tagGroupValue]

          // If formOption exists in formOptions/index, use that
          if (foundFormOptions && option in foundFormOptions)
            return foundFormOptions[option]

          // Else just create a basic default formOption with the string
          return [option, {}] as FormOptionI
        })

      const existingGroupIndex = form.findIndex(
        (formGroup) => formGroup.value === tagGroupValue,
      )

      // If form group exists, update it with tagOptions
      if (existingGroupIndex !== -1) {
        return {
          ...form[existingGroupIndex],
          options: tagOptions,
        }
      }

      // Otherwise, create a new form group
      return {
        value: tagGroupValue as keyof ConfigI,
        type: 'button-group',
        multi: true,
        options: tagOptions,
      }
    },
  )

  return mergedForm as FormI[]
}

// Hook for getting all available filters for a list of metrics (i.e. a tab)
export function useAvailableTabFilters({
  metrics,
  tags,
}: {
  metrics?: string[]
  tags?: MetricsExplorerTagsI
}): FormI[] {
  // Combine the hard coded forms in metricSettings with tags API responses
  const formGroupsWithTags = useAllFormGroupsWithTags({ metrics, tags })

  // Merge the form groups of each metric to remove duplicate options
  const mergedFormGroups =
    mergeDuplicateFormGroupsWithUniqueOptions(formGroupsWithTags)

  // Filter out form groups if they only have one option (e.g. ['all'], or ['skyshowtime'])
  const filteredFormGroups = mergedFormGroups.filter(
    (group) => group.options?.length !== 1,
  )

  // Sort merged form groups alphabetically by the 'value' key
  const sortedFormGroups = [...filteredFormGroups].sort((a, b) =>
    a.value.localeCompare(b.value),
  )

  // Also Sort the options alphabetically within each group
  const sortedOptionsGroups = sortedFormGroups.map((group) => ({
    ...group,
    options: [...group.options].sort((a, b) => a[0].localeCompare(b[0])),
  }))

  return sortedOptionsGroups
}
