import moment from 'moment-timezone'
import React from 'react'
import {
  HiDownload,
  HiOutlineInformationCircle,
  HiRefresh,
  HiShare,
} from 'react-icons/hi'
import InfiniteScroll from 'react-infinite-scroller'
import { useLocation, useNavigate } from 'react-router-dom'

import AnimatedButtonGroup from '@/components/ButtonGroup/AnimatedButtonGroup'
import XAxis from '@/components/Chart/XAxis'
import DevUrl from '@/components/DevUrl'
import DatePickerSwitch from '@/components/FilterPanel/components/DatePickerSwitch'
import InfoModalContent from '@/components/InfoModalContent'
import LastUpdatedTime from '@/components/LastUpdatedTime'
import Loading from '@/components/Loading'
import PageHeader from '@/components/PageHeader'
import ShareModalContent from '@/components/ShareModalContent'
import Status from '@/components/Status'
import relativeDates, { conviva2RelativeDates } from '@/config/relativeDates'
import { FormI } from '@/config/types'
import useCurrentMinute from '@/hooks/useCurrentMinute'
import useEventsData from '@/hooks/useEventsData'
import useEventsSettings from '@/hooks/useEventsSettings'
import { useClientStore } from '@/stores/client.store'
import { useEventStore } from '@/stores/event.store'
import { useModalStore } from '@/stores/modal.store'
import { useThemeStore } from '@/stores/theme.store'
import { useTimeStore } from '@/stores/time.store'
import { useUiStore } from '@/stores/ui.store'
import cleanseConfig from '@/utils/cleanseConfig'
import { cn } from '@/utils/cn'
import createUrlFromConfig from '@/utils/createUrlFromConfig'
import { logEvent } from '@/utils/firebaseAnalytics'
import flattenEventsData from '@/utils/flattenEventsData'
import getConfigFromUrl from '@/utils/getConfigFromUrl'
import getDateRangeFromConfig from '@/utils/getDateRangeFromConfig'
import getEventsConfigDateRange from '@/utils/getEventsConfigDateRange'
import getEventsConfigMetrics from '@/utils/getEventsConfigMetrics'
import getTimezoneLabel from '@/utils/getTimezoneLabel'
import getUniqueEventId from '@/utils/getUniqueEventId'

import Filters from '../Filters/Filters'
import { getFiltersFromConfig } from '../Filters/getFiltersFromConfig'
import { DiffBar } from './DiffBar'
import { EventItem } from './EventItem'
import LinearDisclaimer from './LinearDisclaimer'

// ** Plays metrics test: Adding `plays` to the quality events query is as a means to check wether old linear or sle events should display quality metrics.
// Instead of null, the %metrics(VPF VSF) might come as 0 and display 0 values, but if Plays is null, we know not to display anything

export const QUALITY_METRICS = [
  'connection-induced-rebuffering-ratio',
  'video-start-failures-technical-percentage',
  'video-playback-failures-technical-percentage',
]

let timeout: any

const AllEvents = () => {
  const theme = useThemeStore((state) => state.theme)
  const location = useLocation()
  const timezone = useTimeStore((state) => state.timezone)
  const client = useClientStore((state) => state.client)
  const [eventsPageDate, setEventsPageDate] = useEventStore((state) => [
    state.eventsPageDate,
    state.setEventsPageDate,
  ])
  const [eventsPageStreamType, setEventsPageStreamType] = useEventStore(
    (state) => [state.eventsPageStreamType, state.setEventsPageStreamType],
  )
  const [eventsPageFilters, setEventsPageFilters] = useEventStore((state) => [
    state.eventsPageFilters,
    state.setEventsPageFilters,
  ])

  const scrollParentRef = React.useRef(null)
  const navigate = useNavigate()

  const openModal = useModalStore((state) => state.openModal)
  const developerMode = useUiStore((state) => state.developerMode)
  const baseSettings = useEventsSettings({
    variants: {
      withClient: true,
    },
  })

  const vodSettings = {
    ...baseSettings,
    dates: conviva2RelativeDates[client],
  }

  const searchParamsConfig = getConfigFromUrl({
    metrics: [baseSettings],
    isEvents: true,
  })

  function getConfigFromStore(withFilters?: boolean) {
    let configFromStore: ConfigI = {}

    if (eventsPageStreamType[client]) {
      configFromStore['stream-type'] = eventsPageStreamType[client]
    }
    if (eventsPageDate[client]) {
      configFromStore = {
        ...configFromStore,
        ...eventsPageDate[client],
      }
    }
    if (eventsPageFilters[client] && withFilters) {
      configFromStore = {
        ...configFromStore,
        ...eventsPageFilters[client],
      }
    }
    return configFromStore
  }

  function getInitialConfig(withFilters?: boolean) {
    const isVod =
      searchParamsConfig['stream-type']?.includes('vod') ||
      baseSettings.defaultConfig['stream-type']?.includes('vod') ||
      eventsPageStreamType[client]?.includes('vod')

    const initialStoreOverrides = getConfigFromStore(withFilters)
    const initialSettings = isVod ? vodSettings : baseSettings

    const conviva2SupportedConfig = getEventsConfigDateRange({
      config: {
        ...initialSettings.defaultConfig,
        ...initialStoreOverrides,
        ...searchParamsConfig,
      },
      settings: initialSettings,
    })
    const initialConfig = getEventsConfigMetrics({
      settings: initialSettings,
      config: {
        ...conviva2SupportedConfig,
        metric: [...QUALITY_METRICS, 'plays'], // see  ** explanation at the top of the file
        ...initialStoreOverrides,
      },
    })
    return initialConfig
  }

  const [config, setConfig] = React.useState<ConfigI>(getInitialConfig(true))

  const isVod = config['stream-type']?.includes('vod')
  const isLinear = config['stream-type']?.includes('linear')
  const isSle = config['stream-type']?.includes('sle')
  const isNow = !!config['time-period']?.includes('now')

  const settings = isVod
    ? vodSettings
    : { ...baseSettings, dates: relativeDates[client] }

  const [diffBarHovered, setDiffBarHovered] = React.useState<{
    enabled: boolean
    start: any
    end: any
  }>()
  const [silentRefresh, setSilentRefresh] = React.useState(false)

  const currentMinute = useCurrentMinute()

  const onUpdateConfig = (updatedConfig: ConfigI, isSilent = false) => {
    const nextConfig = { ...config, ...updatedConfig }

    if (!isSilent) {
      events.remove()
    }
    const { start, end } = getDateRangeFromConfig(nextConfig)
    setSilentRefresh(isSilent)
    setEventsPageFilters(client, getFiltersFromConfig(nextConfig, settings))
    setConfig({ ...nextConfig, start, end })
  }

  const onUpdateLocalConfig = (updatedConfig: ConfigI, delay = 0) => {
    const nextConfig = { ...config, ...updatedConfig }
    setConfig(nextConfig)

    clearTimeout(timeout)
    timeout = null
    timeout = setTimeout(() => {
      onUpdateConfig(nextConfig)
    }, delay)
  }

  const cleansedConfig = React.useMemo(() => cleanseConfig(config), [config])

  const { events, url } = useEventsData({
    key: 'events',
    config: cleansedConfig,
    reactQueryProps: {
      refetchInterval: false,
    },
    variants: {
      withClient: true,
    },
  })

  // Change metrics on vod change
  React.useEffect(() => {
    const initialSettings = isVod ? vodSettings : baseSettings
    let storedCustomDate = {}

    if (
      // If a date is saved in the store
      eventsPageDate[client] &&
      // And it's a custom selection
      eventsPageDate[client].relativeDate.id === 'custom' &&
      // And the date is not now
      !isNow
    ) {
      // Save the stored date to reuse
      storedCustomDate = eventsPageDate[client]
    }

    const conviva2SupportedConfig = getEventsConfigDateRange({
      config: {
        ...initialSettings.defaultConfig,
        ...searchParamsConfig,
        ...config,
      },
      settings: initialSettings,
    })
    const newConfig = getEventsConfigMetrics({
      settings: initialSettings,
      config: {
        ...conviva2SupportedConfig,
        metric: [...QUALITY_METRICS, 'plays'], // see  ** explanation at the top of the file
        ...storedCustomDate,
      },
    })

    setConfig(newConfig)

    setEventsPageFilters(client, getFiltersFromConfig(newConfig, settings))
  }, [isVod, isNow])

  React.useEffect(() => {
    if (events?.data?.pages && events?.data?.pages?.length <= 1) {
      const { start, end } = getDateRangeFromConfig(config)
      if (start !== config?.start || end !== config?.end) {
        onUpdateConfig({ start, end }, true)
      }
    }
  }, [currentMinute])

  // Location effects  - not sure if still needed
  React.useEffect(() => {
    setSilentRefresh(false)
  }, [location])

  React.useEffect(() => {
    navigate(location.pathname)
  }, [navigate, location.pathname])

  const streamTypesButtons = getsStreamTypesButtons({
    selected:
      eventsPageStreamType[client] || typeof config['stream-type'] === 'string'
        ? config['stream-type']
        : config['stream-type'][0],
    onSelect: (value) => {
      onUpdateConfig({ 'stream-type': value })
      setEventsPageStreamType(client, value)
      logEvent('select_filter', {
        filterName: 'streamType',
        streamType: value,
      })
    },
    settings,
  })

  const showFilters =
    settings.form.filter((formGroup) => formGroup.type !== 'hidden').length > 0

  const filtersContext = React.useMemo(
    () => ({
      activeFilters: eventsPageFilters[client],
      availableFilters: settings.form.filter(
        (formGroup) => formGroup.type !== 'hidden',
      ),
      updateFilters: ({
        formGroup,
        isMulti,
        value,
      }: {
        formGroup: FormI['value']
        value
        checked
        isMulti
      }) => {
        // HOW TO update config with a single filter update

        let updatedValues

        if (isMulti) {
          const existingValues = [
            ...((config[formGroup as keyof ConfigI] as string[]) || []),
          ]

          if (existingValues.includes(value)) {
            updatedValues = existingValues.filter((v) => v !== value)
            if (updatedValues.length === 0) {
              updatedValues = ['all']
            }
          } else {
            updatedValues = [
              ...existingValues.filter((v) => v !== 'all'),
              value,
            ]
          }
        } else {
          updatedValues = value
        }
        if (
          JSON.stringify(updatedValues) !== JSON.stringify(config[formGroup])
        ) {
          onUpdateConfig({ [formGroup]: updatedValues }, false)
        }
      },
      deleteFilters: () => {
        onUpdateConfig(getInitialConfig(false))
        setEventsPageFilters(client, {})
      },
    }),
    [eventsPageFilters, client],
  )

  return (
    <main className='flex flex-1 overflow-hidden'>
      <div className='flex flex-col flex-1 w-full'>
        <PageHeader
          className='w-full px-2 py-1 border-b bg-neutral border-divider-main'
          childrenAsTitle={true}
          buttons={[
            {
              label: 'Refresh',
              Icon: HiRefresh,
              onClick: () => {
                setSilentRefresh(false)
                events.remove()
                events.refetch()
                logEvent('select_refresh')
              },
            },
            {
              label: 'Info',
              Icon: HiOutlineInformationCircle,
              onClick: () => {
                openModal({
                  title: `${settings?.label} Information`,
                  description: (
                    <InfoModalContent
                      settings={settings}
                      config={config}
                      isEvent
                    />
                  ),
                  appearance: 'info',
                })
                logEvent('select_info')
              },
            },
            {
              label: 'Share',
              Icon: HiShare,
              onClick: () => {
                const shareUrl = createUrlFromConfig(config, settings)
                openModal({
                  title: 'Share',
                  description: <ShareModalContent url={shareUrl} />,
                  appearance: 'info',
                })
                logEvent('select_share')
              },
            },
            {
              label: 'Download',
              Icon: HiDownload,
              onClick: () => {
                const data = flattenEventsData(events?.data)
                const csvData = convertEventDataToCSV(data, timezone)
                if (csvData === '') {
                  alert('No data to export')
                } else {
                  // Create CSV file and initiate download
                  const blob = new Blob([csvData], {
                    type: 'text/csv;charset=utf-8;',
                  })
                  const link = document.createElement('a')
                  link.href = URL.createObjectURL(blob)
                  link.setAttribute('download', `events_data.csv`)
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
                logEvent('select_download')
              },
            },
          ]}
        >
          <div className='flex items-center w-full space-x-4'>
            {streamTypesButtons.length > 1 && (
              <AnimatedButtonGroup
                buttons={streamTypesButtons}
                name='events-stream-type'
              />
            )}
            {isLinear && (
              <LinearDisclaimer
                theme={theme}
                client={client}
                onClick={() => {
                  openModal({
                    title: `${settings?.label} Information`,
                    description: (
                      <InfoModalContent
                        settings={settings}
                        config={config}
                        isEvent
                      />
                    ),
                    appearance: 'info',
                  })
                  logEvent('click_disclaimer')
                }}
              />
            )}
          </div>
        </PageHeader>
        {/* filters and datepicker */}

        <div
          className={cn(
            'px-2 lg:px-4 2xl:px-12 z-20 flex flex-row flex-wrap-reverse md:flex-nowrap items-center justify-between py-3  backdrop-blur  bg-neutral-dimmed/75',
          )}
        >
          <div className='flex flex-col items-start justify-start flex-grow w-full mt-2 space-y-2 md:mt-0 md:space-y-0 md:space-x-4 md:items-center md:justify-between md:flex-row'>
            {showFilters && <Filters filtersContext={filtersContext} />}
          </div>
          <div className='relative z-50 flex items-center justify-end flex-shrink-0 w-auto space-x-2'>
            <DatePickerSwitch
              tabs={{
                QUICK_RANGES: {
                  VALUE: 'quick-ranges',
                  LABEL: 'Quick Ranges',
                },
                CUSTOM_RANGE: {
                  VALUE: 'custom-range',
                  LABEL: 'Custom Range',
                },
              }}
              relative={true}
              config={config}
              dates={settings.dates}
              onUpdateLocalConfig={(value, delay) => {
                onUpdateLocalConfig(value, delay)
                setEventsPageDate(client, value)
              }}
              buttonClassName='min-w-fit flex-shrink-0 bg-transparent'
              showCustomDateRangeDisclaimer={isVod}
            />
          </div>
        </div>

        <section className='flex flex-col flex-1 overflow-hidden border-t border-divider-main'>
          {developerMode && <DevUrl url={url} />}

          <Status
            {...events}
            isEmpty={flattenEventsData(events?.data).length === 0}
            silentRefresh={silentRefresh}
            loadingMessage={`We're aggregating through millions of events, so this can take a while...`}
            comingSoon={baseSettings?.comingSoon}
            comingSoonMessage='Events data is coming soon'
            comingSoonSubMessage="We're in the process of integrating Merlin data to provide this feature"
          >
            <div className='flex justify-center flex-1 overflow-hidden'>
              <div
                className='flex-1 w-full overflow-y-auto xl:px-12 bg-neutral-dimmed'
                ref={scrollParentRef}
              >
                <div className='mx-auto max-w-7xl'>
                  <div className='sticky top-0 z-20 px-1 py-3 space-y-3 text-sm font-medium border-b text-text-tertiary border-divider-main bg-neutral-dimmed'>
                    {(isSle || isLinear) && (
                      <div className='hidden sm:block'>
                        <XAxis
                          range={[
                            Number(moment(config.start).unix()) * 1000,
                            Number(moment(config.end).unix()) * 1000,
                          ]}
                          interval={16}
                        />
                        <div className='absolute left-0 z-10 w-full px-1 top-1'>
                          <DiffBar
                            start={config.start}
                            end={config.end}
                            itemStart={diffBarHovered?.start}
                            itemEnd={diffBarHovered?.end}
                            enabled={diffBarHovered?.enabled}
                            animate
                            bgClassName='bg-neutral-dimmed'
                          />
                        </div>
                      </div>
                    )}

                    <div className='relative grid grid-cols-12 gap-4 sm:px-4'>
                      <div className='col-span-3 2xl:col-span-2'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Rank
                        </p>
                      </div>
                      <div className='col-span-3'>
                        <p className='text-xs font-semibold tracking-wider uppercase text-text-tertiary'>
                          Event
                        </p>
                      </div>
                      <div className='col-span-6 pb-3 2xl:col-span-7'>
                        <p className='text-xs font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                          {isVod && !isNow ? 'Plays' : 'Peak concurrency'}
                        </p>
                        {!isVod && (
                          <p className='text-xs font-semibold tracking-wider text-right uppercase text-text-tertiary'>
                            and quality
                          </p>
                        )}
                        <LastUpdatedTime
                          isRefreshing={
                            events.isLoading ||
                            events.isFetching ||
                            events.isRefetching
                          }
                          timestamp={events.dataUpdatedAt}
                          containerClasses='absolute right-0  sm:right-4 -bottom-1.5  text-text-tertiary opacity-80'
                        />
                      </div>
                    </div>
                  </div>

                  <ol className='py-4 pt-24 -mt-24 overflow-hidden divide-y shadow divide-divider-main rounded-xl bg-neutral-dimmed-heavy'>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={() => {
                        if (
                          events.hasNextPage &&
                          !events.isLoading &&
                          !events.isFetching &&
                          !events.isFetchingNextPage &&
                          !events.isError
                        ) {
                          events.fetchNextPage()
                        }
                      }}
                      hasMore={events.hasNextPage}
                      loader={
                        <div className='justify-center w-full py-8' key={0}>
                          <Loading
                            includeLogo={false}
                            width={64}
                            lightTheme={false}
                          />
                        </div>
                      }
                      threshold={24}
                      useWindow={false}
                      getScrollParent={() => scrollParentRef.current}
                    >
                      {flattenEventsData(events?.data)?.map((event, index) => {
                        const id = getUniqueEventId(event)
                        return (
                          <EventItem
                            key={id}
                            id={id}
                            event={event}
                            index={index}
                            config={config}
                            settings={settings}
                            setDiffBarHovered={setDiffBarHovered}
                          />
                        )
                      })}
                    </InfiniteScroll>
                  </ol>
                </div>
              </div>
            </div>
          </Status>
        </section>
      </div>
    </main>
  )
}

export default AllEvents

function getsStreamTypesButtons({ selected, onSelect, settings }) {
  return settings?.form
    ?.find((formGroup) => formGroup.value === 'stream-type')
    .options.map(([value, optionConfig]) => {
      const { icon, label } = optionConfig

      return {
        value,
        label: label,
        onClick: () => {
          onSelect(value)
          logEvent('select_filter', {
            filterName: 'streamType',
            streamType: value,
          })
        },
        selected,
        Icon: icon,
      }
    })
}
// Function to convert Events JSON to CSV format
function convertEventDataToCSV(jsonData: Conviva2EventI[], timezone?: string) {
  const columnHeaders = [
    'Start',
    'End',
    'Title',
    'Genre',
    'Viewership',
    'VSF-T(%)',
    'VPF-T(%)',
    'CIRR',
  ]

  // Check if JSON data is empty
  if (jsonData.length === 0) {
    return ''
  }

  // Create headers string
  const headers = columnHeaders.join(',') + '\n'

  // Map JSON data to CSV rows
  const rows = jsonData
    .map((row) => {
      // Map each row to CSV format
      return columnHeaders
        .map((columnHeader) => {
          switch (columnHeader) {
            case 'Start':
              return row.meta.startTimeEpochUTC
                ? moment(row.meta.startTimeEpochUTC)
                    .tz(timezone)
                    .format('YYYY-MM-DD HH:mm') +
                    getTimezoneLabel(row.meta.startTimeEpochUTC, timezone)
                : row.meta.rightsStartTimeEpochUTC
                ? moment(row.meta.rightsStartTimeEpochUTC)
                    .tz(timezone)
                    .format('YYYY-MM-DD HH:mm') +
                  getTimezoneLabel(row.meta.rightsStartTimeEpochUTC, timezone)
                : ''

            case 'End':
              return row.meta.endTimeEpochUTC
                ? moment(row.meta.endTimeEpochUTC)
                    .tz(timezone)
                    .format('YYYY-MM-DD HH:mm') +
                    getTimezoneLabel(row.meta.endTimeEpochUTC, timezone)
                : row.meta.rightsEndTimeEpochUTC
                ? moment(row.meta.rightsEndTimeEpochUTC)
                    .tz(timezone)
                    .format('YYYY-MM-DD HH:mm') +
                  getTimezoneLabel(row.meta.rightsEndTimeEpochUTC, timezone)
                : ''

            case 'Viewership':
              return row?.concurrentPlays
                ? 'Concurrency: ' + row.concurrentPlays
                : row?.plays
                ? 'Plays: ' + row.plays
                : ''
            case 'VSF-T(%)':
              return row?.videoStartFailuresTechnicalPercentage !== null
                ? new Intl.NumberFormat('en-GB', {
                    unit: 'percent',
                    style: 'unit',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(row?.videoStartFailuresTechnicalPercentage)
                : ''
            case 'VPF-T(%)':
              return row?.videoPlaybackFailuresTechnicalPercentage !== null
                ? new Intl.NumberFormat('en-GB', {
                    unit: 'percent',
                    style: 'unit',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(row?.videoPlaybackFailuresTechnicalPercentage)
                : ''
            case 'CIRR':
              return row?.connectionInducedRebufferingRatio !== null
                ? new Intl.NumberFormat('en-GB', {
                    unit: 'percent',
                    style: 'unit',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(row?.connectionInducedRebufferingRatio)
                : ''
            case 'Title':
              return row.meta.title ? `"${row.meta.title}"` : ''
            default:
              return row.meta[columnHeader.toLowerCase()] || ''
          }
        })
        .join(',')
    })
    .join('\n')

  // Combine headers and rows
  return headers + rows
}
