import { convivaStyleDataFormattersMultipleMetrics } from '@/config/metricsSettings/dataFormatterHelpers'
import { getNowRounded } from '@/config/relativeDates/peacock'
import { ReportSettingsI } from '@/config/types'

const now = getNowRounded() // now with Peacock Default timezone

function getStartAndEndConfig() {
  const start = now.clone().startOf('day').format()
  const end = now.clone().format()

  return { start, end }
}

// TODO - see if this will be necessary
const subscriberReportPageSettings: ReportSettingsI = {
  apiDomain: 'whateverApiDomain',
  form: [],
  formatter: convivaStyleDataFormattersMultipleMetrics,
  defaultConfig: {
    client: 'peacock',
    ...getStartAndEndConfig(),
  },
}

export default Object.freeze(subscriberReportPageSettings)
