import { startCase } from 'lodash'
import React from 'react'

import { Badge } from '@/components/ui/badge'
import { useFilters } from '@/pages/MetricsExplorer/contexts/filters-context'

interface FilterGroupTitleItemProps {
  label?: string
  value: keyof FiltersI
}

const FilterGroupTitleItem = React.forwardRef<
  HTMLDivElement,
  FilterGroupTitleItemProps
>(({ label, value }, ref) => {
  const { activeFilters } = useFilters()
  const selectedFiltersLength =
    typeof activeFilters?.[value] === 'string' ||
    typeof activeFilters?.[value] === 'number'
      ? 1
      : (activeFilters?.[value] as string[])?.length

  return (
    <div
      ref={ref}
      className='flex items-center justify-between flex-1 h-5 mr-2 space-x-2'
    >
      <span> {label ?? startCase(value)} </span>
      {selectedFiltersLength > 0 && (
        <Badge className='py-0.5'>{selectedFiltersLength}</Badge>
      )}
    </div>
  )
})

export default FilterGroupTitleItem
