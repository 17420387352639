import {
  PickerProps,
  RangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment'
import { ReactNode, useState } from 'react'
import { HiOutlineExclamation } from 'react-icons/hi'

import DatePicker from '@/components/DatePicker'
import DatePickerBusiestDays from '@/components/DatePickerWithTabs/DatePickerBusiestDays/DatePickerBusiestDays'
import DatePickerCustomRange from '@/components/DatePickerWithTabs/DatePickerCustomRange/DatePickerCustomRange'
import DatePickerPopoverTrigger from '@/components/DatePickerWithTabs/DatePickerPopoverTrigger'
import DatePickerQuickRanges from '@/components/DatePickerWithTabs/DatePickerQuickRanges/DatePickerQuickRanges'
import ListBox from '@/components/ListBox'
import { Popover, PopoverContent } from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import useTheme from '@/hooks/useTheme'
import { cn } from '@/utils/cn'

interface DatePickerTabsI {
  [key: string]: {
    VALUE: string
    LABEL: string
  }
}

interface DatePickerSwitchProps {
  relative?: boolean
  tabs?: DatePickerTabsI
  dates?: any[]
  config: ConfigI
  onUpdateLocalConfig: (value: any, delay: number) => void
  datePickerProps?: PickerProps<Moment> | RangePickerProps<Moment>
  showCustomDateRangeDisclaimer?: boolean
  buttonClassName?: string
  listBoxClassName?: string
}

function DatePickerCustomQuickRangeTabsList({
  tabs,
}: {
  tabs: DatePickerTabsI
}) {
  return (
    <div className='sticky top-0 z-20 py-2 bg-neutral-dimmed-heavy/75 backdrop-blur'>
      <TabsList
        className={cn('grid w-full', {
          'grid-cols-2': Object.keys(tabs).length === 2,
          'grid-cols-3': Object.keys(tabs).length === 3,
          'grid-cols-4': Object.keys(tabs).length === 4,
        })}
      >
        {Object.keys(tabs).map((tab) => (
          <TabsTrigger key={tab} value={tabs[tab].VALUE}>
            {tabs[tab].LABEL}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  )
}

function DatePickerPanel({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <ScrollArea>
      <div className={cn(className, 'w-full md:w-72')}>{children}</div>
    </ScrollArea>
  )
}

const DatePickerSwitch = ({
  relative,
  tabs,
  dates,
  config,
  onUpdateLocalConfig,
  datePickerProps = {},
  showCustomDateRangeDisclaimer = false,
  buttonClassName,
  listBoxClassName,
}: DatePickerSwitchProps) => {
  const theme = useTheme()!

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const removeDates = config?.removeDates || []

  const handleDatePickerChange = (date: any) => {
    onUpdateLocalConfig(
      {
        ...date,
        'time-period': null,
        relativeDate: dates.find((d) => d.id === 'custom'),
      },
      300,
    )
  }
  const handleRelativeDateUpdate = (relativeDate: RelativeDateI) => {
    const _config: ConfigI = {
      relativeDate,
    }

    if (relativeDate?.id === 'custom') {
      _config.dateType = 'datetime'
      _config['time-period'] = null

      if (config['stream-type']?.includes('vod')) {
        // If the stream type is vod, we won't have a start/end in config to use as our initial date
        // We want to create a sensible start/end using getStartEndParams from conviva2RelativeDates

        if (config?.relativeDate?.getStartEndParams) {
          const dateParams = config?.relativeDate?.getStartEndParams()

          if (dateParams && 'start' in dateParams) {
            _config.start = dateParams?.start.clone().utc().format()
          }
          if (dateParams && 'end' in dateParams) {
            _config.end = dateParams?.end.clone().utc().format()
          }
        }
      }
    } else {
      const dateParams = relativeDate?.getDateRange()

      if (dateParams && 'start' in dateParams) {
        _config.start = dateParams.start.clone().utc().format()
      }
      if (dateParams && 'end' in dateParams) {
        _config.end = dateParams.end.clone().utc().format()
      }
      if (dateParams['time-period']) {
        _config['time-period'] = dateParams['time-period']
      }
      _config.dateType = 'relative'
    }
    onUpdateLocalConfig(_config, 0)
  }

  if (tabs) {
    return (
      <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)}>
        <DatePickerPopoverTrigger
          config={config}
          buttonClassName={buttonClassName}
        />

        <PopoverContent
          style={{ maxHeight: 500 }}
          className={cn(
            'flex flex-shrink-0 w-full p-0 overflow-hidden divide-x divide-divider-main ',
            listBoxClassName,
          )}
        >
          <DatePickerPanel>
            <div className='px-2 pb-4'>
              <Tabs
                defaultValue={
                  config.relativeDate?.id === 'custom' && !!tabs['CUSTOM_RANGE']
                    ? tabs['CUSTOM_RANGE'].VALUE
                    : Object.values(tabs)[0].VALUE
                }
              >
                <DatePickerCustomQuickRangeTabsList tabs={tabs} />
                <div className='px-2 pb-2'>
                  {Object.keys(tabs).map((tab) => (
                    <TabsContent key={tab} value={tabs[tab].VALUE}>
                      {tab === 'QUICK_RANGES' && (
                        <DatePickerQuickRanges
                          relativeDates={dates.filter(
                            (date) =>
                              removeDates.findIndex(
                                (dateToRemove) => dateToRemove === date.id,
                              ) === -1,
                          )}
                          config={config}
                          onUpdateConfig={(relativeDate) => {
                            handleRelativeDateUpdate(relativeDate)
                            setPopoverOpen(false)
                          }}
                        />
                      )}
                      {tab === 'CUSTOM_RANGE' && (
                        <DatePickerCustomRange
                          config={config}
                          onUpdateConfig={(nextConfig) => {
                            handleDatePickerChange(nextConfig)
                          }}
                        />
                      )}
                      {tab === 'BUSIEST_DAYS' && (
                        <DatePickerBusiestDays
                          config={config}
                          onUpdateConfig={(nextConfig) => {
                            handleDatePickerChange(nextConfig)
                            setPopoverOpen(false)
                          }}
                        />
                      )}
                    </TabsContent>
                  ))}
                </div>
              </Tabs>
            </div>
          </DatePickerPanel>
        </PopoverContent>
      </Popover>
    )
  } else if (!tabs && relative) {
    return (
      <>
        <ListBox
          listBoxClassName={listBoxClassName}
          buttonClassName={buttonClassName}
          label='Date Picker'
          labelHidden
          value={config?.relativeDate}
          options={dates.filter(
            (date) =>
              removeDates.findIndex(
                (dateToRemove) => dateToRemove === date.id,
              ) === -1,
          )}
          onChange={handleRelativeDateUpdate}
        />
        {config?.dateType === 'datetime' && (
          <div className=''>
            <DatePicker
              selected={{
                start: config?.start,
                end: config?.end,
                dateType: 'datetime',
                isRange: true,
              }}
              onChange={handleDatePickerChange}
              datePickerProps={datePickerProps}
            />
            {showCustomDateRangeDisclaimer && (
              <div
                className={cn(
                  'flex items-center px-2.5 py-3 mt-2 text-xs font-regular border-l-2 border-amber-500 bg-amber-500/10',
                  {
                    'text-amber-700': theme.type === 'light',
                    'text-amber-400': theme.type === 'dark',
                  },
                )}
              >
                <HiOutlineExclamation className='flex-shrink-0 w-4 h-4 mr-2.5' />
                Custom date ranges can be slow, especially over long windows
              </div>
            )}
          </div>
        )}
      </>
    )
  } else {
    return (
      <DatePicker
        selected={{
          start: config?.start,
          end: config?.end,
          dateType: config?.dateType,
          isRange: config?.isRange,
        }}
        onChange={handleDatePickerChange}
        datePickerProps={datePickerProps}
      />
    )
  }
}

export default DatePickerSwitch
