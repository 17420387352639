import axios from 'axios'
import queryString from 'query-string'
import * as React from 'react'
import { QueryFunctionContext, UseQueryResult, useQueries } from 'react-query'

import { ClientT, MetricSettingsI } from '@/config/types'
import useMetricSettingsList from '@/hooks/useMetricSettingsList'
import { useMetricsExplorerTabs } from '@/pages/MetricsExplorer/hooks/useMetricsExplorerTabs'
import { useClientStore } from '@/stores/client.store'
import getAccessToken from '@/utils/getAccessToken'
import { nonNullable } from '@/utils/nonNullable'

const getTagsUrl = (client: ClientT, metricSettings?: MetricSettingsI) => {
  const tagsQueryParams = metricSettings?.tags?.params
    ? { ...metricSettings.tags.params }
    : {}

  if (!metricSettings) return

  const tagsApiDomain = metricSettings.apiDomain
  const tagsEndpoint = metricSettings.tags?.endpoint ?? '/tags'

  if (!tagsApiDomain || !tagsEndpoint) return

  if (client) {
    tagsQueryParams.client = client
  }

  const queryStringified = queryString.stringify(tagsQueryParams)

  let url = `${tagsApiDomain}${tagsEndpoint}`

  if (queryStringified) {
    url += `?${queryStringified}`
  }

  return url
}

const fetchTags = async ({ queryKey }: QueryFunctionContext) => {
  const { metricSettings, client } = queryKey![1] as any

  const url = getTagsUrl(client, metricSettings)

  if (!url) return

  try {
    const token = await getAccessToken()

    const response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    if (!response) {
      throw new Error('Unknown Error')
    }

    return response.data
  } catch (error) {
    // Don't throw a full error for cancelledErrors, creates too much noise in logging
    if (axios.isAxiosError(error) && error.name === 'CanceledError') {
      return null
    }
    console.error(error)
    throw error
  }
}

export function useMetricsExplorerTagsData() {
  const client = useClientStore((state) => state.client)
  const { activeTab } = useMetricsExplorerTabs()

  const metricSettingsList = useMetricSettingsList({
    variants: { withClient: true },
  })

  const tabMetrics = activeTab.tiles
    .filter((tile) => typeof tile.metric === 'string')
    .map((tile) => tile.metric) as string[]

  const uniqueMetrics = [...new Set(tabMetrics)]

  const uniqueMetricsWithSettings = uniqueMetrics
    .map((metric) => {
      const metricSettings = metricSettingsList.find(
        ({ value }) => value === metric,
      )
      return metricSettings
    })
    .filter((metricSettings) => {
      const tagsEnabled = metricSettings?.tags?.enabled
      return !!metricSettings && tagsEnabled
    })
    .filter(nonNullable)

  const queries = useQueries(
    uniqueMetricsWithSettings.map((metricSettings: MetricSettingsI) => {
      return {
        queryKey: [
          `tags-${metricSettings.value}`,
          {
            metricSettings,
            client,
          },
        ],
        queryFn: fetchTags,
        refetchInterval: 86400000, // 1 day
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    }),
  ) as UseQueryResult<TagsI, Error>[]

  const tags = React.useMemo(() => {
    return queries.reduce<MetricsExplorerTagsI>((acc, query, index) => {
      const metric = uniqueMetricsWithSettings[index].value
      acc[metric] = {
        tags: query.data || {},
        isLoading: query.isLoading,
        isError: query.isError,
        error: query.error,
      }
      return acc
    }, {})
  }, [queries])

  return tags
}
