/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const _colors = require('tailwindcss/colors')
const tinycolor = require('tinycolor2')

const getFullPalette = require('../utils/getFullPalette')
const peacockPallette = require('../utils/peacockPallette')

colors = { ..._colors, ...peacockPallette }
// Palette
const primary = 'supernova'
const secondary = 'orange'
const success = 'green'
const error = 'red'
const info = 'blue'
const warning = 'orange'

const neutral = 'zinc'

const dark = {
  value: 'peacock-dark',
  type: 'dark',
  label: 'Peacock Dark',
  selectors: ['.peacock-dark'],
  theme: {
    chartColors: [
      // Initial set of chart colors, always use tailwind colors to avoid duplicates
      colors.zinc['500'],
      colors.supernova['500'],
      colors.pumpkin['500'],
      colors.redribbon['500'],
      colors.curiousblue['500'],
      colors.uranium['500'],
      colors.fuchsiablue['500'],
    ],
    colors: {
      // Primary and Secondary colours
      'elements-primary-dimmed': colors[primary]['300'],
      'elements-primary-main': colors[primary]['400'],
      'elements-primary-shadow': colors[primary]['500'],
      'elements-primary-highlight': colors[primary]['200'],
      'elements-primary-contrastText': '#191919',
      ...getFullPalette('elements-primary', primary),

      // Secondary
      'elements-secondary-dimmed': colors[secondary]['400'],
      'elements-secondary-main': colors[secondary]['500'],
      'elements-secondary-shadow': colors[secondary]['600'],
      'elements-secondary-highlight': colors[secondary]['300'],
      'elements-secondary-contrastText': '#ffffff',
      ...getFullPalette('elements-secondary', secondary),

      // Homepage realtime gradient
      'headline-gradient-from': 'rgb(0, 0, 0)',
      'headline-gradient-via': 'rgb(0, 0, 0)',
      'headline-gradient-to': 'rgb(0, 0, 0)',

      'event-backdrop': 'rgb(0, 0, 0)',

      // Alert
      'helpers-error-dimmed': colors[neutral]['700'],
      'helpers-error-main': colors[error]['400'],
      'helpers-error-button': colors[error]['500'],
      'helpers-error-button-hover': colors[error]['400'],
      'helpers-error-contrastText': '#ffffff',

      'helpers-warning-dimmed': colors[neutral]['700'],
      'helpers-warning-main': colors[warning]['400'],
      'helpers-warning-button': colors[warning]['500'],
      'helpers-warning-button-hover': colors[warning]['400'],

      'helpers-success-dimmed': colors[neutral]['700'],
      'helpers-success-main': colors[success]['400'],
      'helpers-success-button': colors[success]['500'],
      'helpers-success-button-hover': colors[success]['400'],

      'helpers-info-dimmed': colors[neutral]['700'],
      'helpers-info-main': colors[info]['400'],
      'helpers-info-button': colors[info]['500'],
      'helpers-info-button-hover': colors[info]['400'],

      // Custom Brand colours (e.g. Sky palette, Peacock palette)
      'brand-1': '#ffffff',

      // Text
      'text-primary': '#ffffff',
      'text-secondary': colors[neutral]['300'],
      'text-tertiary': colors[neutral]['400'],
      'text-dimmed': colors[neutral]['500'],
      'text-disabled': colors[neutral]['600'],

      // Neutral
      'divider-dimmed': colors[neutral]['700'],
      'divider-main': colors[neutral]['600'],
      'divider-shadow': colors[neutral]['500'],

      'border-dimmed': colors[neutral]['700'],
      'border-main': colors[neutral]['600'],
      'border-shadow': colors[neutral]['500'],

      'neutral-dimmed-heavy': tinycolor(colors[neutral]['800'])
        .lighten(5)
        .toString(),
      'neutral-dimmed': tinycolor(colors[neutral]['800'])
        .lighten(2.5)
        .toString(),
      neutral: colors[neutral]['800'],
      'neutral-shadow': colors[neutral]['900'],
      'neutral-shadow-heavy': tinycolor(colors[neutral]['900'])
        .darken(7.5)
        .toString(),
    },
  },
  muiThemeBase: {
    palette: {
      type: 'dark',
      primary: {
        main: colors[primary]['400'],
        light: colors[primary]['500'],
        dark: colors[primary]['300'],
      },
      secondary: {
        main: colors[secondary]['400'],
        light: colors[secondary]['500'],
        dark: colors[secondary]['300'],
      },
      error: {
        main: colors[error]['400'],
        light: colors[error]['600'],
        dark: colors[error]['300'],
      },
      text: {
        disabled: colors[neutral]['600'],
        icon: 'rgba(255, 255, 255, 0.5)',
        primary: '#fff',
        secondary: colors[neutral]['200'],
      },
      divider: colors[neutral]['600'],
      gray: colors[neutral],
      background: {
        default: 'rgb(48,48,54)',
        paper: 'rgb(48,48,54)',
      },
    },
  },
}

module.exports = dark
