import { ClientT, FaqSettingsI } from '@/config/types'

// As clients become more varied, start creating FAQs unique to each client
import commonFaqs from './common'
import nowFaqs from './now'
import nowOTTFaqs from './nowott'

export const faqs: Record<ClientT, FaqSettingsI> = {
  'nbcu-dtc': commonFaqs,
  now: nowFaqs,
  nowott: nowOTTFaqs,
  peacock: commonFaqs,
  showmax: commonFaqs,
  'sky-europe-and-peacock': commonFaqs,
  'sky-europe': commonFaqs,
  skyshowtime: commonFaqs,
}

export default faqs
