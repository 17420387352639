import useMetricSettings from '@/hooks/useMetricSettings'
import { cn } from '@/utils/cn'

const EventItemQualityMetrics = ({
  qualityMetrics,
  eventData,
  wrapperClassName,
  itemClassName,
  itemLabelClassName,
  itemValueClassName,
}: {
  qualityMetrics: string[]
  eventData?: Conviva2EventI
  wrapperClassName?: string
  itemClassName?: string
  itemLabelClassName?: string
  itemValueClassName?: string
}) => {
  if (qualityMetrics.length === 0 || !eventData) return null

  const data = qualityMetrics
    .map((qualityMetric) => {
      const qualityMetricSettings = useMetricSettings({
        metric: qualityMetric,
        variants: { withClient: true },
      })

      const qualityMetricLabel = qualityMetricSettings.shortLabel
        ? qualityMetricSettings.shortLabel
        : qualityMetricSettings.label

      const rawMetricValue =
        eventData?.[qualityMetricSettings.dataKey as keyof Conviva2EventI]

      const qualityMetricValue =
        typeof rawMetricValue === 'number'
          ? qualityMetricSettings.valueFormat(rawMetricValue)
          : null

      return [qualityMetricLabel, qualityMetricValue]
    })
    .filter((datum) => datum[1] !== null)

  if (data.length === 0) return null

  if (eventData.plays === null) return null // see  ** explanation at the top of the pages/Content/AllEvents.tsx

  return (
    <div className={wrapperClassName}>
      {data.map(([qualityMetricLabel, qualityMetricValue]) => (
        <div key={qualityMetricLabel} className={itemClassName}>
          <p
            className={cn(
              'mr-2 tracking-wider text-right uppercase text-text-tertiary',
              itemLabelClassName,
            )}
          >
            {qualityMetricLabel}
          </p>
          <p className={itemValueClassName}>{qualityMetricValue}</p>
        </div>
      ))}
    </div>
  )
}

export default EventItemQualityMetrics
