import axios from 'axios'
import {
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query'

import { BusiestDayI, ClientT } from '@/config/types'
import { useClientStore } from '@/stores/client.store'
import getBusiestDaysUrl from '@/utils/getBusiestDaysUrl'

import getAccessToken from '../utils/getAccessToken'
import useSettings from './useSettings'

const fetchBusiestDays = async ({ queryKey }: QueryFunctionContext) => {
  const { client, includeTopEvents } = queryKey![1] as {
    config: ConfigI
    client: ClientT
    includeTopEvents: boolean
  }

  const busiestDaysUrl = getBusiestDaysUrl(client, includeTopEvents)

  try {
    const token = await getAccessToken()
    const response = await axios.get(busiestDaysUrl, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (!response) {
      // Any errors thrown from try will get passed to catch, which will then process them
      throw new Error('Response is undefined or null')
    }
    return response.data.data
  } catch (error) {
    // Don't throw a full error for cancelledErrors, creates too much noise in logging.
    if (axios.isAxiosError(error) && error.name === 'CanceledError') {
      console.log('Request was canceled')
      return null
    }
    console.error(error)
    throw error
  }
}

const useBusiestDaysData = ({
  queryKey,
  config,
  reactQueryProps,
}: {
  queryKey?: QueryKey
  config?: ConfigI
  reactQueryProps?: UseQueryOptions
} = {}): {
  busiestDaysQuery: UseQueryResult<BusiestDayI[], Error>
  busiestDaysUrl: string
} => {
  const client = useClientStore((state) => state.client)
  const { clientSettings } = useSettings()
  const { includeTopEvents } = clientSettings.metricsExplorer.busiestDays

  const busiestDaysUrl = getBusiestDaysUrl(client, includeTopEvents)

  const busiestDaysQuery = useQuery<any, any, any, QueryKey>(
    [queryKey || 'busiest-days', { config, client, includeTopEvents }],
    fetchBusiestDays,
    {
      ...reactQueryProps,
    },
  )

  return {
    busiestDaysQuery,
    busiestDaysUrl,
  }
}

export default useBusiestDaysData
